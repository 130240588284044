import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import * as L from 'node_modules/leaflet';
import 'leaflet.markercluster';
import { RoadClusters, ClusterType } from "../_models/Clusters";
import { HttpClient } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import * as XLSX from 'xlsx';
import { ApplicationUser } from '../_models/ApplicationUser';
import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
import { LatLng } from 'leaflet';
import { TriageRegion } from '../_models/TriageRegion.js';
import '../../assets/dist/leaflet.canvas-markers'
import * as noUiSlider from 'nouislider'
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import { DomainMapRange, DefaultValues } from '../_models/DomainMapRange';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Config } from '../_models/Config';

@Component({
  selector: 'app-road-markingsand-lines',
  templateUrl: './road-markingsand-lines.component.html',
  styleUrls: ['./road-markingsand-lines.component.css']

})
export class RoadMarkingsandLinesComponent implements OnInit {
  public CurrentYear=(new Date()).getFullYear();
  public isRobocistAdmin = false;
  public User: ApplicationUser;
  public RegionID: number;
  public DrawnFences = Array<GeoData>();
  public YearList = [];
  public MonthList = [];
  public lat: number = 0;
  public long: number = 0;
  public map: any;
  public AssessmentCluster = Array<RoadClusters>();
  public height: string;
  public ImgList = Array<string>();
  public datalist = Array<InsideMapPoint>();
  public slider: any;
  public sliderMonth: any;
  public MinRange: any;
  public MaxRange: any;
  public URL: string;
  public isLoaded = false;
  @ViewChild('content') input;
  public Region = new TriageRegion();
  public Month = 0;
  public Year = 0;
  public currentZoom = 13;
  public currentCenter = new LatLng(35.779591, -78.638176);
  public isSet = false;
  public MonthValue = 0;
  public BackMonth = 0;
  public MapTypeID: number;
  public DomainRange = new DomainMapRange();
  isAdmin = false;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  DomainAdminRole = Config.AdminRoleId;
  SizeID = 4;
  public Type="" 
  public chkBoundingBox=false

  public defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsMonth")) as DefaultValues;

  public constructor(private http: HttpClient, private _Activatedroute: ActivatedRoute, private _restservice: RestService, private cdRef: ChangeDetectorRef, private modalService: NgbModal) {
    this.height = window.innerHeight + "px";
  }


  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params: ParamMap,) => {
      this.MapTypeID = parseInt(params.get('MapTypeID'));
      this.Type = params.get('Type');
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.DomainRange.MaximumCoverage = 1;
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    //this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    if (this.User.RoleId == this.RobocistAdminRole || this.User.RoleId == this.DomainAdminRole) {
      this.isAdmin = true;
    }
    if (this.User.RoleId == this.RobocistAdminRole) {
      this.isRobocistAdmin = true;
    }
    if(this.Type=="Q"){
      this.defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsQue")) as DefaultValues
    }
    if(this.defaultsSetting!=null ){
      this.chkBoundingBox=this.defaultsSetting.chkBoundingBox;
    }

    this.ListYear()
    this.GetRegion();
    //this.GetYearMonth();
  }

  IsLoad: boolean = false;
  SetSessionDefaults() {
    if (this.IsLoad == true) {
      var defaults = new DefaultValues();
      defaults.Year = $('#YearID').val() as number
      if (this.Type == "Q") {
        defaults.Quarter = $('#MonthID').val() as number;
      } else {
        defaults.Month = $('#MonthID').val() as number;
      }
      defaults.chkBoundingBox = $('input[name="chkBoundingBox"]:checked')[0] == undefined ? false : true
      defaults.Coverage = this.MonthValue;
      if(this.Type=="Q"){
        localStorage.removeItem("SetSessionMapsDefaultsQue")
        localStorage.setItem("SetSessionMapsDefaultsQue", JSON.stringify(defaults))
      }else{
        localStorage.removeItem("SetSessionMapsDefaultsMonth")
        localStorage.setItem("SetSessionMapsDefaultsMonth", JSON.stringify(defaults))
    }
    }
  }


  ListYear(){
    this._restservice.Call<string[]>('GET', API.ListOfYear + this.RegionID+'/'+this.Type, null)
    .subscribe(data => {
      this.YearList = data;

    });
  }
  SetDefault() {
    var domain = new DomainMapRange();
    domain.MapTypeID = this.MapTypeID;
    domain.RegionID = this.RegionID;
    domain.AssetTrendSize = parseInt($('#SizeID').val() as string);
    domain.MaximumCoverage = this.MonthValue;
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    if (Arr.length == 0)
      domain.Assessment = null;
    else
      domain.Assessment = Arr;

    domain.FairMin = $('#FairMin').text() as unknown as number;
    domain.FairMax = $('#FairMax').text() as unknown as number;
    this._restservice.Call<DomainMapRange>('POST', API.SetDefaultRange, domain)
      .subscribe(data => {
        this.DomainRange = data;
        AutoClosingAlert('success', 'Default Range set successfully.');
      })
  }
  GetDefaultRange() {
    this._restservice.Call<DomainMapRange>('GET', API.GetDefaultRange + this.MapTypeID + "/" + this.RegionID, null)
      .subscribe(data => {
        // this.slider.noUiSlider.updateOptions({
        //   start:[data.FairMin,data.FairMax]
        // });
        if (data == null) {
          this.DomainRange.FairMin = 4;
          this.DomainRange.FairMax = 4;
          $('.MapAssessmentList').map(function () {
            if ($(this).val() != 3) {
              $(this).attr('checked', 'true');
            }
          })
        }
        else {
          this.DomainRange = data;
          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
          if (this.DomainRange.Assessment == null) {
            $('.MapAssessmentList').map(function () {
              if ($(this).val() != 3) {
                $(this).attr('checked', 'true');
              }
            })
          }
          else {
            this.DomainRange.Assessment.forEach(element => {
              $('#chk_' + element).attr('checked', 'true');
            });
          }
          this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
        }
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        var EndDate = new Date(this.Year, this.Month);

        this.monthDiff(StartDate, EndDate);
        this.slider = document.getElementById('slider') as any;

        noUiSlider.create(this.slider, {
          start: [this.DomainRange.FairMin, this.DomainRange.FairMax],
          padding: [1, 1],
          connect: true,
          tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
          range: {
            'min': 0,
            'max': 10
          }
        });

        var PoorMax = document.getElementById('PoorMax');
        var FairMin = document.getElementById('FairMin');
        var FairMax = document.getElementById('FairMax');
        var GoodMin = document.getElementById('GoodMin');

        this.slider.noUiSlider.on('update', function (values, handle) {
          if (handle) {
            FairMax.innerHTML = Math.round(values[handle]).toString();
            GoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();
          } else {
            FairMin.innerHTML = Math.round(values[handle]).toString();
            PoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();
          }
        });
        if (this.defaultsSetting !=null &&this.defaultsSetting.Coverage != null) {
          if (this.sliderMonth != undefined) {
            this.sliderMonth.noUiSlider.updateOptions({
              start: [this.defaultsSetting.Coverage]
            });
          }
        }
        this.IsLoad=true;
        this.Map();

      });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
      
        this.isLoaded = true;
         this.GetYearMonth();
      })
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;

    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    if(this.Type=="M"){
    if (1 == mon) {
     
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      
    this.sliderMonth = document.getElementById("sliderMonth") as any;
    // document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : "Month");
    noUiSlider.create(this.sliderMonth, {
      start: this.DomainRange.MaximumCoverage,
      tooltips: [wNumb({ decimals: 0 })],
      range: {
        'min': 1,
        'max': mon
      },
    });
    var self = this;
    self.sliderMonth.noUiSlider.on('update', function (values, handle) {
      self.MonthValue = Math.round(values[handle]);
      if (self.MonthValue != self.DomainRange.MaximumCoverage) {
        self.SetSessionDefaults()
      }
    });
  }
  }
}
 
  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (isDefault) {
        this.GetDefaultRange();
      }else {
        this.SetSessionDefaults()
      }
    }
    else {
      var SelectedYear=Year;
      if(this.YearList.filter(s=>s==Year).length==0){
        SelectedYear=this.LastYear
      }
      this._restservice.Call<string[]>('GET', API.ListOfMonth + SelectedYear + "/" + this.RegionID+"/"+this.Type, null)
        .subscribe(data => {
          if (data != null) {
          this.MonthList = data;
          this.cdRef.detectChanges();
          if (isDefault) {
           
            if (this.YearList.filter(s => s == Year).length > 0 &&  this.MonthList.filter(s => s.ID == Month).length > 0) {
              $('#YearID').val(Year);
              $('#MonthID').val(Month);
              this.Month = Month;
              this.Year = Year;
            }
            else {
                $('#YearID').val(this.LastYear);
                $('#MonthID').val(this.LastMonth);
                this.Month = this.LastMonth;
                this.Year = this.LastYear;
            }
          }
          if (isDefault) {
            this.GetDefaultRange();
          }else {
            this.SetSessionDefaults()
          }
        }

        });
    }
  }
  LastYear: any;
  LastMonth: any;
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.GetYearMonth + this.RegionID+"/"+this.Type + "?Type=Road Markings", null)
      .subscribe(data => {
        this.LastYear = data.Year;
        this.LastMonth = data.MonthID;
        if(this.defaultsSetting!=null){
          if (this.Type == "Q") {
            this.FillYearMonth(this.defaultsSetting.Year,this.defaultsSetting.Quarter, true);
          }else{
            this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Month, true);
          }
        }else{
          this.FillYearMonth(data.Year, data.MonthID, true);
        }
      })
  }
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;
    if(this.Type=="Q"){
      if(Month==1){
        Month=1
      }else if(Month==2){
        Month=4
      }
      else if(Month==3){
        Month=7
      }else{
        Month=10
      }
      this.sliderMonth=3
      this.MonthValue=3
    }

    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);
    this.SetSessionDefaults()
    this.monthDiff(StartDate, EndDate);
  }
  Map() {

    var self = this;

    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });

    if (Arr.length == 0) {
      $('.MapAssessmentList:checkbox').each(function () {
        Arr.push($(this).val())
      });
    }
    document.getElementById('weathermap').innerHTML = "<div id='map'></div>";
    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;
    }
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    var arrymonth= $('#MonthID').val() as string;
    var sendMonths=parseInt(arrymonth);
    var converageMonth=this.BackMonth;
 
   if(this.Type=="Q"){
     isPrevious=true
       if(parseInt(arrymonth)==1){
         sendMonths=3
       }else if(parseInt(arrymonth)==2){
         sendMonths=6
       }
       else if(parseInt(arrymonth)==3){
         sendMonths=9
       }else{
         sendMonths=12
       }
       if(this.BackMonth<=0){
         converageMonth=3
       }else{
       converageMonth=this.BackMonth*3
       }
   }
    this.MinRange = $('#FairMin').text() as unknown as number;
    this.MaxRange = $('#FairMax').text() as unknown as number;
    this.http.get<any[]>(API.ListOfClusterRoadMarkingAndLines + "?MonthID=" + sendMonths + "&Year=" + $('#YearID').val()+"&Type="+this.Type + "&UserID=" + this.User.Id + "&RegionID=" + this.RegionID + "&AssessmentIDs=" + JSON.parse(JSON.stringify(Arr)) + "&MinRange=" + this.MinRange + "&MaxRange=" + this.MaxRange + "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1))
      .subscribe(data => {
        var markerList = [];
        if (data.length > 0 == this.map == undefined) {
          this.lat = data[0].Lt;
          this.long = data[0].Lg;
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }
        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

        var osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; '+this.CurrentYear+' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; '+this.CurrentYear+' Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });
          
        var baseMaps = {
          "Street": osm,
          "Satellite": mqi
        };

        var overlays = {//add any overlays here
        };
      
        L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);

        // this.map.on('zoomend', function (e) {
        //   var currZoom = self.map.getZoom();
        //   if (markerList.length > 0) {
        //     if (currZoom >= 16) {
        //       markers.removeLayers(markerList);
        //     }

        //     else {
        //       markers.addLayers(markerList);
        //     }
        //   }
        // });
        var searchControl = esriGeoCoder.geosearch({
          collapseAfterResult: true,
          useMapBounds: false,
          zoomToResult: false,
          providers: [ esriGeoCoder.arcgisOnlineProvider() ]
        }).addTo(this.map);
        var results = L.layerGroup().addTo(this.map);
        searchControl.on('results', function (data) {
          results.clearLayers();
          for (var i = data.results.length - 1; i >= 0; i--) {
            results.addLayer(L.marker(data.results[i].latlng));
          }
        });
        
        // if ($('#MonthID').val() == "0" && $('#YearID').val() == "0" && data.length > 0) {
        //   var date = data[0].Dt as string;
        //   this.Year = parseInt(date.split('-')[0]);
        //   this.Month = parseInt(date.split('-')[1]);
        //   this.FillYearMonth(date.split('-')[0], date.split('-')[1]);
        // }
        // else {
          var m = $('#MonthID').val() as string;
          var y = $('#YearID').val() as string;
          this.Month = parseInt(m);
          this.Year = parseInt(y);
        // }


        var len = data.length;
        var markers = L.markerClusterGroup({ chunkedLoading: true });

        for (var i = 0; i < len; i++) {

          var item = data[i];
          var cluster = new RoadClusters();
          cluster.GPSLat = item.Lt;
          cluster.GPSLong = item.Lg;
          cluster.ClusterType = item.Ct;
          cluster.Assessment = item.As;
          cluster.AssessmentDate = item.Dt;
          cluster.RoadClusterID = item.CtID;
          cluster.AssessmentClusterID = item.ID;
          this.AssessmentCluster.push(cluster);

          var marker = L.marker(L.latLng(cluster.GPSLat, cluster.GPSLong), {
            // icon: myIcon,
            title: cluster.ClusterType, draggable: false
          }).on('click', this.MarkerClick.bind(null, cluster, this)
          );
          marker.setOpacity(0);
          markerList.push(marker);

          // markers.addLayer(marker);
          var featureGroup = L.featureGroup().addTo(this.map);
          if (Arr.length > 0) {
            var iconColor = "#000000";
            if (cluster.Assessment > this.MaxRange && cluster.Assessment <= 10) {
              iconColor = "#008000";
            }
            
            else if (cluster.Assessment >= this.MinRange && cluster.Assessment <= this.MaxRange) {
              iconColor = "#0000ff";
            }
            else if (cluster.Assessment >= 0 && cluster.Assessment < this.MinRange) {
              iconColor = "#f31515";
            }
           
            L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong), { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 }).addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
            );

          }
          featureGroup.bringToBack();
        }

        if (this.AssessmentCluster.length > 0) {
          this.map.addLayer(markers);
        }
        this.RetriveGeoFence();
       
      
      })
  }
  
  ResetSlider() {
    this.slider.noUiSlider.updateOptions({
      start: [this.DomainRange.FairMin, this.DomainRange.FairMax]
    });
    $('.MapAssessmentList').removeAttr('checked');
    if (this.DomainRange == null) {
      this.DomainRange.FairMin = 4;
      this.DomainRange.FairMax = 4;
      
      $('.MapAssessmentList').map(function () {
        if ($(this).val() != 3) {
          $(this).prop('checked', true);
        }
      })
    }
    else {
   
      if (this.DomainRange.MaximumCoverage == null) {
        this.DomainRange.MaximumCoverage = 1;
      }
     
      if (this.DomainRange.Assessment == null) {
        $('.MapAssessmentList').map(function () {
          if ($(this).val() != 3) {
            $(this).prop('checked', true);
          }
        })
      }
      else {
        this.DomainRange.Assessment.forEach(element => {
          $('#chk_' + element).prop('checked', true);
        });
      }
      this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
    }
  }

  RetriveGeoFence() {
    this.DrawnFences = Array<GeoData>();
    var featureGroup = L.featureGroup().addTo(this.map);
    

    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        var self = this;
        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();

      })

  }
  
  MarkerClick(cluster, self, e) {

    var lat = cluster.GPSLat;
    var lng = cluster.GPSLong;
    var obj = self as this;
    var ID = cluster.AssessmentClusterID;
    var isPrevious = false;
    if (obj.BackMonth > 0) {
      isPrevious = true;
    }
    var arrymonth= $('#MonthID').val() as string;
    var sendMonths=parseInt(arrymonth);
    var converageMonth=obj.BackMonth;
 
    if(obj.Type=="Q"){
      isPrevious=true
        if(parseInt(arrymonth)==1){
          sendMonths=3
        }else if(parseInt(arrymonth)==2){
          sendMonths=6
        }
        else if(parseInt(arrymonth)==3){
          sendMonths=9
        }else{
          sendMonths=12
        }
        if(obj.BackMonth<=0){
          converageMonth=3
        }else{
          converageMonth=obj.BackMonth*3
      }
       
    }

    var boundingBox = $('#chkBoundingBox').is(':checked');

    obj.http.get<any>(API.RoadMarkingMapImgList + ID + "/" + lat + "/" + lng +"?Type="+obj.Type+"&Month=" +sendMonths + "&Year=" + obj.Year + "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth-1)+"&BoundingBox="+boundingBox)
      .subscribe(data => {
        obj.datalist = data.Item1;
        obj.ImgList = data.Item2;

        obj.cdRef.detectChanges();

        L.popup({ maxHeight: 350 })
          .setLatLng(e.latlng)
          .setContent(document.getElementById('dvImgList').innerHTML)
          .openOn(obj.map);

        obj.cdRef.detectChanges();

        $('.img').click(function () {
          obj.URL = $(this.firstChild).attr('src');
          obj.cdRef.detectChanges();
          obj.modalService.open(obj.input, { size: 'lg' });
        });
      });
  }

  //********** Export Start ***************/
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  dynamicDownloadTxt() {
    this.dyanmicDownloadByHtmlTag({
      fileName: 'RoadLinesJson',
      text: JSON.stringify(this.AssessmentCluster)
    });
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.AssessmentCluster);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'RoadLinesMap.xlsx');

  }
  //********** Export End ***************/

}
