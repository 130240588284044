export class DomainMapRange{
    ID : number;
    MapTypeID:number;
    FairMin:number;
    FairMax:number;
    RegionID:number;
    IsActive : boolean;
    CreatedByID:string;
    CreatedDate:string;
    MapTypeName:string;
    Characteristics:string[];
    RideQuality:string[];
    MaximumCoverage:number;
    Assessment:number[];
    AssetTrendSize:number;
    RoadAssessment:number[];
    RoadFairMin:number;
    RoadFairMax:number;
}

export class DefaultValues{
    Year:number;
    Month?:number=null;
    Quarter?:number=null;
    Coverage:number;
    chkBoundingBox:boolean=false;
}