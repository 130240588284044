import { Component, OnInit, Renderer2, Inject, ChangeDetectorRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute,ParamMap } from '@angular/router';
import {ResetPasswordViewModel} from '../_models/ResetPasswordViewModel';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
public isError;
public _url = API.ResetPassword;
public IsDuplicate = false;
public lblErrorMsg = "";
  constructor(
    
    private router: Router, private _route: ActivatedRoute,
    private _restservice : RestService,private cdRef:ChangeDetectorRef) { }

    public status = 'fa-eye';
    public type = 'password';
    public ResetPassword = new ResetPasswordViewModel();
    public ValidEmailForSecurityQue=false;
    public lblInvalidAnswer="";
  ngOnInit() {
    $("form").attr('autocomplete', 'off');
    this._route.paramMap.subscribe((params:ParamMap) => {
        this.ResetPassword.Code =  params.get('code');
        this.ResetPassword.userId = params.get('userId');
   });

  
   this._restservice.Call<any>('GET', API.ResetPassword+"?userId="+this.ResetPassword.userId,null)
   .subscribe(data => {
     var code = this.ResetPassword.Code;
     var userid = this.ResetPassword.userId;
    this.ResetPassword = data;
    this.ResetPassword.Code=code;
    this.ResetPassword.userId = userid;
   })
  }
  CheckPassCreate() {
    if($('#Password').hasClass('Validpass')){
      $("#validPass").hide()
    var pass = $('#Password').val();
    var confPas = $("#ConfirmPasswordAdd").val();
    if (pass != '') {
      if (pass == confPas) {
        $("#Checkshow").show();
        $("#Crossshow").hide();
        this.IsDuplicate=false;
      } else {
        $("#Checkshow").hide();
        $("#Crossshow").show();
        this.IsDuplicate=true;
      }
    } else {
      $("#Checkshow").hide();
      $("#Crossshow").hide();
      this.IsDuplicate=true;
    }
  }
  else{
    
    this.IsDuplicate=true;
    $("#validPass").show();
  }
  }
  CheckForPass(){
    if($('#Password').hasClass('Validpass')){
      this.IsDuplicate=false;
      $("#validPass").hide()
    }else{
      this.IsDuplicate=true;
     $("#validPass").show();
    }
    
  }
  getAlert() {
    if (this.status === 'fa-eye-slash') {
      this.status = 'fa-eye';
      this.type = 'text';
    } else {
      this.status = 'fa-eye-slash';
      this.type = 'password';
    }
  }
  onSubmit() {
    this._restservice.Call<any>('POST', API.ResetPassword, this.ResetPassword)
    .subscribe(data => {
        this.router.navigate(['/SignIn']);
      },
      (error: HttpErrorResponse) => {
        var errormsg = error as unknown as string;
        if(errormsg == 'InvalidToken')
        {
          this.lblErrorMsg = "The password reset link is no longer valid. Please request another password reset email from the login page."
        }
      }
    )
  }
  CheckAnswer()
  {
    this.lblInvalidAnswer ="";
this._restservice.Call<string>("POST",API.CheckAnswer,this.ResetPassword)
.subscribe(data =>{
this.ValidEmailForSecurityQue = true;
this.cdRef.detectChanges();
PasswordStrengthInit('#Password');
$('.dvUserQuecls *').attr('readonly','readonly');

},
(error: HttpErrorResponse) => {
  this.lblInvalidAnswer = error as unknown as string;
  })
  }
}
