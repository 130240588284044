import { UserSecurityQue } from './UserSecurityQue';

export class ResetPasswordViewModel {
    Email: string;
    Password: string;
    ConfirmPassword:string;
    Code: string;
    Mobile:string;
    userId:string;
    userSecurities=Array<UserSecurityQue>()
}