import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, config } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoginViewModel } from '../_models/LoginViewModel';
import { User } from '../_models';
import { RestService } from '../_services/rest.service';
import { TokenModel } from '../_models/TokenModel';
import { API } from '../_models/API';
import { Config } from '../_models/Config';
import { Globals } from './Globals';
import { ApplicationUser } from '../_models/ApplicationUser';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<ApplicationUser>;
    public currentUser: Observable<ApplicationUser>;

    private currentTokenSubject: BehaviorSubject<TokenModel>;
    public currentToken: Observable<TokenModel>;

    constructor(private http: HttpClient, private _restservice: RestService) {
        this.currentUserSubject = new BehaviorSubject<ApplicationUser>(JSON.parse(this.getCookie("currentUser")) as ApplicationUser);
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentTokenSubject = new BehaviorSubject<TokenModel>(JSON.parse(this.getCookie("currentToken")));
        this.currentToken = this.currentTokenSubject.asObservable();
    }
    error = '';
    public UserData = new ApplicationUser();

    public get currentUserValue(): ApplicationUser {
        return this.currentUserSubject.value;
    }

    public get currentTokenValue(): TokenModel {
        return this.currentTokenSubject.value;
    }



    login(loginModel: LoginViewModel) {
        sessionStorage.clear();
        const body = new HttpParams()
            .set('username', loginModel.Username.toLowerCase())
            .set('password', loginModel.Password)
            .set('grant_type', Config.grant_type)
            .set('client_id', Config.ClientId)
            .set('client_secret', Config.client_secret)
            .set('scope', Config.scope)
            .set('response_type', Config.response_type);
        return this._restservice.Call<TokenModel>('TOKEN', API.Token, body)
            .pipe(map(token => {
                if (token && token.access_token) {
                    document.cookie = "currentToken=" + JSON.stringify(token) + "; expires=2100; path=/";
                    sessionStorage.setItem('currentToken', JSON.stringify(token));
                    this.currentTokenSubject.next(token)
                    return token;
                }
            }))
    }
    getCookie(name) {

        var start = document.cookie.indexOf(name + "=");
        console.log(start)
        var len = start + name.length + 1;

        if ((!start) && (name != document.cookie.substring(0, name.length))) {

            return null;

        }

        if (start == -1) return null;

        var end = document.cookie.indexOf(';', len);

        if (end == -1) end = document.cookie.length;
        return document.cookie.substring(len, end);

    }

    UserFromToken() {
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        if (this.getCookie("currentToken") != null) {
            this.currentTokenSubject.next(JSON.parse(this.getCookie("currentToken")))
        }
        return this._restservice.Call<ApplicationUser>('GET', API.GetUser, null)
            .pipe(map(userInfo => {
                if (userInfo != null) {
                    document.cookie = "currentUser=" + JSON.stringify(userInfo) + "; expires=2100; path=/";
                    sessionStorage.setItem('currentUser', JSON.stringify(userInfo));
                    this.currentUserSubject.next(userInfo);
                    Globals.Username = userInfo.UserName;
                    Globals.RoleName = userInfo.RoleName;
                    Globals.UserID = userInfo.Id;
                    Globals.RoleID = userInfo.RoleId;
                    this.UserData = userInfo;
                    Globals.CurrentLoginId = this.UserData.CurrentLoginId;
                    return userInfo;
                }
            }))
    }

    logout() {
        localStorage.removeItem('SetSessionMapsDefaultsQue')
        localStorage.removeItem('SetSessionMapsDefaultsMonth')
        sessionStorage.removeItem('currentUser');
        document.cookie = "currentToken=null; expires=2100; path=/";
        document.cookie = "currentUser=null; expires=2100; path=/";
        sessionStorage.clear();
        this._restservice.Call('POST', API.Logout + Globals.CurrentLoginId, null)
            .subscribe(data => {
                console.log('logout');
            })
        this.currentUserSubject.next(null);
    }
    getDecodedAccessToken(token: string): any {
        try {
        }
        catch (Error) {
            return null;
        }
    }

}