import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '../_services';
import { Globals } from '../_services/Globals';
import { map } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })

export class RouteGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute :ActivatedRoute
    ) { }
    

    canActivate(route: ActivatedRouteSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            this.router.navigate(['/Home']);
        }else{
            Globals.BreadCrumbID = route.data['ID'];
        }
        return true;
            
    }
   
}