import { Component,Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { AuthenticationService, RestService } from './_services';
import { User } from './_models';
import { appRoutes } from './app.routing';
import { MenuService } from './_services/menu.service';
import { ApplicationUser } from './_models/ApplicationUser';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { enableProdMode } from '@angular/core';

@Component({ selector: 'app-root', templateUrl: 'app.component.html' })

export class AppComponent implements OnInit {
  title = 'RoadTriage';
  currentUser: ApplicationUser;
  public spinkit = Spinkit;
  breadcrumbList= Array<any>();
  menu: Array<any> = [];
  location: Location;
  constructor(
      private _router: Router,
      private authenticationService: AuthenticationService,
      private menuService: MenuService,
      private _restService : RestService
  ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(){
    //this.menu = this.menuService.getMenu();
    // this.listenRouting();
    if(environment.production){
      enableProdMode();
      if (location.protocol === 'http:') {
       window.location.href = location.href.replace('http', 'https');
     }    
    }
  }

  logout() {
      this.authenticationService.logout();
      this._router.navigate(['/login']);
  }
  // listenRouting() {
  //   let routerUrl: string, routerList: Array<any>, target: any;
  //   this.router.events.subscribe((router: any) => {
  //     routerUrl = router.urlAfterRedirects;
  //     if (routerUrl && typeof routerUrl === 'string') {
  //       target =this.router.config[1].children;
  //       this.breadcrumbList.length = 0;
  //       routerList = routerUrl.slice(1).split('/');
  //       routerList.forEach((router, index) => {
  //         target = target.find(page => page.path === router);
  //         this.breadcrumbList.push({
  //           name: target.data.breadcrumb,
  //           path: (index === 0) ? target.path : `${this.breadcrumbList[index-1].path}/${target.path}`
  //         });
  //         if (index+1 !== routerList.length) {
  //           target = target.children;
  //         }
  //       });
  //       console.log(this.breadcrumbList);
  //     }
  //   });
  // }

  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.split('/');
        routerList.forEach((router, index) => {
          target = target.find(page => page.path.slice(2) === router);
          this.breadcrumbList.push({
            name: target.name,
            path: (index === 0) ? target.path : `${this.breadcrumbList[index-1].path}/${target.path.slice(2)}`
          });
          
          if (index+1 !== routerList.length) {
            target = target.children;
          }
        });

      }
    });
  }
}