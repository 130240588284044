import { Component, OnInit, ViewChild } from '@angular/core';
import { RegisterNewUserViewModel } from '../_models';
import { RestService } from '../_services/rest.service';
import { Router } from '@angular/router';
import { API } from '../_models/API';
import { ApplicationUser } from '../_models/ApplicationUser';
import { UserRoles } from '../_models/ApplicationRoleListViewModel';
import { AssignRegion } from '../_models/AssignRegion';
import { Config } from '../_models/Config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../_models/Client';
@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent implements OnInit {
  @ViewChild('content') input;
  public RegisterUserViewModel = new RegisterNewUserViewModel();
  InviteSent = false;
  IsDuplicate = true;
  DuplicateEmail = "";
  public Roles = new Array<UserRoles>();
  public AdminRole = Config.AdminRoleId;
  public RegionalAdminRole = Config.RegionalAdminRoleId;
  public UserRole = Config.UserRoleId;
  public User: ApplicationUser;
  constructor(private _restservice: RestService,
    private modelService: NgbModal) { }

  ngOnInit() {
    sessionStorage.removeItem('Userids')
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    this.GetAllRegions();
  }


  OnSubmit() {

    var count = 0;
    this.RegisterUserViewModel.Clients.forEach(client => {

      client.AssignRegions.forEach(domain => {
        if (domain.RoleID != null) {
          count++;
        }
        domain.SubAssignRegions.forEach(region => {
          if (region.RoleID != null) {
            count++;
          }
          region.SubAssignRegions.forEach(subregion => {
            if (subregion.RoleID != null) {
              count++;
            }
          });
        });
      });
    });

    if (count == 0) {
      this.modelService.open(this.input);
    }
    else {
      this.RegisterUserViewModel.Email = this.RegisterUserViewModel.Email.toLowerCase();
      this._restservice.Call<ApplicationUser>('POST', API.SendInvitationtoUser, this.RegisterUserViewModel)
        .subscribe(data => {
          if (data != null) {
            this.InviteSent = true;
          }
        })
    }
  }
  Collapse() {
    $('.show').removeClass('show');
    $('.cart-heading').addClass('collapsed');
  }

  GetAllRegions() {
    this._restservice.Call<AssignRegion[]>('GET', API.GetAllRegionForInvitation + "?userId=" + this.User.Id, null).subscribe(data => {
      var clientIDs = Array.from(new Set(data.map(x => x.ClientID)));
      clientIDs.map(x => {
        var client = new Client();
        client.ID = x;
        client.OrganizationName = data.find(r => r.ClientID == x).ClientName;
        client.AssignRegions = data.filter(r => r.ClientID == x);


        this.RegisterUserViewModel.Clients.push(client);
      })
      this.RegisterUserViewModel.Clients = this.RegisterUserViewModel.Clients.sort((a, b) => (a.OrganizationName > b.OrganizationName) ? 1 : -1)
      this.RegisterUserViewModel.Clients.forEach(c => {

        if (c.toggle == undefined || c.toggle == "") {
          c.toggle = "show"
          c.Col = "";
        }
        c.AssignRegions.forEach(ass => {
          if (ass.toggle == undefined || ass.toggle == "") {
            ass.toggle = ""
            ass.Col = "collapsed";
          }
          ass.SubAssignRegions.forEach(subass => {
            if (subass.toggle == undefined || subass.toggle == "") {
              subass.toggle = ""
              subass.Col = "collapsed";
            }
          });
         
        });
      });
    })
  }

  CheckDuplicate(type) {
    if (type == 'Email') {
      this._restservice.Call<any>('GET', API.ValidateDuplicate + "?Email=" + this.RegisterUserViewModel.Email, null)
        .subscribe(result => {
          if (result.StatusCode != 200) {
            this.DuplicateEmail = "User is already exist in the system.";
            this.IsDuplicate = true;

          }
          else {
            this.DuplicateEmail = "";
            this.IsDuplicate = false;
            this.OnSubmit();
          }

        })
    }

  }

  FocusIn(type) {
    if (type == 'Email') {
      this.DuplicateEmail = "";
    }
  }

  GetUserRoles() {
    this._restservice.Call<UserRoles[]>('GET', API.Roles, null)
      .subscribe(result => {
        this.Roles = result
      })
  }

  DomainChange(Domain: AssignRegion, value: string) {
    if (value == this.AdminRole || value == this.RegionalAdminRole) {
      Domain.SubAssignRegions.forEach(element => {
        element.RoleID = this.RegionalAdminRole;
        element.SubAssignRegions.forEach(subelement => {
          subelement.RoleID = this.UserRole;
        });
      });
    }
    else {
      Domain.SubAssignRegions.forEach(element => {
        element.RoleID = this.UserRole;
        element.SubAssignRegions.forEach(subelement => {
          subelement.RoleID = this.UserRole;
        });
      });
    }


  }

  DeselectDomain(Domain: AssignRegion) {
    Domain.RoleID = null;
    Domain.SubAssignRegions.forEach(element => {
      element.RoleID = null;
      element.SubAssignRegions.forEach(subelement => {
        subelement.RoleID = null;
      });
    });
  }

  ResetAll() {
    this.RegisterUserViewModel.Clients.forEach(client => {

      client.AssignRegions.forEach(Domain => {

        Domain.RoleID = null;
        Domain.SubAssignRegions.forEach(element => {
          element.RoleID = null;
          element.SubAssignRegions.forEach(subelement => {
            subelement.RoleID = null;
          });
        });
      });
    });

  }


  RegionChange(Region: AssignRegion) {
    Region.SubAssignRegions.forEach(element => {
      element.RoleID = this.UserRole;
    });

  }

  
  CollapseAction(id, C: Client) {
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    this.RegisterUserViewModel.Clients.forEach(cli => {
      if (cli.ID == C.ID) {
        cli = C;
      }
    });
  }
  CollapseAssig(id, C: AssignRegion, level: number) {

    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    if (level == 1) {
      this.RegisterUserViewModel.Clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ID).forEach(a => a = C)
    } else if (level == 2) {
      this.RegisterUserViewModel.Clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ParentID)[0].SubAssignRegions.filter(s => s.ID == C.ID).forEach(a => a = C)
    }

    return C.toggle;
  }

}
