import { Component, OnInit, ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-pdflane-line',
  templateUrl: './pdflane-line.component.html',
  styleUrls: ['./pdflane-line.component.css']
})
export class PDFLaneLineComponent implements OnInit {

  public constructor() { }


    // chkBoundingBox = true;
    // Pavementroi = "segment-summary"
    // segmentid:number;
    // public ImageCDN = API.ImageCDN+"/";
    // wayid:number;
    // StartDate:string;
    // popupsegment = new tbl_SegmentAssessment();
    // public Type="" 
    // segment = new tbl_SegmentAssessment();
    
  ngOnInit() {

    // this._Activatedroute.paramMap.subscribe((params: ParamMap,) => {
    //   this.segmentid = parseInt(params.get('segmentid'));
    //   this.wayid = parseInt(params.get('WayID'));
    //   this.Type = params.get('Type');
    // });
    // this.MarkerClick()
  }


//   // imu = "imu"
//   MarkerClick() {
//     this.segment = JSON.parse(sessionStorage.getItem('segment')) as tbl_SegmentAssessment;
//     var segment=this.segment
 
//     if (this.chkBoundingBox == true) {
//       this.Pavementroi = "segment-summary"
//     } else {
//       this.Pavementroi = "segment-summary"
//     }

//     this.http.get<any>( API.LaneLinePopup + this.segmentid + "?Type="+this.Type+ "&WayID=" + this.wayid + "&StartDate=" + this.segment.StartDate)
//       .subscribe(data => {
//         this.popupsegment = data;
//         this.popupsegment.StartDate=segment.StartDate;
      
//         var length = (this.popupsegment.points.length / 2).toFixed(0)
//         this.popupsegment.middlepoint = this.popupsegment.points[length]
//         this.popupsegment.middlepoint = this.popupsegment.middlepoint.replace('(', '')
//         this.popupsegment.middlepoint = this.popupsegment.middlepoint.replace(')', '')
//         this.popupsegment.lat = parseFloat(this.popupsegment.middlepoint.split(',')[0]).toFixed(7)
//         this.popupsegment.long = parseFloat(this.popupsegment.middlepoint.split(',')[1]).toFixed(7)

//         this.cdRef.detectChanges();
//         this.OtherMap()

//         });

      
//   }

//   OtherMap(){
  


//     document.getElementById('map1').innerHTML = "<div id='Pavementmap1' style='width:335px;height:335px'></div>";
//     var Pavementmap1=L.map('Pavementmap1', {  dragging: false,
//       scrollWheelZoom: 'center',
//       center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13});
//     L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
//       attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
//       maxZoom: 19,
//       minZoom:13
//     }).addTo(Pavementmap1)

//     document.getElementById('map2').innerHTML = "<div id='Pavementmap2' style='width:335px;height:335px'></div>";
//     var Pavementmap2=L.map('Pavementmap2', {  
//       dragging: false,
//       scrollWheelZoom: 'center',
//       center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 12});
//     L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
//       attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
//       maxZoom: 19,
//       minZoom:12
//     }).addTo(Pavementmap2)
//  // })

//   var point = new Array<LatLng>();
//       this.popupsegment.points.forEach(p => {
//         p = p.replace('(', '')
//         p = p.replace(')', '')
//         var lat = parseFloat(p.split(",")[0]);
//         var long = parseFloat(p.split(",")[1]);

//         point.push(new L.LatLng(lat, long));
//       });
//       var polylinePoints = [
//         point
//         //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
//       ];
     
//       var firstpolyline1 = new L.polyline(polylinePoints, {
//         color: "#3333ff",
//         weight: 4,
//         opacity: 1,
//         smoothFactor: 1
//       })
//       firstpolyline1.addTo(Pavementmap1);
//       var firstpolyline = new L.polyline(polylinePoints, {
//         color: "#3333ff",
//         weight: 4,
//         opacity: 1,
//         smoothFactor: 1
//       })
     
//       firstpolyline.addTo(Pavementmap2);
//     L.marker([this.popupsegment.lat, this.popupsegment.long]).addTo(Pavementmap2);
   
    
 
//   }

//   exportToPdf(){
//     // let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
//     // // var pdf = new jsPDF('p', 'pt',  [imgWidth, pageHeight]);
//     // var imgWidth = 208;
//     // var pageHeight = 295;
//     // // var imgWidth = 1345;
//     // // var pageHeight = 2041.5;
//     // var position = 0;

//     // var data = document.getElementById('pdfPrakrutiId');
//     // var data2 = document.getElementById('RideCharacteristics');
//     // html2canvas(data).then(canvas => {
//     //   var imgHeight = canvas.height * imgWidth / canvas.width;
       
//     //   const contentDataURL = canvas.toDataURL('image/png')
//     // // Few necessary setting options
//     // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//     // pdf.save('MYPdf.pdf'); // Generated PDF
//     // });
//     //     var data = document.getElementById('maindiv');
//     //     html2canvas(data).then(canvas => {
//     //     // Few necessary setting options
//     //     var imgWidth = 208;
//     //     var pageHeight = 295;
//     //     var imgHeight = canvas.height * imgWidth / canvas.width;
//     //     var heightLeft = imgHeight;
        
//     //     const contentDataURL = canvas.toDataURL('image/png')
//     //     let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
//     //     var position = 0;
//     //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//     //     pdf.save('MYPdf.pdf'); // Generated PDF
//     // });
//     this.getPDF()
//     }
 
//    getPDF(){
    
//       var HTML_Width =$("#maindiv").width();
//       var HTML_Height = $("#maindiv").height();
//       var top_left_margin = 15;
//       // var PDF_Width =HTML_Width+(top_left_margin*2) ;
//       // var PDF_Height =(PDF_Width*1.5)+(top_left_margin*2);
//       var PDF_Width =HTML_Width+(top_left_margin*2) ;
//       var PDF_Height =(PDF_Width*1.5)+(top_left_margin*2);
//       var canvas_image_width = HTML_Width;
//       var canvas_image_height = HTML_Height;
//       var data = document.getElementById('maindiv');
//       var data2 = document.getElementById('RideCharacteristics')
//      // var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
//       var pdf = new jsPDF('portrait', 'pt',  [PDF_Width, PDF_Height],true);
//       var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
 
//       html2canvas(data,{
//          logging:true,
//          allowTaint:true,
//         useCORS: true }).then(canvas => {
//           var img = new Image();
//           img.onload = function() {
//             document.body.appendChild(img);
//           };

//         var imgData = canvas.toDataURL("image/png");
//           pdf.addImage(imgData, 'JPEG', 0, top_left_margin,canvas_image_width,canvas_image_height+10);
//           pdf.save("LaneLine.pdf");
//           });

//     };
}
