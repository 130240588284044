export class SiteMap {
    ID=0;
    ParentID = null;
    Name: string ;
    FullName:string;
    URL: string ;
    Icon: string ;
    bgClass: string ;
    OnHoverbgClass: string ;
    borderleft: string ;
    border: string ;
    Image: string ;
    Role: string ;
    ActiveClass:string;
    ActivebgClass:string;
    ParentName:string;
    Nodes: Array<SiteMap>;
    AssignmentID:number;
    DiagnosisID:number;
}
export class BreadCrumb {
    Name: string ;
    URL: string ;
    ID = 0;
    Parent =0;
    Clickable:boolean;
}