import { Injectable } from '@angular/core';
import { BreadCrumb } from '../_models';
import { Globals } from './Globals';
import { RestService } from './rest.service';
import { map } from 'rxjs/operators';
import { API } from '../_models/API';

@Injectable()
export class MenuService {

    constructor(private _restservice: RestService) { }

    GetBreadCrumbs() {
        var data = Globals.BreadCrumbID;
        var args;
        if (Globals.Args != undefined) {
            args = Globals.Args.toString();
        }
        else {
            args = '';
        }
        var argsval = Globals.ArgsVal;
        return this._restservice.Call<BreadCrumb[]>('GET', API.BreadCrumbsList + '?ID=' + data + '&args=' + args + '&argsval=' + argsval, null)
            .pipe(map(data => {
                return data;
            }))
    }


}