import { Component, OnInit } from '@angular/core';
import { RestService } from '../_services';
import { TriageRegion } from '../_models/TriageRegion';
import { API } from '../_models/API';
import { Client } from '../_models/Client';
import { AssignRegion } from '../_models/AssignRegion';

@Component({
  selector: 'app-region-view',
  templateUrl: './region-view.component.html',
  styleUrls: ['./region-view.component.css']
})
export class RegionViewComponent implements OnInit {
  public RegionList = Array<TriageRegion>();
  public clients = Array<Client>();
  constructor(private _restService: RestService) { }

  ngOnInit() {
    this.GetAllRegionWithUser();
  }

  GetAllRegionWithUser() {
    this._restService.Call<TriageRegion[]>('GET', API.GetAllRegionWithUser, null)
      .subscribe(data => {
        this.RegionList = data;
        var clientIDs = Array.from(new Set(this.RegionList.map(x => x.ClientID)));
          clientIDs.map(x => {
            var client =this.RegionList.find(r => r.ClientID == x).Client;
            client.TriageRegions = this.RegionList.filter(r => r.ClientID == x);


            this.clients.push(client);
          })
          this.clients = this.clients.sort((a, b) => (a.OrganizationName > b.OrganizationName) ? 1 : -1)
          this.clients.forEach(c => {

            if (c.toggle == undefined || c.toggle == "") {
              c.toggle = "show"
              c.Col = "";
            }
            c.TriageRegions.forEach(ass => {
              if (ass.toggle == undefined || ass.toggle == "") {
                ass.toggle = ""
                ass.Col = "collapsed";
              }
              ass.subTriageRegions.forEach(subass => {
                if (subass.toggle == undefined || subass.toggle == "") {
                  subass.toggle = ""
                  subass.Col = "collapsed";
                }
              });
             
            });
          });
      })
  }
  Collapse() {
    $('.show').removeClass('show');
    $('.cart-heading').addClass('collapsed');
    this.clients.forEach(C => {
      C.toggle = "";
      C.Col = "collapsed";
    });


  }

  
  CollapseAction(id, C: Client) {
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    this.clients.forEach(cli => {
      if (cli.ID == C.ID) {
        cli = C;
      }
    });
  }
  CollapseAssig(id, C: AssignRegion, level: number) {

    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }

    if (level == 1) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ID).forEach(a => a = C)


    } else if (level == 2) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ParentID)[0].SubAssignRegions.filter(s => s.ID == C.ID).forEach(a => a = C)


    }

    return C.toggle;
  }

}
