import {Pipe,PipeTransform} from '@angular/core'

@Pipe({name:'searchFilter'})
export class searchFilterPipe implements PipeTransform{

    transform(value:any,search:string){
if(!search){
    return value;
}
let solution = value.filter(v=>{
    if(!v){
        return;
    }
    return v.ExpertFullName.toLowerCase().indexOf(search.toLowerCase())!== -1;
})
return solution;
    }
}