
import { tbl_ClusterImage } from './tbl_ClusterImage';

export class tbl_AssessmentCluster{
     
       AssessmentClusterID :number=0;
       ObjectType:string="";
       ClusterID :number=0;
       RoadClusterID  :number=0;
       ClusterType= new Array<string>();
       gpslat :number=0;
       gpslong  :number=0;
       AssesmentDateStamp:string="";
       Assessment :number=0;
       clusterdatadensity:number=0;
       tbl_ClusterImages = new Array<tbl_ClusterImage>(); 
       RoundingAssessment :number=0;
       roadclusterdatadensity:number;
       subclustertype= new Array<string>();
       Lat :number=0;
       Long  :number=0;
       MinAssessment:number;
       imagelink= new Array<string>();
       AllImg=new Array<string>();
}

