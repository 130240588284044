import { Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { FAQData } from '../_models/FAQData';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationUser } from '../_models/ApplicationUser';
import { Config } from '../_models/Config';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { HttpHeaders, HttpClient } from '@angular/common/http';
declare var require: any
const FileSaver = require('file-saver');


@Component({
  selector: 'app-road-triage-faq',
  templateUrl: './road-triage-faq.component.html',
  styleUrls: ['./road-triage-faq.component.css']
})
export class RoadTriageFAQComponent implements OnInit {
  FAQdata = Array<FAQData>();
  IsRobocistAdmin = false;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  User:ApplicationUser;

  @ViewChild('VersionAlert') VersionAlert;
  @ViewChild('UserManuals') UserManuals;

  constructor(private _restservice: RestService, private _router: Router, private modalService: NgbModal,private cdRef:ChangeDetectorRef,private http: HttpClient) {

  }
  ngOnInit() {
    this.GetFAQData();
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
   if(this.User.RoleId == this.RobocistAdminRole)
   {
     this.IsRobocistAdmin = true;
   }
  }

  GetFAQData(){
    this._restservice.Call<FAQData[]>('GET',API.RoadTriageFAQ,null)
    .subscribe(data =>{
      this.FAQdata = data;
      this.cdRef.detectChanges();
    var acc = document.getElementsByClassName("accordion");
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
    //this.modalService.open(this.UserManuals);
    })
   
  }

  OpenModel(){
    this.modalService.open(this.VersionAlert);
  }

  OpenPdf(){
    FileSaver.saveAs(API.PdfURL, "Softwarerelease");
   // window.open(API.PdfURL, '_blank');
   
  }

  downloadPdf() {
    //const pdfUrl = './assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
    this.modalService.dismissAll()
    FileSaver.saveAs(API.PdfURL, "Softwarerelease");
  }

  openDoc() {
    this.modalService.dismissAll()
    window.open(API.PdfURL, '_blank', '', true);
  }

}
