import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import * as L from 'node_modules/leaflet';
import 'leaflet.markercluster';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApplicationUser } from '../_models/ApplicationUser.js';
import { TriageRegion } from '../_models/TriageRegion.js';
import { GeoData, InsideMapPoint, GeoPoint } from '../_models/GeoData.js';
import { LatLng, Icon, icon } from 'leaflet';
import { RoadClusters, ClusterType, RoadClustersRaw } from '../_models/Clusters.js';
import * as  noUiSlider from 'nouislider/distribute/nouislider';
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import 'leaflet-control-geocoder';
import { DomainMapRange, DefaultValues } from '../_models/DomainMapRange';
import { Config } from '../_models/Config';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
// import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ChartOptions } from 'chart.js';
import { tbl_SegmentAssessment, SegmentPopupToolTip, ImagePopup } from '../_models/tbl_SegmentAssessment';
import "../../assets/dist/leaflet.groupedlayercontrol.css";
import "../../assets/dist/leaflet.groupedlayercontrol.js";
import "../../assets/dist/exampledata.js";
import '../../assets/dist/leaflet-heat'
import { tbl_AssessmentCluster } from '../_models/tbl_AssessmentCluster';

@Component({
  selector: 'app-line-segment-map',
  templateUrl: './line-segment-map.component.html',
  styleUrls: ['./line-segment-map.component.css']
})

export class LineSegmentMapComponent implements OnInit {
  @ViewChild('Imgcontent') Imgcontent: TemplateRef<any>;
  @ViewChild('SegmentImgcontent') SegmentImgcontent: TemplateRef<any>;
  @ViewChild('content') content: TemplateRef<any>;
  @ViewChild('Helpcontent') Helpcontent: TemplateRef<any>;
  public Month = 0;
  public Year = 0;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  DomainAdminRole = Config.AdminRoleId;
  public isRobocistAdmin = false;
  isAdmin = false;
  public User: ApplicationUser;
  public CurrentYear = (new Date()).getFullYear();
  public RegionID: number;
  public YearList = [];
  public MonthList = [];
  public MonthValue = 0;
  public BackMonth = 0;
  public MapTypeID: number;
  public DomainRange = new DomainMapRange();
  public Region = new TriageRegion();
  public slider: any;
  public sliderMonth: any;
  public DrawnFences = Array<GeoData>();

  public ImageCDN = API.ImageCDN;
  public height: string;
  public map: any;
  public isSet = false;
  public currentZoom = 15;
  public currentCenter = new LatLng(35.10193405724608, 84.69363542279648);
  public lat: number = 0;
  public long: number = 0;
  @ViewChild('content') input;
  modalSegment: BsModalRef;
  modalRequire: BsModalRef;
  modalcontent: BsModalRef;
  modalHelp: BsModalRef;
  public Segments = new Array<tbl_SegmentAssessment>();
  public SelectTab = "ObjectSummary"
  public Type = ""

  public ToolTipContent = new SegmentPopupToolTip();


  public defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsMonth")) as DefaultValues;


  public constructor(private http: HttpClient, private _Activatedroute: ActivatedRoute,
    private _restservice: RestService, public cdRef: ChangeDetectorRef, private modalService: NgbModal, private BSmodalService: BsModalService, public router: Router, private _http: HttpClient) {
    this.height = window.innerHeight + "px";
    Chart.plugins.unregister(ChartDataLabels);
  }

  ngOnInit() {
    localStorage.removeItem("MainMapactiveradio")
    this.GetJsonToolTip();
    this._Activatedroute.paramMap.subscribe((params: ParamMap, ) => {
      this.MapTypeID = parseInt(params.get('MapTypeID'));
      this.Type = params.get('Type');
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
   // this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    if (this.User.RoleId == this.RobocistAdminRole) {
      this.isRobocistAdmin = true;
    }
    if (this.User.RoleId == this.RobocistAdminRole || this.User.RoleId == this.DomainAdminRole) {
      this.isAdmin = true;
    }
    if(this.Type=="Q"){
      this.defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsQue")) as DefaultValues
    }
    if(this.defaultsSetting!=null ){
      this.chkBoundingBox=this.defaultsSetting.chkBoundingBox;
    }

    this.ListYear();
    this.GetRegion();
    //this.GetYearMonth();

  }

  IsLoad: boolean = false;
  SetSessionDefaults() {
   
    if (this.IsLoad == true) {
      var defaults = new DefaultValues();
      defaults.Year = $('#YearID').val() as number
      if (this.Type == "Q") {
        defaults.Quarter = $('#MonthID').val() as number;
      } else {
        defaults.Month = $('#MonthID').val() as number;
      }
      defaults.chkBoundingBox = $('input[name="chkBoundingBox"]:checked')[0] == undefined ? false : true
      defaults.Coverage = this.MonthValue;
      if(this.Type=="Q"){
        localStorage.removeItem("SetSessionMapsDefaultsQue")
        localStorage.setItem("SetSessionMapsDefaultsQue", JSON.stringify(defaults))
      }else{
        localStorage.removeItem("SetSessionMapsDefaultsMonth")
        localStorage.setItem("SetSessionMapsDefaultsMonth", JSON.stringify(defaults))
    }
    }
  }


  GetJsonToolTip() {
    this.http.get<SegmentPopupToolTip[]>("../../assets/JsonFile/SegmentPopupToolTip.json").subscribe(s => {
      this.ToolTipContent = s.filter(s => s.Name == "PavementMap")[0];

    });

  }
  ListYear() {
    this._restservice.Call<string[]>('GET', API.SegmentListOfYear + this.RegionID + '/' + this.Type, null)
      .subscribe(data => {
        this.YearList = data;

      });
  }
  SetDefault() {
    var domain = new DomainMapRange();
    domain.MapTypeID = this.MapTypeID;
    domain.RegionID = this.RegionID;
    domain.AssetTrendSize = parseInt($('#SizeID').val() as string);
    domain.MaximumCoverage = this.MonthValue;
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    if (Arr.length == 0)
      domain.Assessment = null;
    else
      domain.Assessment = Arr;

    domain.FairMin = $('#FairMin').text() as unknown as number;
    domain.FairMax = $('#FairMax').text() as unknown as number;
    this._restservice.Call<DomainMapRange>('POST', API.SetDefaultRange, domain)
      .subscribe(data => {
        this.DomainRange = data;
        AutoClosingAlert('success', 'Default Range set successfully.');
      })
  }
  GetDefaultRange() {
    this._restservice.Call<DomainMapRange>('GET', API.GetDefaultRange + this.MapTypeID + "/" + this.RegionID, null)
      .subscribe(data => {

        if (data == null) {
          this.DomainRange.MaximumCoverage = 1;
        } else {
          this.DomainRange = data;

          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
        }
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        var EndDate = new Date(this.Year, this.Month);

        this.monthDiff(StartDate, EndDate, 'S');
        this.IsLoad = true
        this.Map();

      });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
      
        this.GetYearMonth();
      })
  }
  monthDiff(d1, d2, Type) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    var minRange = 1;


    if (1 == mon) {
      minRange = 1; // minus 1 minute
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      if (this.DomainRange.MaximumCoverage == undefined) {
        this.DomainRange.MaximumCoverage = 0
      }
      var coverage = 4;
      if (this.Type == "M") {
        coverage = 12;
      }

      if (Type == "S")//Set
      {
        this.sliderMonth = document.getElementById("sliderMonth") as any;
        noUiSlider.create(this.sliderMonth, {
          start: this.DomainRange.MaximumCoverage,
          tooltips: [wNumb({ decimals: 0 })],
          range: {
            'min': 1,
            'max': coverage
          },
        });
        var self = this;
        self.sliderMonth.noUiSlider.on('update', function (values, handle) {
          self.MonthValue = Math.round(values[handle]);
          if (self.MonthValue != self.DomainRange.MaximumCoverage) {
            self.SetSessionDefaults()
          }
        });
      }
      else { //reset
        this.sliderMonth.noUiSlider.updateOptions({
          start: [this.DomainRange.MaximumCoverage]
        });
      }
      if (this.defaultsSetting !=null &&this.defaultsSetting.Coverage != null) {
        if (this.sliderMonth != undefined) {
          this.sliderMonth.noUiSlider.updateOptions({
            start: [this.defaultsSetting.Coverage]
          });
        }
      }
    }
  }

  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (isDefault) {
        this.DomainRange.MaximumCoverage = 1;
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        var EndDate = new Date(this.Year, this.Month);

        this.monthDiff(StartDate, EndDate, 'S');
        this.IsLoad = true
        this.Map();

        // this.GetDefaultRange();
      }else {
        this.SetSessionDefaults()
      }
    }
    else {
      var SelectedYear=Year;
      if(this.YearList.filter(s=>s==Year).length==0){
        SelectedYear=this.LastYear
      }
      this._restservice.Call<string[]>('GET', API.SegmentListOfMonth + SelectedYear + "/" + this.RegionID + "/" + this.Type, null)
        .subscribe(data => {
          if (data != null) {
          this.MonthList = data;
          this.cdRef.detectChanges();
          if (isDefault) {
            if (this.YearList.filter(s => s == Year).length > 0 &&  this.MonthList.filter(s => s.ID == Month).length > 0) {
              $('#YearID').val(Year);
              $('#MonthID').val(Month);
              this.Month = Month;
              this.Year = Year;
            }
            else {
                $('#YearID').val(this.LastYear);
                $('#MonthID').val(this.LastMonth);
                this.Month = this.LastMonth;
                this.Year = this.LastYear;
            }
          }
          if (isDefault) {
            this.DomainRange.MaximumCoverage = 1;
            var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
            var EndDate = new Date(this.Year, this.Month);

            this.monthDiff(StartDate, EndDate, 'S');
            this.IsLoad = true
            this.Map();

            // this.GetDefaultRange();
          }else {
            this.SetSessionDefaults()
          }
        }
        });
    }
  }
  LastYear: any=0;
  LastMonth: any=0;
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.SegmentGetYearMonth + this.RegionID + "/" + this.Type + "", null)
      .subscribe(data => {
        this.LastYear = data.Year;
        this.LastMonth = data.MonthID;
        if(this.defaultsSetting!=null){
          if (this.Type == "Q") {
            this.FillYearMonth(this.defaultsSetting.Year,this.defaultsSetting.Quarter, true);
          }else{
            this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Month, true);
          }
        }else{
          this.FillYearMonth(data.Year, data.MonthID, true);
        }
      })
  }
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;

    if (this.Type == "Q") {
      if (Month == 1) {
        Month = 1
      } else if (Month == 2) {
        Month = 4
      }
      else if (Month == 3) {
        Month = 7
      } else {
        Month = 10
      }
    }
    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);
    this.SetSessionDefaults()
    this.monthDiff(StartDate, EndDate, 'R');
  }

  public layerControl;
  public MainMapactiveradio = "";
  Map() {

    var self = this;
    //   if(self.map!=undefined){
    //   var active = self.map
    //   alert(active)
    //   console.log(active)
    // }
    //this.RetriveGeoFence();
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    let RoadArr = [];
    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    // if (Arr.length == 0) {
    //   $('.MapAssessmentList:checkbox').each(function () {
    //     Arr.push($(this).val())
    //   });
    // }
    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).attr('name'));
    });

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).attr('name'));
    })
    document.getElementById('weathermap').innerHTML = "<div id='map' style='width:1300px'></div>";
    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;

    }
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    var arrymonth = $('#MonthID').val() as string;
    var sendMonths = parseInt(arrymonth);
    var converageMonth = this.BackMonth;

    if (this.Type == "Q") {
      isPrevious = true
      if (parseInt(arrymonth) == 1) {
        sendMonths = 3
      } else if (parseInt(arrymonth) == 2) {
        sendMonths = 6
      }
      else if (parseInt(arrymonth) == 3) {
        sendMonths = 9
      } else {
        sendMonths = 12
      }
      if (this.BackMonth <= 0) {
        converageMonth = 3
      } else {
        converageMonth = this.BackMonth * 3
      }
    }

    this._restservice.Call<tbl_SegmentAssessment[]>('GET', API.SegmentList + this.RegionID + "?Type=" + this.Type + "&MonthIDs=" + sendMonths + "&Year=" + $('#YearID').val() +
      "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1), null)
      .subscribe(data => {
        this.Segments = data;

        var markerList = [];
        if (data.length > 0 && this.map == undefined && data != null) {
          if (this.Segments[0].points.length > 0) {
            this.Segments[0].points[0] = this.Segments[0].points[0].replace('(', '')
            this.Segments[0].points[0] = this.Segments[0].points[0].replace(')', '')
            this.lat = parseFloat(this.Segments[0].points[0].split(",")[0]);
            this.long = parseFloat(this.Segments[0].points[0].split(",")[1]);
          }
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }
        // this.lat = parseFloat(this.Segments[0].Points[0].split(",")[0]);
        // this.long = parseFloat(this.Segments[0].Points[0].split(",")[1]);


        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });


        var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });

          var baseMaps1 = {
            "Street": osm,
            "Satellite": mqi
          };
  
          var len = data.length;
  
          var PavementfeatureGroup = L.featureGroup()
          var RidefeatureGroup = new L.featureGroup();
          var CombinedfeatureGroup = new L.featureGroup();
         
  
          for (var i = 0; i < len; i++) {
            // this.Segments.forEach(e => {
            var e = new tbl_SegmentAssessment();
            e = data[i];
            var point = new Array<LatLng>();
  
            if (e.points.length != 0) {
              e.points.forEach(p => {
                p = p.replace('(', '')
                p = p.replace(')', '')
                var lat = parseFloat(p.split(",")[0]);
                var long = parseFloat(p.split(",")[1]);
  
                point.push(new L.LatLng(lat, long));
              });
              var polylinePoints = [
                point
                //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
              ];
              //overallrating
              var iconColor = "#000000";
              if (e.overallrating == "Severe") {
                iconColor = "#cc0000";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: iconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "CombinedfeatureGroup", e, this));
                firstpolyline.addTo(CombinedfeatureGroup);
                CombinedfeatureGroup.bringToBack();
              } else if (e.overallrating == "Light") {
                iconColor = "#FFFF00";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: iconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "CombinedfeatureGroup", e, this));
                firstpolyline.addTo(CombinedfeatureGroup);
                CombinedfeatureGroup.bringToBack();
              } else if (e.overallrating == "Moderate") {
                iconColor = "#FE9903";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: iconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "CombinedfeatureGroup", e, this));
                firstpolyline.addTo(CombinedfeatureGroup);
                CombinedfeatureGroup.bringToBack();
              } else if (e.overallrating == "Good") {
                iconColor = "#276A27";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: iconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "CombinedfeatureGroup", e, this));
                firstpolyline.addTo(CombinedfeatureGroup);
                CombinedfeatureGroup.bringToBack();
  
              } else {
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: iconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "CombinedfeatureGroup", e, this));
                firstpolyline.addTo(CombinedfeatureGroup);
                CombinedfeatureGroup.bringToBack();
              }
  
              //pavementdistressrating
              var pavementdistressiconColor = "#000000";
              if (e.pavementdistressrating == "Severe") {
                pavementdistressiconColor = "#cc0000";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: pavementdistressiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "PavementfeatureGroup", e, this));
                firstpolyline.addTo(PavementfeatureGroup);
                PavementfeatureGroup.bringToBack();
              } else if (e.pavementdistressrating == "Light") {
                pavementdistressiconColor = "#FFFF00";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: pavementdistressiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "PavementfeatureGroup", e, this));
                firstpolyline.addTo(PavementfeatureGroup);
                PavementfeatureGroup.bringToBack();
              } else if (e.pavementdistressrating == "Moderate") {
                pavementdistressiconColor = "#FE9903";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: pavementdistressiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "PavementfeatureGroup", e, this));
                firstpolyline.addTo(PavementfeatureGroup);
                PavementfeatureGroup.bringToBack();
              } else if (e.pavementdistressrating == "Good") {
                pavementdistressiconColor = "#276A27";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: pavementdistressiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "PavementfeatureGroup", e, this));
                firstpolyline.addTo(PavementfeatureGroup);
                PavementfeatureGroup.bringToBack();
  
              } else {
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: pavementdistressiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "PavementfeatureGroup", e, this));
                firstpolyline.addTo(PavementfeatureGroup);
                PavementfeatureGroup.bringToBack();
              }
  
              //ridequalityrating
              var ridequalityratingiconColor = "#000000";
              if (e.ridequalityrating == "Severe") {
                ridequalityratingiconColor = "#cc0000";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: ridequalityratingiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "RidefeatureGroup", e, this));
                firstpolyline.addTo(RidefeatureGroup);
                RidefeatureGroup.bringToBack();
              } else if (e.ridequalityrating == "Light") {
                ridequalityratingiconColor = "#FFFF00";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: ridequalityratingiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "RidefeatureGroup", e, this));
                firstpolyline.addTo(RidefeatureGroup);
                RidefeatureGroup.bringToBack();
              } else if (e.ridequalityrating == "Moderate") {
                ridequalityratingiconColor = "#FE9903";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: ridequalityratingiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "RidefeatureGroup", e, this));
                firstpolyline.addTo(RidefeatureGroup);
                RidefeatureGroup.bringToBack();
              } else if (e.ridequalityrating == "Good") {
                ridequalityratingiconColor = "#276A27";
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: ridequalityratingiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "RidefeatureGroup", e, this));
                firstpolyline.addTo(RidefeatureGroup);
                RidefeatureGroup.bringToBack();
  
              } else {
                var firstpolyline = new L.polyline(polylinePoints, {
                  color: ridequalityratingiconColor,
                  weight: 4,
                  opacity: 1,
                  smoothFactor: 1
                }).on('click', this.MarkerClick.bind(null, "RidefeatureGroup", e, this));
                firstpolyline.addTo(RidefeatureGroup);
                RidefeatureGroup.bringToBack();
              }
            }
  
          }
          var groupedOverlays = {
            "Assessment Options": {
              "Pavement Distress": PavementfeatureGroup,
              "Ride Quality": RidefeatureGroup,
              "Combined": CombinedfeatureGroup
            }
          };
          this.MainMapactiveradio = localStorage.getItem("MainMapactiveradio")
         
          if (this.MainMapactiveradio != null) {
            if (this.MainMapactiveradio == "Pavement Distress") {
              PavementfeatureGroup.addTo(this.map);
            } else if (this.MainMapactiveradio == "Ride Quality") {
              RidefeatureGroup.addTo(this.map);
            } else {
              CombinedfeatureGroup.addTo(this.map);
            }
          } else {
            PavementfeatureGroup.addTo(this.map);
          }
          var options = {
            // Make the "Landmarks" group exclusive (use radio inputs)
            exclusiveGroups: ["Assessment Options"],
            // Show a checkbox next to non-exclusive group labels for toggling all
            groupCheckboxes: true,
            position: 'topleft'
          };
  
          this.map.on('overlayadd', function (e) {
            localStorage.setItem("MainMapactiveradio", e.name)
          });
  
          // .on('overlayadd', this.onOverlayAdd());
  
          this.layerControl = L.control.groupedLayers(baseMaps1, groupedOverlays, options);
          this.map.addControl(this.layerControl)

        // SearchResult

        var geocoder = L.Control.geocoder({
          defaultMarkGeocode: false,
          position: "topleft",
        })
          .on('markgeocode', function(e) {
           
            var latlng = e.geocode.center;
            if(e.target._lastGeocode.split(',').length<2){
              var marker = L.marker(latlng).addTo(self.map);
            }else{
              var marker = L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]).addTo(self.map);
            }
             self.map.fitBounds(e.geocode.bbox);
            
          })
          .addTo(self.map);
        // var layerresults = new L.LayerGroup().addTo(this.map);
        // var geocoder = L.Control.geocoder({
        //   defaultMarkGeocode: false,
        //   position: "topleft"
        // })
        //   .on('markgeocode', function (e) {
        //     console.log(e)
        //     layerresults.clearLayers();
        //     // L.marker([e.geocode.center.lat,e.geocode.center.lng]).addTo(self.map);
        //     console.log(parseFloat(e.target._lastGeocode.split(',')[0]) + ',' + parseFloat(e.target._lastGeocode.split(',')[1]))
        //     layerresults.addLayer(L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]));
        //     // L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]).addTo(self.map);
        //     // L.marker([e.geocode.center.lat, e.geocode.center.lng]).addTo(self.map);
        //     var latlong = [
        //       [parseFloat(e.target._lastGeocode.split(',')[0]),parseFloat(e.target._lastGeocode.split(',')[1])]
        //       //  [e.geocode.center.lat, e.geocode.center.lng]
        //       //     //     //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
        //     ];
        //     self.map.fitBounds([latlong]);
        //   })
        //   .addTo(self.map);

        // var gc = [{
        //   url: "http://gis1.meridenct.gov/arcgis/rest/services/MeridenGeocoder/GeocodeServer",
        //   name: "MeridenGeocoder",
        //   singleLineFieldName: "Street"
        // }];
        // var searchControl = L.esri.Geocoding.geosearch({
        //   collapseAfterResult: true,
        //   useMapBounds: false,
        //   zoomToResult: true,
        //   autocomplete: true,
        //   // allowMultipleResults:false,
        //   // geocoders: gc,
        //   providers: [esriGeoCoder.arcgisOnlineProvider({
        //    maxResults: 1,
        //     nearby: {
        //       lat: this.lat, 
        //       lng: this.long,
        //     },
        //   })]
        // }).addTo(this.map);
        // var layerresults = new L.LayerGroup().addTo(this.map);
        // searchControl.on("results", function(data){
        //   layerresults.clearLayers();
        //   console.log(data)
        //   for (var i = data.results.length - 1; i >= 0; i--) {
        //     console.log(data.results[i].latlng)
        //      if (i == data.results.length - 1) {
        //       layerresults.addLayer(L.marker(data.results[i].latlng));

        //     //  var latlong= [
        //     //   [data.results[i].latlng.lat,data.results[i].latlng.lng]
        //     //     //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
        //     //   ];
        //     //   self.map.fitBounds([latlong])
        //     }
        //   }
        // });


       
        this.cdRef.detectChanges();
        this.RetriveGeoFence();
      });
    // var markers = L.markerClusterGroup({ chunkedLoading: true });

  }


  exportToPdf() {
    sessionStorage.removeItem('segment')
    this.modalcontent.hide()
    // this.popupsegment.sliderMonth=this.sliderMonth;
    //this.popupsegment.BackMonth=this.BackMonth;
    sessionStorage.setItem('segment', JSON.stringify(this.popupsegment))
    // this.router.navigate(['/PDFLineSegment',this.popupsegment.wayid,this.popupsegment.segmentid,this.Type]).then(result => {  
    window.open('/#/PDFLineSegment/' + this.popupsegment.wayid + '/' + this.popupsegment.segmentid + '/' + this.Type, '');
    // });
  }


  RetriveGeoFence() {

    var featureGroup = L.featureGroup().addTo(this.map);
    this.DrawnFences = Array<GeoData>();
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {


        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();


      })

  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
  }
  popupsegment = new tbl_SegmentAssessment();
  popupsegmentimages = new tbl_SegmentAssessment();
  chkBoundingBox = false;
  Pavementroi = "pavement-with-roi"
  PavementroiLaneline = "pavement-with-roi"
  HeatmapRadio = "PavementfeatureGroup"
  // imu = "imu"
  TypeOfHeatMap = "Pavement Distress Hotspots";
  MarkerClick(type, segment: tbl_SegmentAssessment, self, e) {

    type = localStorage.getItem("MainMapactiveradio")
    var obj = self as this;
    obj.http.get<tbl_SegmentAssessment>(API.GetSegment + segment.assessmentsegmentid + "?Type=" + obj.Type)
      .subscribe(data => {
        segment = data;

        obj.HeatmapRadio = localStorage.getItem("MainMapactiveradio")
        if (type == "RidefeatureGroup") {
          obj.TypeOfHeatMap = "Ride Quality"

        } else if (type == "PavementfeatureGroup") {
          obj.TypeOfHeatMap = "Pavement Distress Hotspots"
        } else {
          obj.TypeOfHeatMap = "Pavement Distress & Ride Quality "
        }

        if (obj.chkBoundingBox == true) {
          obj.Pavementroi = "pavement-with-roi-bbox"
        } else {
          obj.Pavementroi = "pavement-with-roi"
        }

        if (self.chkBoundingBox == true) {
          self.PavementroiLaneline = "segment-summary"
        } else {
          self.PavementroiLaneline = "segment-summary"
        }
        obj.http.get<tbl_SegmentAssessment>(API.LaneLinePopup + segment.assessmentsegmentid + "?Type=" + obj.Type + "&WayID=" + segment.wayid )
          .subscribe(data => {
            obj.popupsegmentimages = data;


          });
        obj._restservice.Call<tbl_SegmentAssessment>('GET', API.SegmentPopup  + segment.assessmentsegmentid + "?Type=" + obj.Type + "&WayID=" + segment.wayid, null)
          .subscribe(data => {
            obj.popupsegment = data;
            obj.popupsegment.StartDate = segment.StartDate;

            var length = (obj.popupsegment.points.length / 2).toFixed(0)
            obj.popupsegment.middlepoint = obj.popupsegment.points[length]
            obj.popupsegment.middlepoint = obj.popupsegment.middlepoint.replace('(', '')
            obj.popupsegment.middlepoint = obj.popupsegment.middlepoint.replace(')', '')
            obj.popupsegment.lat = parseFloat(obj.popupsegment.middlepoint.split(',')[0]).toString() //.toFixed(6)
            obj.popupsegment.long = parseFloat(obj.popupsegment.middlepoint.split(',')[1]).toString()//.toFixed(6)

            segment = data
            obj.cdRef.detectChanges();
            obj.modalcontent = obj.BSmodalService.show(obj.content, { class: 'modal-xl modal-dialog-scrollable mt-2' });
            // this.modalService.open(this.input, {windowClass: 'modal-xxl' });
            obj.OtherMap(type)

            var Light = segment.TroubleSpots_cd.LightValue;
            var Severe = segment.TroubleSpots_cd.SevereValue;
            var Moderate = segment.TroubleSpots_cd.ModerateValue;
            var None = segment.TroubleSpots_cd.NoneValue;
            // var Light = 0;
            //  var Severe = 0;
            // var Moderate = 0;
            //  var None = obj.popupsegment.TroubleSpots_cd.EmptyImage;
             var TotalTrouble = 0;
            // console.log(segment)
            // if (segment.ConcentrationList_cd != null && segment.ConcentrationList_cd != undefined) {
            //   // let result = segment.ConcentrationList_cd.map(function (a) { return a.SampleCount; });
            //   if (segment.ConcentrationList_cd.length > 0) {

            //     var maxcount = segment.ConcentrationList_cd.length
            //     //result.reduce((a, b) => Math.max(a, b));
            //     for (let i = 0; i <= maxcount; i++) {
            //       if (segment.ConcentrationList_cd[i] != undefined) {
            //         if (segment.ConcentrationList_cd[i].Rating == "Light") {
            //           Light += segment.ConcentrationList_cd[i].SampleCount;
            //         } 
            //          if (segment.ConcentrationList_cd[i].Rating == "Severe") {
            //           Severe += segment.ConcentrationList_cd[i].SampleCount;
            //         } 
            //          if (segment.ConcentrationList_cd[i].Rating == "Moderate") {
            //           Moderate += segment.ConcentrationList_cd[i].SampleCount;
            //         } 
            //         if (segment.ConcentrationList_cd[i].Rating == "None") 
            //         {
            //           None += segment.ConcentrationList_cd[i].SampleCount;
            //         }
            //       }
            //     }
            //   }
            // }
            TotalTrouble = Light + Severe + Moderate + None;
            Severe = ((Severe * 100 / TotalTrouble));
            Light = ((Light * 100 / TotalTrouble));
            Moderate = ((Moderate * 100 / TotalTrouble));
            None = ((None * 100 / TotalTrouble));
          
            let optionsTroble: ChartOptions = {
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false
              },
              scales: {
                yAxes: [{
                  ticks: {
                    fontColor: "#040404",
                    beginAtZero: true
                  },

                }],
                xAxes: [{
                  ticks: {
                    fontColor: "#040404",
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    stepSize: 20
                  }

                }]
              },
              title: {
                display: true,
                text: 'Sample Count',
                position: 'bottom'
              },
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    var dataArr = ctx.chart.data.datasets[0].data as number[];
                    let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
                    if (total == 0) {
                      return "0%";
                    } else {
                      let percentage = (value * 100 / total).toFixed(0) + "%";
                      return percentage;
                    }
                  },
                  color: 'black',
                  align: 'right',
                  anchor: 'start',
                  textAlign: 'center'
                }
              }
            };
            let optionsPeavment: ChartOptions = {
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false
              },
              scales: {
                yAxes: [{
                  ticks: {
                    display: false
                  }
                  // ticks: {
                  //   fontColor:"#040404",
                  //   beginAtZero: true
                  // }
                }],
                xAxes: [{
                  ticks: {
                    fontColor: "#040404",
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    stepSize: 20
                  }
                }]
              },
              title: {
                display: true,
                text: 'Percentage of Images',
                position: 'bottom'
              },
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    //   var dataArr = ctx.chart.data.datasets[0].data as number[];
                    //   let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
                    //   if(total==0){
                    //     return "0%";
                    //   } else{   
                    //   let percentage=((value/total)*100).toFixed(0) +"%" 
                    //  // let percentage = (value * 100 / total).toFixed(2) + "%";
                    //   return percentage;
                    // }
                    return value + "%";
                  },
                  color: 'black',
                  align: 'right',
                  anchor: 'start',
                  textAlign: 'center'
                }
              }
            };
            let optionsRide: ChartOptions = {
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false
              },
              scales: {
                yAxes: [{
                  ticks: {
                    display: false
                  }
                  // ticks: {
                  //   fontColor:"#040404",
                  //   beginAtZero: true
                  // }
                }],
                xAxes: [{
                  ticks: {
                    fontColor: "#040404",
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    stepSize: 20
                  }
                }]
              },
              title: {
                display: true,
                text: 'Percentage of Samples',
                position: 'bottom'
              },
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    //   var dataArr = ctx.chart.data.datasets[0].data as number[];
                    //   let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
                    //   if(total==0){
                    //     return "0%";
                    //   } else{   
                    //   let percentage=((value/total)*100).toFixed(0) +"%" 
                    //  // let percentage = (value * 100 / total).toFixed(2) + "%";
                    //   return percentage;

                    // }

                    return value + "%";
                  },
                  color: 'black',
                  align: 'right',
                  anchor: 'start',
                  textAlign: 'center'
                }
              }
            };


            var crt = document.getElementById('myChartTrouble') as HTMLCanvasElement;
            crt.height = 200;
            new Chart(crt, {
              plugins: [ChartDataLabels],
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [Severe, Moderate, Light, None],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],
                  borderWidth: 1,
                  barPercentage: 0.4,
                  barThickness: 18,  // number (pixels) or 'flex'
                  maxBarThickness: 18 // number (pixels)
                }]
              },
              options:
                optionsTroble,

            });

            //Longitudinal Cracks
            var canvasRoadchart1 = document.getElementById('canvasRoadchart1') as HTMLCanvasElement;
            canvasRoadchart1.height = 170;
            new Chart(canvasRoadchart1, {
              plugins: [ChartDataLabels],
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.LongitudinalCracks_cd.SevereValue, segment.LongitudinalCracks_cd.ModerateValue, segment.LongitudinalCracks_cd.LightValue, segment.LongitudinalCracks_cd.NoneValue],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],

                  borderWidth: 1
                }]
              },
              options: optionsPeavment
            });

            //Lateral Cracks
            var canvasRoadchart2 = document.getElementById('canvasRoadchart2') as HTMLCanvasElement;
            canvasRoadchart2.height = 170;
            new Chart(canvasRoadchart2, {
              plugins: [ChartDataLabels],
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.LateralCracks_cd.SevereValue, segment.LateralCracks_cd.ModerateValue, segment.LateralCracks_cd.LightValue, segment.LateralCracks_cd.NoneValue],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],
                  borderWidth: 1
                }]
              },
              options: optionsPeavment
            });

            //Fatigue Cracks
            var canvasRoadchart3 = document.getElementById('canvasRoadchart3') as HTMLCanvasElement;
            canvasRoadchart3.height = 170;
            new Chart(canvasRoadchart3, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.FatigueCracks_cd.SevereValue, segment.FatigueCracks_cd.ModerateValue, segment.FatigueCracks_cd.LightValue, segment.FatigueCracks_cd.NoneValue],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],
                  borderWidth: 1
                }]
              },
              options: optionsPeavment,
              plugins: [ChartDataLabels],
            });

            //Potholes
            var canvasRoadchart4 = document.getElementById('canvasRoadchart4') as HTMLCanvasElement;
            canvasRoadchart4.height = 170;
            new Chart(canvasRoadchart4, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.Potholes_cd.SevereValue, segment.Potholes_cd.ModerateValue, segment.Potholes_cd.LightValue, segment.Potholes_cd.NoneValue],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],
                  borderWidth: 1
                }]
              },
              options: optionsPeavment,
              plugins: [ChartDataLabels],
            });

            //Patches
            var canvasRoadchart5 = document.getElementById('canvasRoadchart5') as HTMLCanvasElement;
            canvasRoadchart5.height = 170;
            new Chart(canvasRoadchart5, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.Repairs_cd.SevereValue, segment.Repairs_cd.ModerateValue, segment.Repairs_cd.LightValue, segment.Repairs_cd.NoneValue],
                  backgroundColor: [
                    '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
                  ],
                  borderWidth: 1
                }]
              },
              options: optionsPeavment,
              plugins: [ChartDataLabels],
            });

            //Bumps
            var canvaschart1 = document.getElementById('canvaschart1') as HTMLCanvasElement;
            new Chart(canvaschart1, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.Bumps_cd.SevereValue, segment.Bumps_cd.ModerateValue, segment.Bumps_cd.LightValue, segment.Bumps_cd.NoneValue],
                  backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
                  borderWidth: 1
                }]
              },
              options: optionsRide,
              plugins: [ChartDataLabels],
            });

            //Dips/Lifts
            var canvaschart2 = document.getElementById('canvaschart2') as HTMLCanvasElement;
            new Chart(canvaschart2, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.DipsLifts_cd.SevereValue, segment.DipsLifts_cd.ModerateValue, segment.DipsLifts_cd.LightValue, segment.DipsLifts_cd.NoneValue],
                  backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
                  borderWidth: 1
                }]
              },
              options: optionsRide,
              plugins: [ChartDataLabels],
            });

            //Rough Spots
            var canvaschart3 = document.getElementById('canvaschart3') as HTMLCanvasElement;
            new Chart(canvaschart3, {
              type: 'horizontalBar',
              data: {
                labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
                datasets: [{
                  data: [segment.Roughness_cd.SevereValue, segment.Roughness_cd.ModerateValue, segment.Roughness_cd.LightValue, segment.Roughness_cd.NoneValue],
                  backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
                  borderWidth: 1
                }]
              },
              options: optionsRide,
              plugins: [ChartDataLabels],
            });
          });
      });
  }
  public polylinePoints: any
  OtherMap(Type: string) {
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }

    // this._restservice.Call<tbl_SegmentAssessment[]>('GET', API.SegmentCluster + this.RegionID + "?SegmentID="+ this.popupsegment.segmentid + "&WayID=" + this.popupsegment.wayid + "&StartDate=" + this.popupsegment.StartDate +"&MonthID=" + $('#MonthID').val() + "&Year=" + $('#YearID').val() +
    // "&IsPrevious=" + isPrevious + "&BackMonth=" + (this.BackMonth - 1), null)
    // .subscribe(data => {


    document.getElementById('map1').innerHTML = "<div id='Pavementmap1' style='width:335px;height:335px'></div>";
    this.Pavementmap1 = L.map('Pavementmap1', {
      scrollWheelZoom: 'center',
      center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
    }).addTo(this.Pavementmap1)
    var point = new Array<LatLng>();
    this.popupsegment.points.forEach(p => {
      p = p.replace('(', '')
      p = p.replace(')', '')
      var lat = parseFloat(p.split(",")[0]);
      var long = parseFloat(p.split(",")[1]);

      point.push(new L.LatLng(lat, long));
    });
    this.polylinePoints = [
      point
      //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
    ];
    var firstpolyline = new L.polyline(this.polylinePoints, {
      color: "#3333ff",
      weight: 4,
      opacity: 1,
      smoothFactor: 1
    })
    var ZoomLevel2 = this.Pavementmap1.getBoundsZoom([this.polylinePoints])
    this.Pavementmap1.fitBounds([this.polylinePoints])
    var firstpolyline1 = new L.polyline(this.polylinePoints, {
      color: "#3333ff",
      weight: 4,
      opacity: 1,
      smoothFactor: 1
    })


    firstpolyline1.addTo(this.Pavementmap1);

    document.getElementById('map2').innerHTML = "<div id='Pavementmap2' style='width:335px;height:335px'></div>";
    var Pavementmap2 = L.map('Pavementmap2', {
      scrollWheelZoom: 'center',
      center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: ZoomLevel2 - 4
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
      maxZoom: 19,
    }).addTo(Pavementmap2)


    firstpolyline.addTo(Pavementmap2);


    L.marker([this.popupsegment.lat, this.popupsegment.long]).addTo(Pavementmap2);
    this.Pavementmap3 = undefined
    this.HeatMap(Type)
  }
  public heatmapcurrentZoom = 13;
  public Pavementmap3: any;
  public Pavementmap1: any;
  public layerControlheatmap;
  public layerControlSegment;
  HeatMap(Type: string) {

    //   if(this.Pavementmap3==undefined){
    //     this.heatmapcurrentCenter=new L.LatLng(this.popupsegment.lat, this.popupsegment.long)
    //   }else{
    //     this.heatmapcurrentZoom =this.Pavementmap3.getZoom();
    //   this.heatmapcurrentCenter = this.Pavementmap3.getCenter();
    // }
    document.getElementById('map3').innerHTML = "<div id='Pavementmap3' style='width:335px;height:335px'></div>";
    this.Pavementmap3 = L.map('Pavementmap3', {
      scrollWheelZoom: 'center',
      center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
    }).addTo(this.Pavementmap3)
    var firstpolyline1 = new L.polyline(this.polylinePoints, {
      color: "#3333ff",
      weight: 4,
      opacity: 1,
      smoothFactor: 1
    })


    firstpolyline1.addTo(this.Pavementmap3);
    this.Pavementmap3.fitBounds([this.polylinePoints])
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    var arrymonth = $('#MonthID').val() as string;
    var sendMonths = parseInt(arrymonth);
    var converageMonth = this.BackMonth;
    if (this.Type == "Q") {
      isPrevious = true
      if (parseInt(arrymonth) == 1) {
        sendMonths = 3
      } else if (parseInt(arrymonth) == 2) {
        sendMonths = 6
      }
      else if (parseInt(arrymonth) == 3) {
        sendMonths = 9
      } else {
        sendMonths = 12
      }
      if (this.BackMonth <= 0) {
        converageMonth = 3
      } else {
        converageMonth = this.BackMonth * 3
      }
    }
    this.popupsegment.BackMonth = this.MonthValue;

    this.popupsegment.Year = $('#YearID').val() as number;
    this.popupsegment.sendMonths = sendMonths
    this._restservice.Call<tbl_AssessmentCluster[]>('GET', API.SegmentPopupHeatmap + this.popupsegment.segmentid + "/" + this.Type + "?MonthIDs=" + sendMonths + "&Year=" + $('#YearID').val() +
      "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1) + "&clusertype=" + Type + "&BoundingBox=" + this.chkBoundingBox, null)
      .subscribe(data => {
        var len = data.length;
        this.Pavementmap3.fitBounds([this.polylinePoints])

        var PavementfeatureGroup = new L.featureGroup();
        var RidefeatureGroup = new L.featureGroup()
        var CombinedfeatureGroup = new L.featureGroup();

        var SegmentPavementfeaturepoint = new L.featureGroup()
        var SegmentPavementUnassessedfeaturepoint = new L.featureGroup();
        var SegmentRidefeaturepoint = new L.featureGroup();
        var SegmentRoadwayfeaturepoint = new L.featureGroup();
        var SegmentVirtualfeaturepoint = new L.featureGroup()

        if (Type == "Combined") {
          CombinedfeatureGroup.addTo(this.Pavementmap3)
          SegmentPavementfeaturepoint.addTo(this.Pavementmap1)
          SegmentRidefeaturepoint.addTo(this.Pavementmap1)
          SegmentVirtualfeaturepoint.addTo(this.Pavementmap1)

        } else if (Type == "Ride Quality") {
          RidefeatureGroup.addTo(this.Pavementmap3)
          SegmentRidefeaturepoint.addTo(this.Pavementmap1)
          SegmentVirtualfeaturepoint.addTo(this.Pavementmap1)
        } else {
          PavementfeatureGroup.addTo(this.Pavementmap3)
          SegmentPavementfeaturepoint.addTo(this.Pavementmap1)
          SegmentVirtualfeaturepoint.addTo(this.Pavementmap1)
        }

        var Pavementfeaturepoint = new Array<InsideMapPoint>();
        var Ridefeaturepoint = new Array<InsideMapPoint>();
        var Combinedfeaturepoint = new Array<InsideMapPoint>();




        var SegmentgroupedOverlays = {
          "Points of Interest": {
            "Pavement Distress Assessed <span><div class='arrow-down pull-right m-t-xs'> </div></span>": SegmentPavementfeaturepoint,
            "Pavement Distress Unassessed <span><div class='arrow-up pull-right m-t-xs'> </div></span>": SegmentPavementUnassessedfeaturepoint,
            "Roadway Objects <span class='pull-right' style='margin-top:10px'><div class='hexagon '> </div></span>": SegmentRoadwayfeaturepoint,
            "Ride Quality <span><div class='rectangle pull-right m-t-sm'> </div></span>": SegmentRidefeaturepoint,
            "Virtual Drive <span class='pull-right'><div class='oval m-t-sm'> </div></span>": SegmentVirtualfeaturepoint
          }
        };
        var optionsmap = {
          // Make the "Landmarks" group exclusive (use radio inputs)
          // exclusiveGroups: ["Assessment Options"],
          // // Show a checkbox next to non-exclusive group labels for toggling all
          // groupCheckboxes: true,
          position: 'topright'
        };
        this.layerControlSegment = L.control.groupedLayers(null, SegmentgroupedOverlays, optionsmap)
        this.Pavementmap1.addControl(this.layerControlSegment);

        const ArrowDownMarker = L.divIcon({
          className: "arrow-down"
        });
        const ArrowUpMarker = L.divIcon({
          className: "arrow-up"
        });
        const HexagonMarker = L.divIcon({
          className: "hexagon"
        });
        const RectangleMarker = L.divIcon({
          className: "rectangle"
        });
        const OvalMarker = L.divIcon({
          className: "oval"
        });
        var PDA=new Array<tbl_AssessmentCluster>();
        var PDU=new Array<tbl_AssessmentCluster>();
        var RO=new Array<tbl_AssessmentCluster>();
        var RQ=new Array<tbl_AssessmentCluster>();
       
        for (var i = 0; i < len; i++) {

          var se = new tbl_AssessmentCluster();
          se = data[i];

          var point = new InsideMapPoint();
          var item = data[i];
          point.lat = item.Lat;
          point.lng = item.Long;
          if (item.MinAssessment > 6) {
            point.value = 0.3
          }
          else if (item.MinAssessment >= 4 && item.MinAssessment <= 6) {
            point.value = 0.6
          }
          else if (item.MinAssessment >= 0 && item.MinAssessment < 4) {
            point.value = 1
          }
          //PavementfeatureGroup
          if (data[i].ClusterType.filter(s => s == "Pavement Condition").length > 0) {

            if (data[i].subclustertype.filter(s => s == "Potholes" || s == "Longitudinal Cracks Unsealed" || s == "Fatigue Cracks Unsealed" || s == "Lateral Cracks Unsealed").length > 0) {
              PDA.push(se)
              Pavementfeaturepoint.push(point);
              //Combined
              Combinedfeaturepoint.push(point);
            

            }
            if (data[i].subclustertype.filter(s => s == "Repairs" || s == "Longitudinal Cracks Sealed" || s == "Fatigue Cracks Sealed" || s == "Lateral Cracks Sealed" || s == "Edge Drop-Off Characteristics").length > 0) {
              PDU.push(se)
             

            }
            if (data[i].subclustertype.filter(s => s == "Storm Water Drain" || s == "Storm Water Pipe" || s == "Manhole Cover" || s == "Handhole Cover" || s == "Lane Reflector").length > 0) {
              RO.push(se)
             

            }

          }
          //RidefeatureGroup
          if (data[i].ClusterType.filter(s => s == "Ride Quality").length > 0) {
            if (data[i].subclustertype.filter(s => s == "Dips/Lifts" || s == "Roughness" || s == "Bumps").length > 0) {
              RQ.push(se)
              Ridefeaturepoint.push(point);
              //Combined
              Combinedfeaturepoint.push(point);
            
            }
          }
        }
       
        PDA.forEach(a => {
          L.marker(L.latLng(a.Lat, a.Long), { icon: ArrowDownMarker })
          .addTo(SegmentPavementfeaturepoint)
          .on('click', this.SegmentMarkerClick.bind(null, a.AllImg, this));
        SegmentPavementfeaturepoint.bringToBack();
        });
        PDU.forEach(a => {
          L.marker(L.latLng(a.Lat, a.Long), { icon: ArrowUpMarker })
          .addTo(SegmentPavementUnassessedfeaturepoint)
          .on('click', this.SegmentMarkerClick.bind(null, a.AllImg, this));
        SegmentPavementUnassessedfeaturepoint.bringToBack();
        });
        RO.forEach(a => {
          L.marker(L.latLng(a.Lat, a.Long), { icon: HexagonMarker })
          .addTo(SegmentRoadwayfeaturepoint)
          .on('click', this.SegmentMarkerClick.bind(null, a.AllImg, this));

        SegmentRoadwayfeaturepoint.bringToBack();
        });
        RQ.forEach(a => {
          L.marker(L.latLng(a.Lat, a.Long), { icon: RectangleMarker })
          .addTo(SegmentRidefeaturepoint)
          .on('click', this.SegmentMarkerClick.bind(null, a.AllImg, this));
        SegmentRidefeaturepoint.bringToBack();
        });
        if (this.popupsegmentimages.ImagePopup_cd != null) {

          var p1 = new Array<string>();
          p1.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['1'] as any).ImageURL)
          // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['1'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['1'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
          //   .addTo(SegmentVirtualfeaturepoint)
          //   .on('click', this.SegmentMarkerClick.bind(null, p1, this));

          L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['1'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['1'] as any).gpslong), { icon: OvalMarker })
            .addTo(SegmentVirtualfeaturepoint)
            .on('click', this.SegmentMarkerClick.bind(null, p1, this));

          SegmentVirtualfeaturepoint.bringToBack();

          var p2 = new Array<string>();
          p2.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['2'] as any).ImageURL)
          // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['2'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['2'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
          //   .addTo(SegmentVirtualfeaturepoint)
          //   .on('click', this.SegmentMarkerClick.bind(null, p2, this));

          L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['2'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['2'] as any).gpslong), { icon: OvalMarker })
            .addTo(SegmentVirtualfeaturepoint)
            .on('click', this.SegmentMarkerClick.bind(null, p2, this));

          SegmentVirtualfeaturepoint.bringToBack();

          var p3 = new Array<string>();
          p3.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['3'] as any).ImageURL)
          // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['3'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['3'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
          //   .addTo(SegmentVirtualfeaturepoint)
          //   .on('click', this.SegmentMarkerClick.bind(null, p3, this));

          L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['3'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['3'] as any).gpslong), { icon: OvalMarker })
            .addTo(SegmentVirtualfeaturepoint)
            .on('click', this.SegmentMarkerClick.bind(null, p3, this));

          SegmentVirtualfeaturepoint.bringToBack();

          var p4 = new Array<string>();
          if (this.popupsegmentimages.ImagePopup_cd['4'] != undefined) {
            p4.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['4'] as any).ImageURL)
            // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['4'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['4'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
            //   .addTo(SegmentVirtualfeaturepoint)
            //   .on('click', this.SegmentMarkerClick.bind(null, p4, this));


            L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['4'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['4'] as any).gpslong), { icon: OvalMarker })
              .addTo(SegmentVirtualfeaturepoint)
              .on('click', this.SegmentMarkerClick.bind(null, p4, this));

            SegmentVirtualfeaturepoint.bringToBack();
          }
          var p5 = new Array<string>();
          if (this.popupsegmentimages.ImagePopup_cd['5'] != undefined) {
            p5.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['5'] as any).ImageURL)
            // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['5'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['5'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
            //   .addTo(SegmentVirtualfeaturepoint)
            //   .on('click', this.SegmentMarkerClick.bind(null, p5, this));

            L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['5'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['5'] as any).gpslong), { icon: OvalMarker })
              .addTo(SegmentVirtualfeaturepoint)
              .on('click', this.SegmentMarkerClick.bind(null, p5, this));

            SegmentVirtualfeaturepoint.bringToBack();
          }
          var p6 = new Array<string>();
          if (this.popupsegmentimages.ImagePopup_cd['6'] != undefined) {
            p6.push(this.ImageCDN + this.PavementroiLaneline + (this.popupsegmentimages.ImagePopup_cd['6'] as any).ImageURL)
            // L.circleMarker(L.latLng((this.popupsegmentimages.ImagePopup_cd['6'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['6'] as any).gpslong), { radius: 5, fillColor: "#663300", color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
            //   .addTo(SegmentVirtualfeaturepoint)
            //   .on('click', this.SegmentMarkerClick.bind(null, p6, this));

            L.marker(L.latLng((this.popupsegmentimages.ImagePopup_cd['6'] as any).gpslat, (this.popupsegmentimages.ImagePopup_cd['6'] as any).gpslong), { icon: OvalMarker })
              .addTo(SegmentVirtualfeaturepoint)
              .on('click', this.SegmentMarkerClick.bind(null, p6, this));

            SegmentVirtualfeaturepoint.bringToBack();
          }
        }

        var firstpolyline = new L.heatLayer(Pavementfeaturepoint, {
          maxZoom: 13
        })
       
        firstpolyline.addTo(PavementfeatureGroup);
        PavementfeatureGroup.bringToBack();

        var firstpolyline1 = new L.heatLayer(Ridefeaturepoint, {
          maxZoom: 13
        })
        firstpolyline1.addTo(RidefeatureGroup);
        RidefeatureGroup.bringToBack();

        var firstpolyline2 = new L.heatLayer(Combinedfeaturepoint, {
          maxZoom: 13
        })
        firstpolyline2.addTo(CombinedfeatureGroup);
        CombinedfeatureGroup.bringToBack();

        var groupedOverlays = {
          "Assessment Options": {
            "Pavement Distress": PavementfeatureGroup,
            "Ride Quality": RidefeatureGroup,
            "Combined": CombinedfeatureGroup
          }
        };

        var options = {
          // Make the "Landmarks" group exclusive (use radio inputs)
          exclusiveGroups: ["Assessment Options"],
          // Show a checkbox next to non-exclusive group labels for toggling all
          groupCheckboxes: true,
          position: 'topright'
        };
        this.layerControlheatmap = L.control.groupedLayers(null, groupedOverlays, options)
        this.Pavementmap3.addControl(this.layerControlheatmap);
      })
  }

  HeatMapSelect(e, type, self) {
    var obj = self as this;
    obj.HeatmapRadio = type
    if (type == "RidefeatureGroup") {
      obj.TypeOfHeatMap = "Ride Quality"

    } else if (type == "PavementfeatureGroup") {
      obj.TypeOfHeatMap = "Pavement Distress Hotspots"
    } else {
      obj.TypeOfHeatMap = "Pavement Distress & Ride Quality "
    }
  }
  //   ,{
  //     radius: 10,
  //      blur: 10, 
  //     maxZoom: 10
  // }
  // gradient: {
  //   0.1: '#FFFF00',
  //   0.2: '#FE9903',
  //   0.3: '#fd2e03'
  // }
  //   gradient: {
  //     0.1: '#FFFF00',
  //     0.2: '#FE9903',
  //     0.3: '#ff6747'
  // },
  // gradient: {
  //   1: '#cc0000',
  //   0.2: '#FE9903',
  //   0.4: '#fbf700',
  //   0.6: '#fbb300',
  //   0.8: '#276A27'

  // }
  imgdata: string;
  imgPopup(url: string) {
    this.cdRef.detectChanges();
    this.modalRequire = this.BSmodalService.show(this.Imgcontent, { class: 'modal-lg modal-dialog-scrollable mt-2' });
    this.imgdata = url
    //this.modalService.open(this.Imginput, { size: 'lg' });
  }
  public ImgList = Array<string>();
  SegmentMarkerClick(cluster, self, e) {
    var obj = self as this;

    obj.ImgList = cluster
    obj.cdRef.detectChanges();
    // obj.modalSegment = obj.BSmodalService.show(obj.SegmentImgcontent, { class: ' modal-dialog-scrollable mt-2' });

    L.popup({ maxHeight: 300 })
      .setLatLng(e.latlng)
      .setContent(document.getElementById('dvImgList').innerHTML)
      .openOn(obj.Pavementmap1);
    obj.cdRef.detectChanges();

    $('.img').click(function () {
      obj.imgdata = $(this.firstChild).attr('src');
      obj.cdRef.detectChanges();
      obj.imgPopup(obj.imgdata);
    });
    // obj.modalSegment = obj.BSmodalService.show(obj.SegmentImgcontent, { class: ' modal-dialog-scrollable mt-2' });
  }
  public HelpcontentDetails: string;
  public HelpTitle: string
  HelpClick(Type) {
    if (Type == "SegmentMap") {
      this.HelpTitle = "Segment Map"
      this.HelpcontentDetails = this.ToolTipContent.SegmentMap
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "RelativeLocationMap") {
      this.HelpTitle = "Relative Location Map"
      this.HelpcontentDetails = this.ToolTipContent.RelativeLocationMap
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "HeatMap") {
      this.HelpTitle = "Heat Map"
      this.HelpcontentDetails = this.ToolTipContent.HeatMap
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "SegmentSummary") {
      this.HelpTitle = "Segment Summary"
      this.HelpcontentDetails = this.ToolTipContent.SegmentSummary
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        class: 'modal-lg',
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "ObjectSummary") {
      this.HelpTitle = "Object Summary"
      this.HelpcontentDetails = this.ToolTipContent.ObjectSummary
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        class: 'modal-lg',
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "ConcentrationofObjects") {
      this.HelpTitle = "Concentration of Objects"
      this.HelpcontentDetails = this.ToolTipContent.ConcentrationofObjects
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "PavementDistress") {
      this.HelpTitle = "Pavement Distress Distribution by Severity"
      this.HelpcontentDetails = this.ToolTipContent.PavementDistress
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }
    else if (Type == "RideQuality") {
      this.HelpTitle = "Ride Quality by Severity"
      this.HelpcontentDetails = this.ToolTipContent.RideQuality
      this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: false,
        animated: true
      });
    }


  }


}
