import { UserSecurityQue } from './UserSecurityQue';

export class ApplicationUser {
    Id: string = '';
    UserName: string = '';
    Email: string = '';
    ConfirmEmail: string = '';
    RoleName: string = '';
    RoleId: string = '';
    CreatedByID: string = '';
    OrganizationID: number = 0;
    PasswordHash: string = '';
    FirstName: string = '';
    LastName: string = '';
    AssignedRegionsCount: number = 0;
    userSecurities = Array<UserSecurityQue>();
    SecurityQueID: string = "";
    Answer: string = "";
    CurrentLoginId: number = 0;
    CurrentVersion:string;
}

export class AccessUsers {
    Id: string = "";
    Name: string = "";
    Email: string = "";
    TopPermission: string = "";
    Status: string = "";
}