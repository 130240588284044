import { Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import * as L from 'node_modules/leaflet';
import 'leaflet.markercluster';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApplicationUser } from '../_models/ApplicationUser.js';
import { TriageRegion } from '../_models/TriageRegion.js';
import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
import { LatLng } from 'leaflet';
import { ClusterType } from '../_models/Clusters.js';
import * as  noUiSlider from 'nouislider/distribute/nouislider';
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import { DomainMapRange, DefaultValues } from '../_models/DomainMapRange';
import { Config } from '../_models/Config';
import 'leaflet-control-geocoder';

import { BsModalRef, BsModalService } from 'ngx-bootstrap';
// import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ChartOptions } from 'chart.js';
import { tbl_SegmentAssessment, SegmentPopupToolTip } from '../_models/tbl_SegmentAssessment';
import "../../assets/dist/leaflet.groupedlayercontrol.css";
import "../../assets/dist/leaflet.groupedlayercontrol.js";
import "../../assets/dist/exampledata.js";
import { tbl_AssessmentCluster } from '../_models/tbl_AssessmentCluster';

@Component({
  selector: 'app-lane-line-assessment',
  templateUrl: './lane-line-assessment.component.html',
  styleUrls: ['./lane-line-assessment.component.css']
})
export class LaneLineAssessmentComponent implements OnInit {
  @ViewChild('Imgcontent') Imgcontent: TemplateRef<any>;
  @ViewChild('content') content: TemplateRef<any>;

  public CurrentYear=(new Date()).getFullYear();
  public Month = 0 ;
  public Year = 0;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  DomainAdminRole = Config.AdminRoleId;
  public isRobocistAdmin = false;
  isAdmin = false;
  public User: ApplicationUser;
  public RegionID: number;
  public YearList = [];
  public MonthList = [];
  public MonthValue = 0;
  public BackMonth = 0;
  public MapTypeID: number;
  public DomainRange = new DomainMapRange();
  public Region = new TriageRegion();
  public slider: any;
  public sliderMonth: any;
  public DrawnFences = Array<GeoData>();

  public ImageCDN = API.ImageCDN;
  public height: string;
  public map: any;
  public isSet = false;
  public currentZoom = 15;
  public currentCenter = new LatLng(35.10193405724608, 84.69363542279648);
  public lat: number = 0;
  public long: number = 0;
  @ViewChild('content') input;
  modalRequire: BsModalRef;
  modalcontent: BsModalRef;
  public Segments = new Array<tbl_SegmentAssessment>();
  public SelectTab="ObjectSummary"
  public Type="" 
  public ToolTipContent= new SegmentPopupToolTip();
  public defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsMonth")) as DefaultValues;

  public constructor(private http: HttpClient, private _Activatedroute: ActivatedRoute,
    private _restservice: RestService, public cdRef: ChangeDetectorRef, private modalService: NgbModal, private BSmodalService: BsModalService,public router:Router) {
    this.height = window.innerHeight + "px";
    Chart.plugins.unregister(ChartDataLabels);
  }
 
  ngOnInit() {
    this.GetJsonToolTip()
    this._Activatedroute.paramMap.subscribe((params: ParamMap, ) => {
      this.MapTypeID = parseInt(params.get('MapTypeID'));
      this.Type = params.get('Type');
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    //this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    if (this.User.RoleId == this.RobocistAdminRole) {
      this.isRobocistAdmin = true;
    }
    if (this.User.RoleId == this.RobocistAdminRole || this.User.RoleId == this.DomainAdminRole) {
      this.isAdmin = true;
    }

    if(this.Type=="Q"){
      this.defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsQue")) as DefaultValues
    }

    this.ListYear();
    this.GetRegion();
    //this.GetYearMonth();
   
  }

  IsLoad: boolean = false;
  SetSessionDefaults() {
    if (this.IsLoad == true) {
      var defaults = new DefaultValues();
      defaults.Year = $('#YearID').val() as number
      if (this.Type == "Q") {
        defaults.Quarter = $('#MonthID').val() as number;
      } else {
        defaults.Month = $('#MonthID').val() as number;
      }
      defaults.Coverage = this.MonthValue;
      if(this.Type=="Q"){
        localStorage.removeItem("SetSessionMapsDefaultsQue")
        localStorage.setItem("SetSessionMapsDefaultsQue", JSON.stringify(defaults))
      }else{
        localStorage.removeItem("SetSessionMapsDefaultsMonth")
        localStorage.setItem("SetSessionMapsDefaultsMonth", JSON.stringify(defaults))
    }
    }
  }


  GetJsonToolTip(){
    this.http.get<SegmentPopupToolTip[]>("../../assets/JsonFile/SegmentPopupToolTip.json").subscribe(s=>{
    this.ToolTipContent=s.filter(s=>s.Name=="LaneLine")[0];
   
  });
    
  }
  ListYear() {
    this._restservice.Call<string[]>('GET', API.SegmentListOfYear + this.RegionID+'/'+this.Type, null)
      .subscribe(data => {
        this.YearList = data;

      });
  }
  SetDefault() {
    var domain = new DomainMapRange();
    domain.MapTypeID = this.MapTypeID;
    domain.RegionID = this.RegionID;
    domain.AssetTrendSize = parseInt($('#SizeID').val() as string);
    domain.MaximumCoverage = this.MonthValue;
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    if (Arr.length == 0)
      domain.Assessment = null;
    else
      domain.Assessment = Arr;

    domain.FairMin = $('#FairMin').text() as unknown as number;
    domain.FairMax = $('#FairMax').text() as unknown as number;
    this._restservice.Call<DomainMapRange>('POST', API.SetDefaultRange, domain)
      .subscribe(data => {
        this.DomainRange = data;
        AutoClosingAlert('success', 'Default Range set successfully.');
      })
  }
  GetDefaultRange() {
    this._restservice.Call<DomainMapRange>('GET', API.GetDefaultRange + this.MapTypeID + "/" + this.RegionID, null)
      .subscribe(data => {
        
        if (data == null) {
          this.DomainRange.MaximumCoverage = 1;
        } else {
          this.DomainRange = data;

          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
        }
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);

      
          var EndDate = new Date(this.Year, this.Month);
          this.monthDiff(StartDate, EndDate, 'S');
       
      this.IsLoad = true
      this.Map();
      });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
       
         this.GetYearMonth();
      })
  }
  monthDiff(d1, d2, Type) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    var minRange = 1;

   
    if (1 == mon) {
      minRange = 1; // minus 1 minute
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      if (this.DomainRange.MaximumCoverage == undefined) {
        this.DomainRange.MaximumCoverage = 0
      }
      var coverage=4;
      if(this.Type=="M"){
        coverage=12;
      }
      if (Type == "S")//Set
      {
        this.sliderMonth = document.getElementById("sliderMonth") as any;
        noUiSlider.create(this.sliderMonth, {
          start: this.DomainRange.MaximumCoverage,
          tooltips: [wNumb({ decimals: 0 })],
          range: {
            'min': 1,
            'max': coverage
          },
        });
        var self = this;
        self.sliderMonth.noUiSlider.on('update', function (values, handle) {
          self.MonthValue = Math.round(values[handle]);
          if (self.MonthValue != self.DomainRange.MaximumCoverage) {
            self.SetSessionDefaults()
          }
        });
      }
      else { //reset
        this.sliderMonth.noUiSlider.updateOptions({
          start: [this.DomainRange.MaximumCoverage]
        });
      }

      if (this.defaultsSetting !=null &&this.defaultsSetting.Coverage != null) {
        if (this.sliderMonth != undefined) {
          this.sliderMonth.noUiSlider.updateOptions({
            start: [this.defaultsSetting.Coverage]
          });
        }
      }
    }
 
  }

  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (isDefault) {
        this.GetDefaultRange();
      }else {
        this.SetSessionDefaults()
      }
    }
    else {
      var SelectedYear=Year;
      if(this.YearList.filter(s=>s==Year).length==0){
        SelectedYear=this.LastYear
      }
      this._restservice.Call<string[]>('GET', API.SegmentListOfMonth + SelectedYear + "/" + this.RegionID+"/"+this.Type, null)
        .subscribe(data => {
          if (data != null) {
          this.MonthList = data;
          this.cdRef.detectChanges();
          if (isDefault) {
           
            if (this.YearList.filter(s => s == Year).length > 0 &&  this.MonthList.filter(s => s.ID == Month).length > 0) {
              $('#YearID').val(Year);
              $('#MonthID').val(Month);
              this.Month = Month;
              this.Year = Year;
            }
            else {
                $('#YearID').val(this.LastYear);
                $('#MonthID').val(this.LastMonth);
                this.Month = this.LastMonth;
                this.Year = this.LastYear;
            }
          }
          if (isDefault) {
            this.GetDefaultRange();
          }else {
            this.SetSessionDefaults()
          }
        }

        });
    }
  }

  LastYear: any;
  LastMonth: any;
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.SegmentGetYearMonth + this.RegionID +"/"+this.Type+ "", null)
      .subscribe(data => {
        this.LastYear = data.Year;
        this.LastMonth = data.MonthID;
        if(this.defaultsSetting!=null){
          if (this.Type == "Q") {
            this.FillYearMonth(this.defaultsSetting.Year,this.defaultsSetting.Quarter, true);
          }else{
            this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Month, true);
          }
        }else{
          this.FillYearMonth(data.Year, data.MonthID, true);
        }
      })
  }
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;

    if(this.Type=="Q"){
      if(Month==1){
        Month=1
      }else if(Month==2){
        Month=4
      }
      else if(Month==3){
        Month=7
      }else{
        Month=10
      }
    }
    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);
    this.SetSessionDefaults()
    this.monthDiff(StartDate, EndDate, 'S');
  }

  Map() {

    var self = this;
    //this.RetriveGeoFence();
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    let RoadArr = [];
    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    // if (Arr.length == 0) {
    //   $('.MapAssessmentList:checkbox').each(function () {
    //     Arr.push($(this).val())
    //   });
    // }
    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).attr('name'));
    });

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).attr('name'));
    })
    document.getElementById('weathermap').innerHTML = "<div id='map' style='width:1300px'></div>";
    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;

    }
   
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
  
   
    var arrymonth= $('#MonthID').val() as string;
    var sendMonths=parseInt(arrymonth);
    var converageMonth=this.BackMonth;
  
   if(this.Type=="Q"){
    isPrevious=true
    if(parseInt(arrymonth)==1){
      sendMonths=3
    }else if(parseInt(arrymonth)==2){
      sendMonths=6
    }
    else if(parseInt(arrymonth)==3){
      sendMonths=9
    }else{
      sendMonths=12
    }
    if(this.BackMonth<=0){
      converageMonth=3
    }else{
       converageMonth=this.BackMonth*3
    }
   }
   
    this._restservice.Call<tbl_SegmentAssessment[]>('GET', API.LaneLineList + this.RegionID+"?MonthIDs="+sendMonths +"&Type="+this.Type + "&Year=" + $('#YearID').val() +
      "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1), null)
      .subscribe(data => {
        this.Segments = data;
        
        var markerList = [];
        if (data.length > 0 && this.map == undefined && data!=null) {
          if(this.Segments[0].points.length>0){
          this.Segments[0].points[0] = this.Segments[0].points[0].replace('(', '')
          this.Segments[0].points[0] = this.Segments[0].points[0].replace(')', '')
          this.lat = parseFloat(this.Segments[0].points[0].split(",")[0]);
          this.long = parseFloat(this.Segments[0].points[0].split(",")[1]);
        }
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }

        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

        var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; '+this.CurrentYear+' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; '+this.CurrentYear+' Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });
         
           // SearchResult

           var geocoder = L.Control.geocoder({
            defaultMarkGeocode: false,
            position: "topleft",
          })
            .on('markgeocode', function(e) {
              // var latlng = e.geocode.center;
              // var marker = L.marker(latlng).addTo(self.map);
              //  self.map.fitBounds(e.geocode.bbox);
              var latlng = e.geocode.center;
              if(e.target._lastGeocode.split(',').length<2){
                var marker = L.marker(latlng).addTo(self.map);
              }else{
                var marker = L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]).addTo(self.map);
              }
               self.map.fitBounds(e.geocode.bbox);
              
            })
            .addTo(self.map);



        // var layerresults = new L.LayerGroup().addTo(this.map);
        //   var geocoder = L.Control.geocoder({
        //     defaultMarkGeocode: false,
        //     position:"topleft"
        //   })
        //   .on('markgeocode', function (e) {
        //     console.log(e)
        //     layerresults.clearLayers();
        //     console.log(parseFloat(e.target._lastGeocode.split(',')[0]) + ',' + parseFloat(e.target._lastGeocode.split(',')[1]))
        //     layerresults.addLayer(L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]));
        //     var latlong = [
        //       [parseFloat(e.target._lastGeocode.split(',')[0]),parseFloat(e.target._lastGeocode.split(',')[1])]
             
        //     ];
        //     self.map.fitBounds([latlong]);
        //   })
        //   .addTo(self.map);

       
          var baseMaps1 = {
            "Street": osm,
            "Satellite": mqi
          };
        
         
          // var searchControl = esriGeoCoder.geosearch({
          //   collapseAfterResult: true,
          //   useMapBounds: false,
          //   zoomToResult: false,
          //   providers: [ esriGeoCoder.arcgisOnlineProvider() ]
          // }).addTo(this.map);
          // var results = new L.LayerGroup().addTo(this.map);
          // searchControl.on('results', function(data){
          //    results.clearLayers();
          //   for (var i = data.results.length - 1; i >= 0; i--) {
          //     results.addLayer(L.marker(data.results[i].latlng));
          //   }
          // });

        // var polylines = new L.FeatureGroup();
        // this.map.addTo(polylines);
        var len = data.length;
       
        

        for (var i = 0; i < len; i++) {
          // this.Segments.forEach(e => {
          var e = new tbl_SegmentAssessment();
          e = data[i];
          var point = new Array<LatLng>();

          if (e.points.length != 0) {
            e.points.forEach(p => {
              p = p.replace('(', '')
              p = p.replace(')', '')
              var lat = parseFloat(p.split(",")[0]);
              var long = parseFloat(p.split(",")[1]);

              point.push(new L.LatLng(lat, long));
            });
            var polylinePoints = [
              point
              //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
            ];
            //overallrating
            var iconColor = "#000000";
            if (e.LaneOverallRating == "Good") {
              iconColor = "#276A27";
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind(null, e,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();
            } else if (e.LaneOverallRating == "Fair") {
              iconColor = "#FFFF00";
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind(null, e,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();
            } else if (e.LaneOverallRating == "Poor") {
              iconColor = "#FE9903";
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind(null, e ,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();
            } else if (e.LaneOverallRating == "Bad") {
              iconColor = "#cc0000";
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind( null,e,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();

            }else if (e.LaneOverallRating == "None" && e.Laneline_cd.Bad==0 && e.Laneline_cd.Fair==0 && e.Laneline_cd.Good==0 && e.Laneline_cd.Poor==0 && e.Laneline_cd.None==0) {
              iconColor = "#20B2AA";
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind(null,e ,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();

            }else {
              if(this.isAdmin==true){
                iconColor = "#000000";
              }else{
                iconColor = "#cc0000";
              }
              var CombinedfeatureGroup = L.featureGroup().addTo(this.map);
              var firstpolyline = new L.polyline(polylinePoints, {
                color: iconColor,
                weight: 4,
                opacity: 1,
                smoothFactor: 1
              }).on('click', this.MarkerClick.bind(null,e ,this));
              firstpolyline.addTo(CombinedfeatureGroup);
              CombinedfeatureGroup.bringToBack();
            }
          }

        }
        var groupedOverlays = {
         
        };
       
        var options = {
          position: 'topleft'
        };
    
         var layerControl = L.control.groupedLayers(baseMaps1, groupedOverlays, options);
         
        this.map.addControl(layerControl);
        // var polylinePoints = [
        //   [36.62963557931078, 81.77945353923658],
        //   [35.259973681064736, 84.54873919102778],
        //   [35.10193405724608, 84.69363542279648],
        //   [36.59347887826919, 75.57496712546072]
        // ];



        // var pointA = new L.LatLng(28.635308, 77.22496);
        // var pointB = new L.LatLng(28.984461, 77.70641);
        // var pointList = [pointA, pointB];
        this.cdRef.detectChanges();
        this.RetriveGeoFence();
      });
    var markers = L.markerClusterGroup({ chunkedLoading: true });

  }

  exportToPdf(){
    sessionStorage.removeItem('segment')
    this.modalcontent.hide()
    // this.popupsegment.sliderMonth=this.sliderMonth;
    //this.popupsegment.BackMonth=this.BackMonth;
    sessionStorage.setItem('segment',JSON.stringify(this.popupsegment))
    // this.router.navigate(['/PDFLineSegment',this.popupsegment.wayid,this.popupsegment.segmentid,this.Type]).then(result => {  
      window.open('/#/PDFLaneLine/'+this.popupsegment.wayid+'/'+this.popupsegment.segmentid+'/'+this.Type, '');
    // });
    
    }

  RetriveGeoFence() {

    var featureGroup = L.featureGroup().addTo(this.map);
    this.DrawnFences = Array<GeoData>();
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
       

        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();

      })

  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
}
  popupsegment = new tbl_SegmentAssessment();
  chkBoundingBox = false;
  Pavementroi = "pavement-with-roi"
  TypeOfHeatMap = "Pavement Distress Hotspots";
  // imu = "imu"
  MarkerClick(segment: tbl_SegmentAssessment,self, e ) {

    var obj = self as this;
    obj.http.get<tbl_SegmentAssessment>(API.GetSegment + segment.assessmentsegmentid + "?Type=" + obj.Type)
    .subscribe(data => {
      segment = data;
 
    if (self.chkBoundingBox == true) {
      self.Pavementroi = "segment-summary"
    } else {
      self.Pavementroi = "segment-summary"
    }

    obj.http.get<any>( API.LaneLinePopup + segment.assessmentsegmentid + "?Type="+obj.Type+ "&WayID=" + segment.wayid)
      .subscribe(data2 => {
        obj.popupsegment = data2;
        obj.popupsegment.StartDate=segment.StartDate;
      
        
        var length = (obj.popupsegment.points.length / 2).toFixed(0)
        obj.popupsegment.middlepoint = obj.popupsegment.points[length]
        obj.popupsegment.middlepoint = obj.popupsegment.middlepoint.replace('(', '')
        obj.popupsegment.middlepoint = obj.popupsegment.middlepoint.replace(')', '')
        obj.popupsegment.lat = parseFloat(obj.popupsegment.middlepoint.split(',')[0]).toFixed(7)
        obj.popupsegment.long = parseFloat(obj.popupsegment.middlepoint.split(',')[1]).toFixed(7)

        segment = data2
        obj.cdRef.detectChanges();
        obj.modalcontent = obj.BSmodalService.show(obj.content, { class: 'modal-xl modal-dialog-scrollable mt-2' });
        // this.modalService.open(this.input, {windowClass: 'modal-xxl' });
        obj.OtherMap()

        });

      }); 
  }

  OtherMap(){
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }

    // this._restservice.Call<tbl_SegmentAssessment[]>('GET', API.SegmentCluster + this.RegionID + "?SegmentID="+ this.popupsegment.segmentid + "&WayID=" + this.popupsegment.wayid + "&StartDate=" + this.popupsegment.StartDate +"&MonthID=" + $('#MonthID').val() + "&Year=" + $('#YearID').val() +
    // "&IsPrevious=" + isPrevious + "&BackMonth=" + (this.BackMonth - 1), null)
    // .subscribe(data => {

    document.getElementById('map1').innerHTML = "<div id='Pavementmap1' style='width:335px;height:335px'></div>";
    var Pavementmap1=L.map('Pavementmap1', {scrollWheelZoom: 'center',
      center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13});
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; '+this.CurrentYear+' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
      maxZoom: 19,
      minZoom:13
    }).addTo(Pavementmap1)

    var point = new Array<LatLng>();
    this.popupsegment.points.forEach(p => {
      p = p.replace('(', '')
      p = p.replace(')', '')
      var lat = parseFloat(p.split(",")[0]);
      var long = parseFloat(p.split(",")[1]);

      point.push(new L.LatLng(lat, long));
    });
    var polylinePoints = [
      point
      //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
    ];
   

    var ZoomLevel2=Pavementmap1.getBoundsZoom([polylinePoints])
    document.getElementById('map2').innerHTML = "<div id='Pavementmap2' style='width:335px;height:335px'></div>";
    var Pavementmap2=L.map('Pavementmap2', {  
      scrollWheelZoom: 'center',
      center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom:  ZoomLevel2-4});
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; '+this.CurrentYear+' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
      maxZoom: 19
     
    }).addTo(Pavementmap2)
 // })


      var firstpolyline1 = new L.polyline(polylinePoints, {
        color: "#3333ff",
        weight: 4,
        opacity: 1,
        smoothFactor: 1
      })
      Pavementmap1.fitBounds([polylinePoints])
      firstpolyline1.addTo(Pavementmap1);
      var firstpolyline = new L.polyline(polylinePoints, {
        color: "#3333ff",
        weight: 4,
        opacity: 1,
        smoothFactor: 1
      })
     
      firstpolyline.addTo(Pavementmap2);
    L.marker([this.popupsegment.lat, this.popupsegment.long]).addTo(Pavementmap2);
   
      document.getElementById('map3').innerHTML = "<div id='Pavementmap3' style='width:335px;height:335px'></div>";
      var Pavementmap3 = L.map('Pavementmap3', {
        scrollWheelZoom: 'center',
        center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13
      });
      L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
      }).addTo(Pavementmap3)
      var firstpolyline3 = new L.polyline(polylinePoints, {
        color: "#3333ff",
        weight: 4,
        opacity: 1,
        smoothFactor: 1
      })
      firstpolyline3.addTo(Pavementmap3);
      Pavementmap3.fitBounds([polylinePoints])
      this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    var arrymonth = $('#MonthID').val() as string;
    var sendMonths = parseInt(arrymonth);
    var converageMonth = this.BackMonth;
    if (this.Type == "Q") {
      isPrevious = true
      if (parseInt(arrymonth) == 1) {
        sendMonths = 3
      } else if (parseInt(arrymonth) == 2) {
        sendMonths = 6
      }
      else if (parseInt(arrymonth) == 3) {
        sendMonths = 9
      } else {
        sendMonths = 12
      }
      if (this.BackMonth <= 0) {
        converageMonth = 3
      } else {
        converageMonth = this.BackMonth * 3
      }
    }
    this.popupsegment.BackMonth = this.MonthValue;

    this.popupsegment.Year = $('#YearID').val() as number;
    this.popupsegment.sendMonths = sendMonths
    this._restservice.Call<tbl_AssessmentCluster[]>('GET', API.SegmentPopupHeatmap + this.popupsegment.segmentid + "/" + this.Type + "?MonthIDs=" + sendMonths + "&Year=" + $('#YearID').val() +
      "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1) + "&clusertype=" + this.Type + "&BoundingBox=" + this.chkBoundingBox, null)
      .subscribe(data => {
        var len = data.length;
        Pavementmap3.fitBounds([polylinePoints])

        var PavementfeatureGroup = new L.featureGroup();
        var RidefeatureGroup = new L.featureGroup()
        var CombinedfeatureGroup = new L.featureGroup();
      
        var Pavementfeaturepoint = new Array<InsideMapPoint>();
        var Ridefeaturepoint = new Array<InsideMapPoint>();
        var Combinedfeaturepoint = new Array<InsideMapPoint>();

        PavementfeatureGroup.addTo(Pavementmap3)

        for (var i = 0; i < len; i++) {

          var se = new tbl_AssessmentCluster();
          se = data[i];

          var point = new InsideMapPoint();
          var item = data[i];
          point.lat = item.Lat;
          point.lng = item.Long;
          if (item.MinAssessment > 6) {
            point.value = 0.3
          }
          else if (item.MinAssessment >= 4 && item.MinAssessment <= 6) {
            point.value = 0.6
          }
          else if (item.MinAssessment >= 0 && item.MinAssessment < 4) {
            point.value = 1
          }
          //PavementfeatureGroup
          if (data[i].ClusterType.filter(s => s == "Pavement Condition").length > 0) {

            if (data[i].subclustertype.filter(s => s == "Potholes" || s == "Longitudinal Cracks Unsealed" || s == "Fatigue Cracks Unsealed" || s == "Lateral Cracks Unsealed").length > 0) {
              Pavementfeaturepoint.push(point);
              //Combined
              Combinedfeaturepoint.push(point);
            }

          }
          //RidefeatureGroup
          if (data[i].ClusterType.filter(s => s == "Ride Quality").length > 0) {
            if (data[i].subclustertype.filter(s => s == "Dips/Lifts" || s == "Roughness" || s == "Bumps").length > 0) {
              Ridefeaturepoint.push(point);
              //Combined
              Combinedfeaturepoint.push(point);
            
            }
          }
        }
        var firstpolyline = new L.heatLayer(Pavementfeaturepoint, {
          maxZoom: 13
        })
        // gradient: {
        //   0.3: '#00b050',
        //   0.6: '#ffd966',
        //   0.9: '#ff0000'
        // }
        firstpolyline.addTo(PavementfeatureGroup);
        PavementfeatureGroup.bringToBack();

        var firstpolyline1 = new L.heatLayer(Ridefeaturepoint, {
          maxZoom: 13
        })
        firstpolyline1.addTo(RidefeatureGroup);
        RidefeatureGroup.bringToBack();
        
        var firstpolyline2 = new L.heatLayer(Combinedfeaturepoint, {
          maxZoom: 13
        })
        firstpolyline2.addTo(CombinedfeatureGroup);
        CombinedfeatureGroup.bringToBack();

        var groupedOverlays = {
          "Assessment Options": {
            "Pavement Distress": PavementfeatureGroup,
            "Ride Quality": RidefeatureGroup,
            "Combined": CombinedfeatureGroup
          }
        };
        var options = {
          // Make the "Landmarks" group exclusive (use radio inputs)
          exclusiveGroups: ["Assessment Options"],
          // Show a checkbox next to non-exclusive group labels for toggling all
          groupCheckboxes: true,
          position: 'topright'
        };
        var layerControlheatmap = L.control.groupedLayers(null, groupedOverlays, options)
        Pavementmap3.addControl(layerControlheatmap);
      });
  }

  imgdata: string;
  imgPopup(url: string) {
    this.cdRef.detectChanges();
    this.modalRequire = this.BSmodalService.show(this.Imgcontent, { class: 'modal-lg modal-dialog-scrollable mt-2' });
    this.imgdata = url
    //this.modalService.open(this.Imginput, { size: 'lg' });
  }
  @ViewChild('Helpcontent') Helpcontent: TemplateRef<any>;
  modalHelp: BsModalRef;
  public HelpcontentDetails: string;
  public HelpTitle: string
  HelpClick(Type) {
    if (Type == "SegmentMap") {
      this.HelpTitle="Segment Map"
      this.HelpcontentDetails = this.ToolTipContent.SegmentMap
      
    this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      animated: true
    });
    } 
    else if (Type == "RelativeLocationMap") {
      this.HelpTitle="Relative Location Map"
      this.HelpcontentDetails = this.ToolTipContent.RelativeLocationMap
      
    this.modalHelp = this.BSmodalService.show(this.Helpcontent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      animated: true
    });
    }
    else if (Type == "SegmentSummary") {
      this.HelpTitle="Segment Summary"
      this.HelpcontentDetails = this.ToolTipContent.SegmentSummary
      
    this.modalHelp = this.BSmodalService.show(this.Helpcontent, { class:'modal-lg',
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      animated: true
    });
    }
   

  }
}
