import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Client } from '../_models/Client';
import { Subject } from 'rxjs';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { TriageRegion } from '../_models/triageRegion';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainMapRange } from '../_models/DomainMapRange';
import * as  noUiSlider from 'nouislider/distribute/nouislider';
import wNumb from 'src/assets/scripts/js/Custom/wNumb';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit {

  public popoverTitle: string = 'Confirmation';
  public popoverMessage: string = 'Are you sure?';
  public IsTiggered = false;
  dtTrigger: Subject<any> = new Subject();
  clientListWithDomain = Array<Client>();
  clients
  isLoaded = false;
  editDomain = new TriageRegion();
  Status = 1;
  DomainRanges = new Array<DomainMapRange>();
  slider_1: any;
  slider_2: any;
  slider_3: any;
  EndMonthinvalid=false;
  ValidateReleasedate=false;
  constructor(private _restservice: RestService, private _router: Router, private modalService: NgbModal, private cdRef: ChangeDetectorRef) {

  }
  dtOptions: any = {};
  public IsRobocistAdmin = false;
  ID: number;
  @ViewChild('content') input;

  ngOnInit() {
    this.dtOptions = {
      responsive: true,
      searching: true,
      info: true,
      lengthChange: true,
      ordering: true,
      destroy: true,
    };

    this.GetClientsWithDomain();

  }
  GetClientsWithDomain() {
    this._restservice.Call<Client[]>('GET', API.GetClientListWithDomain + "?Active=" + this.Status, null)
      .subscribe(data => {
        this.clientListWithDomain = data;

        this.clientListWithDomain.forEach(client => {
          
            if (client.toggle == undefined || client.toggle == "") {
              client.toggle = ""
              client.Col = "collapsed";
            }
        });
        this.isLoaded = true;
      })
  }
  deleteClientData(id) {
    if (id != 0) {
      this._restservice.Call<Client>('DELETE', API.DeleteCilentList + id, null)
        .subscribe(data => {
          this.GetClientsWithDomain();
          AutoClosingAlert('success', 'Client deleted successfully.');
        })
    }
  }

  GetDomain(DomainID) {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + DomainID, null)
      .subscribe(data => {
        this.GetClientList();
        this.editDomain = data;
        
        //this.GetMapDefault(data.ClientID);

        if (this.editDomain.StartDate == null) {
          this.editDomain.StartMonth = new Date().getMonth() + 1;
          this.editDomain.StartYear = new Date().getFullYear();
        }
        if (this.editDomain.EndDate == null) {
          this.editDomain.EndMonth = 0;
          this.editDomain.EndYear = 0;
        }
        if (this.editDomain.ReleaseDate == null) {
          this.editDomain.ReleaseMonth = 0;
          this.editDomain.ReleaseYear = 0;
        }
      })
  }
  GetClientList() {
    this._restservice.Call<Client[]>('GET', API.ClientList + "?Active=" + this.Status, null)
      .subscribe(data => {
        this.clients = data;
        this.modalService.open(this.input);
        this.cdRef.detectChanges();
      })
  }

  Submit() {
    var StartDate = new Date(this.editDomain.StartYear, this.editDomain.StartMonth - 1);
    // var EndDate = new Date();
    // var ReleaseDate = new Date();
    // if (this.editDomain.ReleaseMonth != 0 && this.editDomain.ReleaseYear != 0) {
    //   ReleaseDate = new Date(this.editDomain.ReleaseYear, this.editDomain.ReleaseMonth - 1);
    // }

    // if (this.editDomain.EndMonth != 0 && this.editDomain.EndYear != 0) {
    //   EndDate = new Date(this.editDomain.EndYear, this.editDomain.EndMonth - 1);
    //   if (EndDate < StartDate) {
    //     AutoClosingAlert('danger', 'End date should be greater than start date.');
    //   }
    //   else if (this.editDomain.ReleaseMonth != 0 && this.editDomain.ReleaseYear != 0) {

    //     if (!(ReleaseDate >= StartDate && ReleaseDate <= EndDate)) {
    //       AutoClosingAlert('danger', 'Release date should be greater than start date and less than end date.');

    //     }
    //     else {
    //       this.UpdateDomain();
    //     }
    //   }
    //   else if (this.editDomain.ReleaseMonth != 0 || this.editDomain.ReleaseYear != 0) {
    //     if (this.editDomain.ReleaseMonth == 0 && this.editDomain.ReleaseYear != 0) {
    //       AutoClosingAlert('danger', 'Please select release month.');
    //     }
    //     else {
    //       AutoClosingAlert('danger', 'Please select release year.');
    //     }
    //   }
    //   else {
    //     this.UpdateDomain();
    //   }
    // }
    // else if (this.editDomain.EndMonth != 0 || this.editDomain.EndYear != 0) {
    //   if (this.editDomain.EndMonth == 0 && this.editDomain.EndYear != 0) {
    //     AutoClosingAlert('danger', 'Please select end month.');
    //   }
    //   else {
    //     AutoClosingAlert('danger', 'Please select end year.');
    //   }
   // }

    // else if (this.editDomain.ReleaseMonth != 0 && this.editDomain.ReleaseYear != 0) {
    //   if (ReleaseDate < StartDate) {
    //     AutoClosingAlert('danger', 'Release date should be greater than start date.');

    //   }
    //   else {
    //     this.UpdateDomain();
    //   }
    // }
    // else if (this.editDomain.ReleaseMonth != 0 || this.editDomain.ReleaseYear != 0) {
    //   if (this.editDomain.ReleaseMonth == 0 && this.editDomain.ReleaseYear != 0) {
    //     AutoClosingAlert('danger', 'Please select release month.');
    //   }
    //   else {
    //     AutoClosingAlert('danger', 'Please select release year.');
    //   }
    // }
    // else {
    //   this.UpdateDomain();
    // }
    if(this.editDomain.EndMonth==0 || this.editDomain.EndYear==0){
      this.EndMonthinvalid=true;
    }
    else{
      this.EndMonthinvalid=false
    }
    if(this.editDomain.ReleaseMonth!=0 && this.editDomain.ReleaseYear!=0){
      if(this.editDomain.ReleaseYear<this.editDomain.StartYear || this.editDomain.ReleaseYear>this.editDomain.EndYear)
      {
        this.ValidateReleasedate=true;
        
      }else if(this.editDomain.ReleaseYear==this.editDomain.StartYear || this.editDomain.ReleaseYear==this.editDomain.EndYear){
          if(this.editDomain.ReleaseYear==this.editDomain.StartYear && this.editDomain.ReleaseMonth<this.editDomain.StartMonth){
            this.ValidateReleasedate=true;
          }else if( this.editDomain.ReleaseYear==this.editDomain.EndYear && this.editDomain.ReleaseMonth>this.editDomain.EndMonth){
            this.ValidateReleasedate=true;
          }else{
            this.ValidateReleasedate=false;
          }
      }
      else{
        this.ValidateReleasedate=false;
      }
    }else{ this.ValidateReleasedate=false;}
    console.log("End date "+this.EndMonthinvalid)
    console.log(this.editDomain.ReleaseYear)
    console.log( this.editDomain.ReleaseMonth)
    if(this.EndMonthinvalid==false && this.editDomain.EndMonth>=this.editDomain.StartMonth 
      && this.editDomain.EndYear>=this.editDomain.StartYear && this.ValidateReleasedate==false){
        this.UpdateDomain()
      }
  }

  UpdateDomain() {
    this.editDomain.StartDate = new Date(this.editDomain.StartYear, this.editDomain.StartMonth - 1).toDateString();
    if (this.editDomain.ReleaseMonth != 0 && this.editDomain.ReleaseYear != 0) {
      this.editDomain.ReleaseDate=new Date(this.editDomain.ReleaseYear, this.editDomain.ReleaseMonth-1).toDateString();
      // var rdate = new Date(this.editDomain.ReleaseYear, this.editDomain.ReleaseMonth, 0).getDate();
      // this.editDomain.ReleaseDate = new Date(this.editDomain.ReleaseYear, this.editDomain.ReleaseMonth - 1, rdate).toDateString();
    }
    else {
      this.editDomain.ReleaseDate = null;
    }
    if (this.editDomain.EndMonth != 0 && this.editDomain.EndYear != 0) {
      this.editDomain.EndDate=new Date(this.editDomain.EndYear, this.editDomain.EndMonth-1).toDateString();
      // var date = new Date(this.editDomain.EndYear, this.editDomain.EndMonth, 0).getDate();
      // this.editDomain.EndDate = new Date(this.editDomain.EndYear, this.editDomain.EndMonth - 1, date).toDateString();
    }
    else {
      this.editDomain.EndDate = null;
    }
    this._restservice.Call<Client>('PUT', API.UpdateClientInDomain, this.editDomain)
      .subscribe(data => {
        this.modalService.dismissAll();
        AutoClosingAlert('success', 'Domain updated successfully.');
        this.GetClientsWithDomain();
      })
  }
  ActivateClient(ClientID) {
    this._restservice.Call<Client>('POST', API.ReactivateClient + ClientID, null)
      .subscribe(data => {
        this.GetClientsWithDomain();
      })
  }

  GetMapDefault(ClientID) {
    this._restservice.Call<DomainMapRange[]>('GET', API.GetMapDefault + this.editDomain.ID + "/" + ClientID, null)
      .subscribe(data => {
        this.DomainRanges = data;


        this.DomainRanges.map(result => {
          this.InitSlider(result);
        })
      })
  }

  InitSlider(DomainRange: DomainMapRange) {
    if (DomainRange.MapTypeID == 1) {
      this.slider_1 = document.getElementById('slider_' + DomainRange.MapTypeID) as any;
      noUiSlider.create(this.slider_1, {
        start: [DomainRange.FairMin, DomainRange.FairMax],
        padding: [1, 1],
        connect: true,
        tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
        range: {
          'min': 0,
          'max': 10
        }
      });

      var FairMin = document.getElementById('FairMin_' + DomainRange.MapTypeID);
      var FairMax = document.getElementById('FairMax_' + DomainRange.MapTypeID);
      this.slider_1.noUiSlider.on('update', function (values, handle) {
        if (handle) {
          DomainRange.FairMin = Math.round(values[handle]);
        } else {
          DomainRange.FairMax = Math.round(values[handle]);
        }
      });
    }
    else if (DomainRange.MapTypeID == 2) {
      this.slider_2 = document.getElementById('slider_' + DomainRange.MapTypeID) as any;
      noUiSlider.create(this.slider_2, {
        start: [DomainRange.FairMin, DomainRange.FairMax],
        padding: [1, 1],
        connect: true,
        tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
        range: {
          'min': 0,
          'max': 10
        }
      });

      var FairMin = document.getElementById('FairMin_' + DomainRange.MapTypeID);
      var FairMax = document.getElementById('FairMax_' + DomainRange.MapTypeID);
      this.slider_2.noUiSlider.on('update', function (values, handle) {
        if (handle) {
          DomainRange.FairMin = Math.round(values[handle]);
        } else {
          DomainRange.FairMax = Math.round(values[handle]);
        }
      });
    }
    else if (DomainRange.MapTypeID == 3) {
      this.slider_3 = document.getElementById('slider_' + DomainRange.MapTypeID) as any;
      noUiSlider.create(this.slider_3, {
        start: [DomainRange.FairMin, DomainRange.FairMax],
        padding: [1, 1],
        connect: true,
        tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
        range: {
          'min': 0,
          'max': 10
        }
      });

      var FairMin = document.getElementById('FairMin_' + DomainRange.MapTypeID);
      var FairMax = document.getElementById('FairMax_' + DomainRange.MapTypeID);
      this.slider_3.noUiSlider.on('update', function (values, handle) {
        if (handle) {
          DomainRange.FairMin = Math.round(values[handle]);
        } else {
          DomainRange.FairMax = Math.round(values[handle]);
        }
      });
    }
  }
  
  CollapseAction(id, C: Client) {
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    if(this.clients !=undefined){
    this.clients.forEach(cli => {
      if (cli.ID == C.ID) {
        cli = C;
      }
    });
  }
  }
}


