import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoData, GeoPoint, GeoPointInside } from '../_models/GeoData';
import { LatLng } from 'leaflet';
import * as L from 'leaflet';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TriageRegion, servicestatus } from '../_models/TriageRegion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import circleToPolygon from 'circle-to-polygon';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { Subject, EMPTY } from 'rxjs';
import { switchAll, catchError, tap } from 'rxjs/operators';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-edit-fence',
  templateUrl: './edit-fence.component.html',
  styleUrls: ['./edit-fence.component.css']
})
export class EditFenceComponent implements OnInit {
  public DisButton = false;

  public srcTiles: string;
  public height: string;
  public Fences = Array<GeoData>();
  public DrawnFences = Array<GeoData>();
  public UpdatedDrawnFences = Array<GeoData>();
  public RegionID: number = 0;
  public map: any;
  private featureGroup: any;
  @ViewChild('content') input;
  private triageRegion = new TriageRegion();
  ParentID: number;
  public parentRegionFences = Array<GeoData>();
  Level: number = 0;
  SubRegionFences = Array<GeoData>();
  
  private updateSubscription: Subscription;
  public constructor(private restservice: RestService, private _route: ActivatedRoute, private modalService: NgbModal, private router: Router) {
    this.height = window.innerHeight + "px";
  }
  ngOnInit() {
    this.srcTiles = 'https://tile.osm.org/{z}/{x}/{y}.png'

    this._route.paramMap.subscribe((params: ParamMap) => {
      this.RegionID = parseInt(params.get('ID'));
    });
  }
  ngAfterViewInit() {

    this.map = L.map("map", {
      center: [39.381266, -97.922211],
      zoom: 13,
      zoomControl: true
    });

    var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19
    }).addTo(this.map),
      mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        attribution: '&copy; Robocist Inc.',
        maxZoom: 19,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });

    var baseMaps = {
      "Street": osm,
      "Satellite": mqi
    };

    var overlays = {//add any overlays here
    };

    L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);
    $(".leaflet-draw-edit-remove").attr('title', 'Delete');
    $(".leaflet-draw-edit-edit").attr('title', 'Edit');
    console.log($(".leaflet-draw-edit-remove").attr('title'))
    this.featureGroup = L.featureGroup().addTo(this.map);
    var drawControl = new L.Control.Draw({
      edit: {
        featureGroup: this.featureGroup
      }
    }).addTo(this.map);

  
    // L.EditToolbar.prototype.removeToolbar();
    var searchControl = esriGeoCoder.geosearch().addTo(this.map);
    var results = L.layerGroup().addTo(this.map);
    searchControl.on('results', function (data) {
      results.clearLayers();
      for (var i = data.results.length - 1; i >= 0; i--) {
        results.addLayer(L.marker(data.results[i].latlng));
      }
    });

    var self = this;

    this.map.on('draw:created', function (e) {
      var latlngs: any;
      if (e.layer instanceof L.Circle) {
        var latlng = e.layer.getLatLng();
        var radiusrecent = e.layer.getRadius();
        var Circlelatlng = L.latLng(latlng.lat, latlng.lng);
        var bounds = Circlelatlng.toBounds(radiusrecent * 2);
        var North = new LatLng(bounds.getNorth(), latlng.lng);
        var South = new LatLng(bounds.getSouth(), latlng.lng);
        var East = new LatLng(latlng.lat, bounds.getEast());
        var West = new LatLng(latlng.lat, bounds.getWest());


        const coordinates = [latlng.lng, latlng.lat];
        const radius = radiusrecent;
        const numberOfEdges = 64;

        let polygon = circleToPolygon(coordinates, radius, numberOfEdges);
        let a = (polygon.coordinates as unknown as L.LatLngExpression[])[0] as unknown as GeoPoint[];
        var geodata = Array<GeoPoint>();
        a.map(value => {
          var points = new GeoPoint();
          points.lat = value[1];
          points.lng = value[0];
          geodata.push(points);

        });
        latlngs = geodata;

      }
      else {
        latlngs = (e.layer.getLatLngs())[0];
      }
      var inside = false;
      var geopoints = Array<GeoPointInside>();
      latlngs.map(value => {
        var data = new GeoPointInside;
        data.lat = value.lat;
        data.lng = value.lng;

        if (data.inside == false) {
          data.inside = self.isMarkerInsideRegion(data.lat, data.lng);
          geopoints.push(data);
        }
      })
      if (geopoints.filter(x => x.inside == false).length > 0) {
        if (self.Level == 2) {
          AutoClosingAlert('danger', 'Region must be inside the domain.');
        }
        else if (self.Level == 3) {
          AutoClosingAlert('danger', 'Sub-region must be inside the region.');
        }
      }
      else {
        self.featureGroup.addLayer(e.layer);
      }

    });
   
    this.RetriveGeoFence();

  }

  GetParentRegion() {
    if(this.ParentID!=null){
    this.restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.ParentID, null)
      .subscribe(data => {
        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];
        Data.map(value => this.parentRegionFences.push(value));
        var count = 0;
        this.parentRegionFences.forEach((element, index) => {
          var self = this;
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;

            var circle = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, {  fillColor: "#789", color: "#444e57" });
            circle.addTo(this.map).bringToBack();

          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, {  fillColor: "#789", color: "#444e57" }).addTo(this.map).bringToBack();


          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, {  fillColor: "#789", color: "#444e57" }).addTo(this.map).bringToBack();
          }
        });
      })
    }
  }

  RetriveGeoFence() {
    this.restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.ParentID = data.ParentID;
        this.Level = data.Level;
        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];
        Data.map(value => this.DrawnFences.push(value));
        var subRegions = data.subTriageRegions;
        var self = this;
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.SubRegionFences.push(value));
        })

        self.DrawnFences.forEach((element, index) => {

          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;

            var circle = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius);

            circle.addTo(this.featureGroup);
            if (index == 0) {
              self.map.fitBounds(circle.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect).addTo(this.featureGroup);
            if (index == 0) {
              self.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly).addTo(this.featureGroup);
            if (index == 0) {
              self.map.fitBounds(latlongpoly);
            }
          }
        });
        this.SubRegionFences.forEach((element, index) => {

          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;

            var circle = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, {  fillColor: "#789", color: "#444e57" });

            circle.addTo(this.map);

          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect,{  fillColor: "#789", color: "#444e57" }).addTo(this.map);

          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, {  fillColor: "#789", color: "#444e57" }).addTo(this.map);

          }
        });
        this.GetParentRegion();
      })
  }


  Export() {
    var self = this;
    this.DisButton = true;
    this.UpdatedDrawnFences = Array<GeoData>();
    var self = this;
    self.Fences = Array<GeoData>();
    var count = 0;

    self.featureGroup.eachLayer(function (layer) {

      var fence = new GeoData();
      var data = Array<GeoPoint>();

      if (layer instanceof L.Circle) {

        data.push(layer.getLatLng() as unknown as GeoPoint);
        fence.coordinates = data;
        fence.radius = layer.getRadius();
        fence.type = 'Point';
        var latlng = layer.getLatLng();
        var radiusrecent = layer.getRadius();

        const coordinates = [latlng.lng, latlng.lat];
        const radius = radiusrecent;
        const numberOfEdges = 64;

        let polygon = circleToPolygon(coordinates, radius, numberOfEdges);
        var polyPoints = polygon.coordinates as unknown as L.LatLngExpression[];
        L.polygon(polyPoints, {  fillColor: "#52595D", color: "#3D3C3A" }).addTo(self.map);
        let a = (polygon.coordinates as unknown as L.LatLngExpression[])[0] as unknown as GeoPoint[];
        var geodata = Array<GeoPoint>();
        a.map(value => {
          var points = new GeoPoint();
          points.lat = value[1];
          points.lng = value[0];
          geodata.push(points);

        });

        var inside = false;
        var geopoints = Array<GeoPointInside>();
        geodata.map(value => {
          var data = new GeoPointInside;
          data.lat = value.lat;
          data.lng = value.lng;

          if (data.inside == false) {
            data.inside = self.isMarkerInsideRegion(data.lat, data.lng);
            if (data.inside == false) {
              count = 1;
            }
            else if (data.inside == true && self.SubRegionFences.length > 0) {
              data.inside = self.isMarkerInsideSubRegion(data.lat, data.lng);
              if (data.inside) {
                count = 2;
              }
            }
            geopoints.push(data);
          }
        })

      }
      else if (layer instanceof L.Rectangle) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Rectangle';
      }
      else if (layer instanceof L.Polygon) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Polygon';
      }

      if (fence.type != '') {
        self.UpdatedDrawnFences.push(fence);
        var coordinatesJSON = JSON.stringify(fence.coordinates).split("[[").join("[").split("]]").join("]");
        if (self.DrawnFences.filter(x => x.type == fence.type && x.radius == fence.radius && JSON.stringify(x.coordinates) == coordinatesJSON).length == 0) {
          self.Fences.push(fence);
        }
      }
    });

    if (this.Fences.length > 0 || (this.UpdatedDrawnFences.length != this.DrawnFences.length && this.UpdatedDrawnFences.length > 0)) {

      if (count == 0) {
        this.triageRegion.GeoJson = JSON.stringify(this.UpdatedDrawnFences);
        this.triageRegion.ParentID = this.ParentID;
        this.triageRegion.Level = this.Level;
        this.triageRegion.GeoJson = this.triageRegion.GeoJson.split("[[").join("[").split("]]").join("]");
        this.triageRegion.CreatedDateTime = new Date() as unknown as string;
        this.restservice.Call<servicestatus[]>("PUT",API.CheckStatus,null)
        .subscribe(data => {
          this.Progressstatus = data
          if(data.filter(s=>s.RegionID==this.RegionID && s.SubRegions.filter(a=>a==this.RegionID).length>0).length>0){
            AutoClosingAlert('danger', 'Data is  processing in backend please do not edit region.');
          }
          else{
            this.restservice.Call<TriageRegion>('POST', API.EditTriageRegion + "?ID=" + this.RegionID, this.triageRegion)
              .subscribe(obj => {
                this.router.navigate(['/Home'])
              },
                error => {
                  AutoClosingAlert('danger', error);
                })
          }
      });
          // else{
          //   AutoClosingAlert('danger', 'Data is in processing in backend please do not edit or add region');
          // }
      }
      else if (count == 1) {
        if (this.Level == 2) {
          AutoClosingAlert('danger', 'Region must be inside the domain.');
        }
        else if (this.Level == 3) {
          AutoClosingAlert('danger', 'Sub-region must be inside the region.');
        }
      }
      else if (count == 2) {
        if (this.Level == 1) {
          AutoClosingAlert('danger', 'Domain must be bigger than region.');
        }
        else if (this.Level == 2) {
          AutoClosingAlert('danger', 'Region must be bigger than sub-region.');
        }
      }
    }
    else {
      this.DisButton = false;
      this.modalService.open(this.input);

    }

  }

  public Progressstatus = new Array<servicestatus>();
 

  isMarkerInsideRegion(x, y) {
    var polyPoints: GeoPoint[];
    var inside = false;
    var count = 0;
    this.parentRegionFences.map(value => {
      count = 1;
      polyPoints = value.coordinates;
      if (inside == false) {
        if (value.type == 'Point') {
          inside = this.IsMarkerInsideCircle(x, y, polyPoints[0].lat, polyPoints[0].lng, value.radius);
        }
        else {

          for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
          }
        }
      }
    });
    return !inside && count == 0 ? true : inside;
  };

  IsMarkerInsideCircle(x, y, Lat, Lng, Radius) {
    var inside = false;
    var i = x - Lat;
    var e = y - Lng;
    var Fromlatlng = new LatLng(x, y);
    var ToLatLng = new LatLng(Lat, Lng);

    var distance = Fromlatlng.distanceTo(ToLatLng);
    if (distance <= Radius)
      inside = true;
    return inside;
  }

  isMarkerInsideSubRegion(x, y) {
    var polyPoints: GeoPoint[];
    var inside = false;

    this.SubRegionFences.map(value => {
      polyPoints = value.coordinates;
      if (inside == false) {
        if (value.type == 'Point') {
          inside = this.IsMarkerInsideCircle(x, y, polyPoints[0].lat, polyPoints[0].lng, value.radius);
        }
        else {

          for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;

          }
        }
      }
    })

    return inside;
  }

  
}

