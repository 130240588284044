import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../_services/Globals';
import { RestService } from '../_services/rest.service';
import { BreadCrumb } from '../_models/SiteMap';
import { API } from '../_models/API';
import { Router } from '@angular/router';
import { MenuService } from '../_services/menu.service';
import { ApplicationUser } from '../_models/ApplicationUser';
import { Config } from '../_models/Config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.css']
})
export class FullLayoutComponent implements OnInit {
  public RobocistAdminRoleID = Config.RobocistAdminRoleId;
  public UserRoleID = Config.UserRoleId;

  menu: Array<BreadCrumb> = [];
  breadcrumbList = Array<BreadCrumb>();
  User = new ApplicationUser();
  constructor(private router: Router,
    private menuService: MenuService, private _restService: RestService, private modalService: NgbModal) {

  }
  changeOfRoutes() {
    this.menuService.GetBreadCrumbs().subscribe(data => {
      this.breadcrumbList = data;
    });
    var data = Globals.BreadCrumbID;
    if (this.User.CurrentLoginId == 0) {
      this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    }
    if (this.User.CurrentLoginId != undefined) {
      this._restService.Call('POST', API.PostUserLogActivity + data + "?UserLoginID=" + this.User.CurrentLoginId, null)
        .subscribe(data => {
        })
    }
  }
  ngOnInit() {
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
  }

  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }

  OnClickBreadCrumb(url) {
    this.onRefresh()
    return this.router.navigate([url]);
  }

  @ViewChild('VersionAlert') VersionAlert;
  OpenModel(){
    this.modalService.open(this.VersionAlert);
  }
}
