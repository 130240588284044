import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable() 
export class Globals {
  isLoading=true;
  public static Username:string;
  public static UserDisplayName:string;
  public static CurrentRole:string;
  public static RoleName:string;
  public static MobileNo:string;
  public static Mobilecode:string;
  
  public static CountryCode : string;
  public static UserID :string;
  public static RoleID : string;
  public static AssignmentID =0;
  public static BreadCrumbID ;
  public static Args;
  public static ArgsVal :string="";
  public static RefreshExpertNav:boolean=true;
  public static NodeID;
  public static ActiveClassID;
  public static CurrentLoginId:number;
}