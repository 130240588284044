import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '../_services';
import { Globals } from '../_services/Globals';
import { map } from 'rxjs/operators';
import { ApplicationUser } from '../_models/ApplicationUser';
@Injectable({ providedIn: 'root' })

export class   AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute :ActivatedRoute
    ) { }
    

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            sessionStorage.removeItem('currentToken');
            sessionStorage.setItem('currentToken', this.authenticationService.getCookie("currentToken"));
            sessionStorage.removeItem('currentUser');
            sessionStorage.setItem('currentUser', this.authenticationService.getCookie("currentUser"));
            // logged in so return true
            Globals.Username=currentUser.UserName;
            Globals.RoleName=currentUser.RoleName;
            Globals.UserID = currentUser.Id;
            Globals.BreadCrumbID = route.data['ID'];
            Globals.Args = route.data['args'];
            Globals.NodeID = parseInt(route.paramMap.get('NodeID'))
            var argument = route.data['args'];
            var AssignmentID = Number(route.paramMap.get('AssignID'));
			Globals.AssignmentID = AssignmentID;
            var DiagnosesID = Number(route.paramMap.get('Diagid'));
            var DiagType=route.paramMap.get('DiagType')
            
            if(DiagType==null){
                    if(DiagnosesID == 0){
                    Globals.ActiveClassID = 'Top Diagnoses';
                    }
                    else{
                        Globals.ActiveClassID = DiagnosesID+'T' ;
                    }
            }
            else{
                
                if(DiagnosesID == 0){
                    if(DiagType=="T"){
                        Globals.ActiveClassID = 'Top Diagnoses';
                    }
                    else{
                        Globals.ActiveClassID = 'Red Flag Diagnoses';

                    }
                    }
                    else{
                        Globals.ActiveClassID = DiagnosesID+DiagType ;
                    }
            }
            var argval = '';
            if(argument != undefined){
                for(var i = 0; i<argument.length;i++){
                    argval = argval+','+route.paramMap.get(argument[i])
                }
            }
               Globals.ArgsVal = argval;
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/SignIn'], { queryParams: { returnUrl: state.url } });
        return false;
    }
   
}