import { Component, OnInit, Renderer2, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { ForgotPassword } from '../_models/ForgotPassword';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { UserSecurityQue } from '../_models/UserSecurityQue';
import { SecurityQue } from '../_models/SecurityQue';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private _restService: RestService, private cdRef: ChangeDetectorRef) { }

  public forgotPassword = new ForgotPassword();
  public Conformationdisplay = 'none';
  public lblcheckemail = false;
  public lblemail = "";
  public lblInvalidEmail = "";
  ngOnInit() {


  }

  ngAfterViewInit() {
  }


  OnButtonClick() {
    if (this.forgotPassword.type == "Username") {
      $("#dvOtp").hide();
      this.lblInvalidEmail = "";
      this._restService.Call<any>('POST', API.ForgotPassword, this.forgotPassword).subscribe(
        (data: any) => {

          var input = this.forgotPassword.Email;
          var sub = input.substr(3, input.length - 6);
          var strtoreplace = "#";
          input = input.replace(sub, strtoreplace.repeat(sub.length));
          this.lblemail = input;

          this.lblcheckemail = true;
          this.Conformationdisplay = 'block';
        },
        (error: HttpErrorResponse) => {
          this.lblInvalidEmail = error as unknown as string;
        });
    }

  }



  onCloseHandled() {
    this.Conformationdisplay = 'none';
  }


}
