import { ClientMap } from './ClientMap';
import { TriageRegion } from './TriageRegion';
import { AssignRegion } from './AssignRegion';

export class Client{
    ID:number = 0;
    OrganizationName:string = '';
    FirstName:string = '';
    LastName:string = '';
    Email:string = '';
    Phone:string = '';
    Street:string = '';
    City:string = '';
    State:string = '';
    Zip:string = '';
    WebAddress:string = '';
    Title:string = '';
    CreatedDate:string = '';
    AllowBoundingBox:boolean=true;
    CreatedDateDisplay:string = '';
    ModifiedDateDisplay:string = '';
    IsActive:boolean;
    TriageRegions=Array<TriageRegion>();
    ClientMaps = Array<ClientMap>();
    AssignRegions = Array<AssignRegion>();
    toggle:string=""
    Col:string="collapsed"
}