
    export class ChangePasswordViewModel
    {
        OldPassword :string;

        NewPassword:string;

        ConfirmPassword :string;
    }

