import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { Subject } from 'rxjs';
import { ApplicationUser, AccessUsers } from '../_models/ApplicationUser';
import { ApplicationRole } from '../_models';
import { TriageRegion } from '../_models/TriageRegion';
import { Config } from '../_models/Config';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  userList = Array<AccessUsers>();
  public popoverTitle: string = 'Confirmation';
  public popoverMessage: string = 'Are you sure?';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
  public Roles = Array<ApplicationRole>();
  dtTrigger: Subject<any> = new Subject();
  public IsTiggered = false;
  public Status: string = "Active";
  public Role: string;
  panelOpenState = false;
  domains = new Array<TriageRegion>();
  regions = new Array<TriageRegion>();
  subregions = new Array<TriageRegion>();
  public User: ApplicationUser;
  public IsRobocistAdmin = false;
  constructor(private _restservice: RestService, private cdref: ChangeDetectorRef) { }
  dtOptions: any = {};
  ngOnInit() {
    this.dtOptions = {
      responsive: true,
      searching: true,
      info: true,
      lengthChange: true,
      ordering: true,
      destroy: true,

    };
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    if (this.User.RoleId == Config.RobocistAdminRoleId) {
      this.IsRobocistAdmin = true;
    }
    
    this.GetRoles();

  }

  GetRegionLevelWiseList(level) {
    this._restservice.Call<TriageRegion[]>('GET', API.RegionLevelWiseList + level, null)
      .subscribe(data => {
        if (level == 1) {
          this.domains = data;
        }
        else if (level == 2) {
          this.regions = data;
        }
        else if (level == 3) {
          this.subregions = data;
        }
      })
  }

  GetUserList() {
    this.cdref.detectChanges();
    sessionStorage.removeItem("UserListStatus")
    sessionStorage.removeItem("UserListRole")
    sessionStorage.setItem("UserListStatus",this.Status);
    sessionStorage.setItem("UserListRole",this.Role);
    this._restservice.Call<AccessUsers[]>('GET', API.AssignUserList + "?Status=" + this.Status + "&RoleId=" + this.Role + "&currentRoleId=" + this.User.RoleId, null)
      .subscribe(data => {
        this.userList = data
        $('#tblUsers').DataTable().destroy();
        this.dtTrigger.next();
        this.IsTiggered = true;
      });
  }

  deleteUserData(userid) {
    this._restservice.Call<ApplicationUser>('DELETE', API.DeleteAssignedRegions + userid, null)
      .subscribe(data => {
        this.GetUserList();
        AutoClosingAlert('success', 'User deleted successfully.');
      })

  }
  GetRoles() {

    this._restservice.Call<ApplicationRole[]>('GET', API.Roles, null)
      .subscribe(result => {

        if (this.IsRobocistAdmin == false) {
          result = result.filter(x => x.Id != Config.RobocistAdminRoleId)
        }
        this.Roles = result;
        this.Role = this.Roles[0].Id;
        if(sessionStorage.getItem("UserListStatus")!=null && sessionStorage.getItem("UserListStatus")!=undefined){
          this.Status=sessionStorage.getItem("UserListStatus")
        }
        if(sessionStorage.getItem("UserListRole")!=null && sessionStorage.getItem("UserListRole")!=undefined){
          this.Role=sessionStorage.getItem("UserListRole")
        }
        this.GetUserList();
      })
  }

}
