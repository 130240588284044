import { Component, OnInit } from '@angular/core';
import { Client } from '../_models/Client';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { Router } from '@angular/router';
import { tblMap } from '../_models/tblMap';
import { ClientMap } from '../_models/ClientMap';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

  Client = new Client();
  constructor(public _restService: RestService, public router: Router) { }
  Maps = Array<tblMap>();
  ngOnInit() {
    this.MapList();
  }

  onSubmit() {
if(this.Client.ClientMaps.filter(x=>x.IsActive).length > 0){
    this._restService.Call<Client>('POST', API.CreateClient, this.Client)
      .subscribe(data => {
        AutoClosingAlert('success', 'Client Saved Successfully.');
        this.router.navigate(['/ClientList'])
      },
        error => {
          AutoClosingAlert('danger', error);
        })

      }
      else{
        AutoClosingAlert('danger','Please select atleast one map.');
      }
  }

  MapList() {
    this._restService.Call<tblMap[]>('GET', API.MapList, null)
      .subscribe(data => {
        data.forEach(element => {
          var clientMap = new ClientMap();
          clientMap.MapID  =element.ID;
          clientMap.Map = element;
          this.Client.ClientMaps.push(clientMap);
        });
      })
  }
}
