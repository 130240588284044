import { Component, OnInit, ViewChild } from '@angular/core';
import { API } from '../_models/API';
import { RestService } from '../_services';
import { AssignRegion } from '../_models/AssignRegion';
import { Config } from '../_models/Config';
import { ParamMap, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import '../../assets/scripts/js/Custom/widgets'
import { ApplicationUser } from '../_models/ApplicationUser';
import { ConfirmationDialogService } from '../_services/ConfirmationDialog.service';
import { Client } from '../_models/Client';

@Component({
  selector: 'app-assign-region',
  templateUrl: './assign-region.component.html',
  styleUrls: ['./assign-region.component.css']
})
export class AssignRegionComponent implements OnInit {
  @ViewChild('content') input;

  public clients = Array<Client>();
  public AdminRole = Config.AdminRoleId;
  public RegionalAdminRole = Config.RegionalAdminRoleId;
  public UserRole = Config.UserRoleId;
  public UserId = "";
  public popoverTitle: string = 'Confirmation';
  public popoverMessage: string = 'Send Notification?';
  constructor(private restService: RestService, private _route: ActivatedRoute, private confirmationDialogService: ConfirmationDialogService) { }
  public UserName: string = "";
  public count = 0;
  public User: ApplicationUser;
  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.UserId = params.get('UserId');

    });

    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;

    this.GetAllRegions();

  }

  GetAllRegions() {
    this.restService.Call<AssignRegion[]>('GET', API.GetAllRegions + "?UserId=" + this.UserId, null).subscribe(data => {
      // this.RegionList = data;
      var clientIDs = Array.from(new Set(data.map(x => x.ClientID)));
      clientIDs.map(x => {
        var client = new Client();
        client.ID = x;
        client.OrganizationName = data.find(r => r.ClientID == x).ClientName;
        client.AssignRegions = data.filter(r => r.ClientID == x);


        this.clients.push(client);
      })
      this.clients = this.clients.sort((a, b) => (a.OrganizationName > b.OrganizationName) ? 1 : -1)
      this.clients.forEach(c => {

        if (c.toggle == undefined || c.toggle == "") {
          c.toggle = "show"
          c.Col = "";
        }
        c.AssignRegions.forEach(ass => {
          if (ass.toggle == undefined || ass.toggle == "") {
            ass.toggle = ""
            ass.Col = "collapsed";
          }
          ass.SubAssignRegions.forEach(subass => {
            if (subass.toggle == undefined || subass.toggle == "") {
              subass.toggle = ""
              subass.Col = "collapsed";
            }
          });
         
        });
      });
      this.UserName = data[0].Username;
    })
  }

  GetAllRole() {
    this.restService.Call('GET', API.GetRoleList, null).subscribe(data => {

    })
  }
  Collapse() {
    $('.show').removeClass('show');
    $('.cart-heading').addClass('collapsed');
    this.clients.forEach(C => {
      C.toggle = "";
      C.Col = "collapsed";
    });

  }

  DomainChange(Domain: AssignRegion, value: string) {
    if (value == this.AdminRole || value == this.RegionalAdminRole) {
      Domain.SubAssignRegions.forEach(element => {
        element.RoleID = this.RegionalAdminRole;
        element.SubAssignRegions.forEach(subelement => {
          subelement.RoleID = this.UserRole;
        });
      });
    }
    else {
      Domain.SubAssignRegions.forEach(element => {
        element.RoleID = this.UserRole;
        element.SubAssignRegions.forEach(subelement => {
          subelement.RoleID = this.UserRole;
        });
      });
    }


  }

  DeselectDomain(Domain: AssignRegion) {
    if (Domain.UniqueParentID != 0) {
      this.clients.forEach(client => {
        
        if(client.AssignRegions.length>0){
          if(client.AssignRegions.filter(x => x.UniqueID == Domain.UniqueParentID).length>0){
            client.AssignRegions.filter(x => x.UniqueID == Domain.UniqueParentID)[0].RoleID = null;
          }
    }
    });

    }
    Domain.RoleID = null;
    Domain.SubAssignRegions.forEach(element => {
      element.RoleID = null;
      element.SubAssignRegions.forEach(subelement => {
        subelement.RoleID = null;
      });
    });
  }

  RegionChange(Region: AssignRegion) {
    Region.SubAssignRegions.forEach(element => {
      element.RoleID = this.UserRole;
    });


  }

  Save() {
    this.count = 0;
    this.clients.forEach(client => {
      
    client.AssignRegions.forEach(domain => {
      if (domain.RoleID != null) {
        this.count++;
      }
      domain.SubAssignRegions.forEach(region => {
        if (region.RoleID != null) {
          this.count++;
        }
        region.SubAssignRegions.forEach(subregion => {
          if (subregion.RoleID != null) {
            this.count++;
          }
        });
      });
    });
  });

    if (this.count == 0) {
      this.RegionConfirmationDialog();
    }
    else {
      this.AssignRegionSave();
    }
  }

  public openConfirmationDialog() {
    this.confirmationDialogService.confirm('Please confirm..', 'Regions assigned successfully. Do you want to send notification?')
      .then((confirmed) => {
        if (confirmed) {
          this.restService.Call('POST', API.SendInvitaion, this.clients)
            .subscribe(data => {
              AutoClosingAlert('success', 'Email sent successfully.')
            },
              error => {
                AutoClosingAlert('danger', ' A problem has been occurred while sending invitation.')
              })
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }


  public RegionConfirmationDialog() {
    this.confirmationDialogService.confirm('Please confirm..', 'User will be removed from all regions. Do you want to proceed?')
      .then((confirmed) => {
        if (confirmed) {
          this.AssignRegionSave();
        }
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  AssignRegionSave() {
    this.restService.Call('POST', API.AssignRegion, this.clients)
      .subscribe(data => {
        if (this.count != 0) {
          this.openConfirmationDialog();
        }
        else {
          AutoClosingAlert('success', 'User is removed from all assigned regions.');
        }
      },
        error => {
          $(".alert-danger").show().css('opacity', 1);

          window.setTimeout(function () {
            $(".alert-danger").fadeTo(1000, 0).slideUp(1000, function () {
              $(this).hide();
            });
          }, 1500);
        })
  }

  SubRegionChange(event, subRegion: AssignRegion, Region: AssignRegion, Domain: AssignRegion) {
    if (event.target.checked == true) {
      subRegion.RoleID = this.UserRole;
    }
    else {
      subRegion.RoleID = null;
      Region.RoleID = null;
      Domain.RoleID = null;
    }
  }

  CollapseAction(id, C: Client) {
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    this.clients.forEach(cli => {
      if (cli.ID == C.ID) {
        cli = C;
      }
    });
  }
  CollapseAssig(id, C: AssignRegion, level: number) {

    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }

    if (level == 1) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ID).forEach(a => a = C)


    } else if (level == 2) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ParentID)[0].SubAssignRegions.filter(s => s.ID == C.ID).forEach(a => a = C)


    }

    return C.toggle;
  }

}
