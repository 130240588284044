import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import '../../assets/scripts/lib/bootstrap/dist/js/bootstrap'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { ApplicationUser } from '../_models/ApplicationUser';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {
  public Username ="";
  public userRoles = '';
  public UserID;
  public userRole;
  public userRolesId;
  modalRef: BsModalRef;
  public user : ApplicationUser;
public Activeclass ='';
  constructor(private router: Router, private authenticationService: AuthenticationService,
    private _restservice: RestService) { }

  ngOnInit() {
      this.userRoles = this.authenticationService.currentUserValue.RoleName;
      this.Username=this.authenticationService.currentUserValue.Email;
      this.user = this.authenticationService.currentUserValue;
    this.UserID = this.authenticationService.currentUserValue.Id;
  }
      
 
  onRefresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    let currentUrl = this.router.url + '?';
    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });
  }
  UserData(role) {
    return this.authenticationService.UserFromToken()
      .subscribe(userInfo => {
        this.onRefresh();
        if(userInfo.RoleName != "Invited Expert"){
          this.router.navigate(['/home']);

        }
        else{
          this.router.navigate(['/Dashboard']);

        }
      })
  }

  save(type, data, url) {
    return this._restservice.Call<any>(type, url,
      data)
      .pipe(map(user => {
        return user;
      }))
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/SignIn']);
  }

}
