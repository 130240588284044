import { LatLng } from 'leaflet';
import { tbl_ClusterImage } from './tbl_ClusterImage';

export class GeoData {
  type: string=''
  coordinates: GeoPoint[]; 
  radius: number
}


export class GeoPoint{
  lat:number;
  lng:number;
}

export class InsideMapPoint{
  lat:number;
  lng:number;
  value  :number;
}

export class GeoPointInside{
  lat:number;
  lng:number;
 inside=false;
}