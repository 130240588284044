export class API {
    //static HostAddress: string = "http://localhost:44389/";

    //static HostAddress: string = "https://roadtriageapi.robocist.com/";   // Live URL
    //static HostAddress: string = "https://roadtriageapidev.robocist.com/";   // Developement URL

    static ImageCDN:string="https://imagecdn.roadtriage.com/";// Live url
     //static ImageCDN:string="https://roadtriageimage.robocist.com/";// Developement url

     static HostAddress: string = "https://membersapi.roadtriage.com/"; // Live URL
    //static HostAddress: string = "https://membersdevapi.roadtriage.com/"; // Developement URL


    static HTML_URL="https://robocist.com/SoftwareRelease/"
    static PdfURL="https://robocist.com/SoftwareRelease/ReleaseLivePdf.pdf"; //Live url

    static Token: string = API.HostAddress + "connect/token";
    static TriageRegionList: string = API.HostAddress + "api/TriageRegion/List";
    static CreateTriageRegion: string = API.HostAddress + "api/TriageRegion/Create";
    static CreateSubTriageRegion: string = API.HostAddress + "api/TriageRegion/CreateSub";
    static CheckStatus:string=API.HostAddress+"api/TriageRegion/CheckStatus"
    static CheckStatusPost:string=API.HostAddress+"api/TriageRegion/CheckStatusPost"

    static EditTriageRegion: string = API.HostAddress + "api/TriageRegion/Edit";
    static EditTriageRegionName: string = API.HostAddress + "api/TriageRegion/EditName";
    static DeleteTriageRegion: string = API.HostAddress + "api/TriageRegion/Delete";
    static ReactivateRegion: string = API.HostAddress + "api/TriageRegion/Reactivate/";

    static GetTriageRegion: string = API.HostAddress + "api/TriageRegion";
    static GetAllUsers: string = API.HostAddress + "api/Account/Users";
    static UsersToAssign: string = API.HostAddress + "api/Account/UsersToAssign";

    static ListOfHeatmap: string = API.HostAddress + "api/Cluster/Heatmap";
    static HeatmapImageList: string = API.HostAddress + "api/HeatMap/HeatmapImgList/";
    static ListOfClusterTrafficSign: string = API.HostAddress + "api/Cluster/TrafficSign";
    static ListOfClusterType: string = API.HostAddress + "api/Cluster/ClusterTypeList";
    static GetYearMonth :string = API.HostAddress+"api/Cluster/GetYearMonth/";
    static ListOfClusterRoadMarkingAndLines: string = API.HostAddress + "api/Cluster/RoadMarkingAndLines";
    static ListOfClusterPavementConditionMap: string = API.HostAddress + "api/Cluster/PavementCondition";
    static ListOfClusterPavementMap: string = API.HostAddress + "api/Cluster/Pavement";

    static PavementMapDemo: string = API.HostAddress + "api/Cluster/PavementDemo";

    static GetAllRegions: string = API.HostAddress + "api/AssignRegion/GetAllRegions";
    static GetAllRegionForInvitation: string = API.HostAddress + "api/AssignRegion/InviteForAssignedRegions";
    static AssignRegion: string = API.HostAddress + "api/AssignRegion";

    static ListOfYear: string = API.HostAddress + "api/Cluster/YearList/";
    static ListOfMonth: string = API.HostAddress + "api/Cluster/MonthByYearList/";

    static SegmentListOfYear: string = API.HostAddress + "api/Segment/YearList/";
    static SegmentListOfMonth: string = API.HostAddress + "api/Segment/MonthByYearList/";
    static SegmentGetYearMonth :string = API.HostAddress+"api/Segment/GetYearMonth/";
    //Extra

    static SignUp = API.HostAddress + "api/Account/SignUp";
    static GetUser: string = API.HostAddress + "api/Account/GetUser";
    static ChangePassword = API.HostAddress + "api/Account/ChangePassword";
    static ForgotPasswordByQues: string = API.HostAddress + 'api/Account/ForgotPasswordbyQue';
    static ForgotPassword: string = API.HostAddress + "api/Account/ForgotPassword";
    static SendOTP: string = API.HostAddress + 'api/Account/SendOTP/';
    static VerifyOTP: string = API.HostAddress + 'api/Account/VerifyOTP/';
    static ForgotUserNameByQues: string = API.HostAddress + 'Account/ForgotUsernamedbyQue';
    static Countries: string = API.HostAddress + 'api/masterdata/Country';
    static AgreePolicy = API.HostAddress + "api/Account/AgreePolicy/";
    static PostCurrentUserRole = API.HostAddress + "api/Account/CurrentRoleInsert";
    static SiteMapList: string = API.HostAddress + "api/Account/ByRoleAsync";
    static ResetPassword: string = API.HostAddress + 'api/Account/ResetPassword';
    static ResetUserName: string = API.HostAddress + 'Account/ResetUserName';
    static ValidateDuplicate = API.HostAddress + "api/UserRegistration/ValidateDuplicate"
    static CheckAnswer = API.HostAddress + "api/Account/CheckAnswer";
    static UploadExpertCV: string = API.HostAddress + "api/Expert/UploadCV";

    //for User Component
    static GetRoleList: string = API.HostAddress + "api/Account/ApplicationRoleList";
    static GetRoleEdit: string = API.HostAddress + "Get/AddEditApplicationRole?id=";
    static DeleteRole: string = API.HostAddress + "Post/DeleteApplicationRole?id=";
    static ManagePassword = API.HostAddress + "User/ManagePassword";
    static ManagePasswordPost: string = API.HostAddress + "api/User/ManagePassword";

    //User Registration process...

    static SendInvitationtoUser = API.HostAddress + "api/UserRegistration/Invitation";
    static GetSignupDetailfromcode = API.HostAddress + "api/Account/Signup";
    static SecurityQuestions: string = API.HostAddress + 'api/UserRegistration/Security/GetQue';
    static AssignUserList: string = API.HostAddress + "api/AssignRegion/AssignList";
    static DeleteAssignedRegions = API.HostAddress + "api/AssignRegion/DeleteAssignedRegions/";
    static Roles = API.HostAddress + "api/UserRegistration/Roles";


    static RegionLevelWiseList = API.HostAddress + "api/TriageRegion/LevelWiseList/";

    static HeatmapImgList = API.HostAddress + "api/HeatMap/MapImgList/"
    static TrafficMapImgList = API.HostAddress + "api/Cluster/TrafficMapImgList/"
    static RoadMarkingMapImgList = API.HostAddress + "api/Cluster/RoadMarkingMapImgList/"
    static PavementMapImgList = API.HostAddress + "api/Cluster/PavementMapImgList/"
    static PavementConditionMapImgList = API.HostAddress + "api/Cluster/PavementConditionMapImgList/"


    static GetAllAssignedRegion = API.HostAddress + "api/AssignRegion/GetAllAssignedRegions";
    static BreadCrumbsList = API.HostAddress + "api/Account/BreadcrumbsAsync";

    static GetAllRegionWithUser = API.HostAddress + "api/TriageRegion/GetAllRegionWithUser";

    static SendInvitaion = API.HostAddress + "api/AssignRegion/SendInvitation";

    static GetProfileForEdit = API.HostAddress + "api/UserRegistration/Edit/";
    static UpdateProfile = API.HostAddress + "api/UserRegistration/Edit";
    static Logout = API.HostAddress + "api/Account/Logout/";
    static GetUserLogs = API.HostAddress + "api/UserLogActivity/List";

    static CreateRobocistAdmin = API.HostAddress + "api/UserRegistration/CreateRobocistAdmin";

    static PostUserLogActivity = API.HostAddress + "api/UserLogActivity/POST/";
    static ExportUserLog = API.HostAddress + "api/UserLogActivity/Export";

    //client
    static ClientList = API.HostAddress + "api/Client/List";
    static DeleteCilentList = API.HostAddress + "api/Client/"
    static CreateClient = API.HostAddress + "api/Client/CreateClient"

    static EditClient = API.HostAddress + "api/Client/Edit";
    static MapList = API.HostAddress + "api/Client/MapList";
    static GetClientEdit = API.HostAddress + "api/Client/Edit/";

    static GetClientListWithDomain = API.HostAddress + "api/Client/clientlistwithDomain";
    static ReactivateClient = API.HostAddress + "api/Client/Reactivate/";
    static GetMapsClientWise = API.HostAddress + "api/TriageRegion/GetMapsClientWise/";
    static UpdateClientInDomain = API.HostAddress + "api/TriageRegion/UpdateClient";

    static RoadTriageFAQ = API.HostAddress + "api/FAQ/FAQdata";
    static RoadTriageFAQJson = API.HostAddress + "api/FAQ/FAQJson";
    static SetDefaultRange = API.HostAddress + "api/DomainMapRange/Create"
    static GetDefaultRange = API.HostAddress + "api/DomainMapRange/"
    static GetMapDefault = API.HostAddress + "api/TriageRegion/GetMapDefault/"


    static Reassign = API.HostAddress + "api/AssignRegion/Reassign/";
    static GetDomains = API.HostAddress + "api/AssignRegion/GetDomains/";
    static GetClientAdmins = API.HostAddress + "api/AssignRegion/GetClientAdmins/";
    static GetDomainUserwise = API.HostAddress + "api/AssignRegion/GetDomainUserwise";


    static SegmentList=API.HostAddress+"api/Segment/List/";
    static SegmentPopup =API.HostAddress+"api/Segment/Popup/";
    static SegmentCluster=API.HostAddress+"api/Segment/Cluster/"
    static SegmentPopupHeatmap=API.HostAddress+"api/Segment/PopupHeatmap/";
    
    static LaneLinePopup =API.HostAddress+"api/Segment/LaneLinePopup/";
    static GetSegment=API.HostAddress+"api/Segment/Get/"
    static LaneLineList=API.HostAddress+"api/Segment/LaneList/";

    static CoverageHeatMap=API.HostAddress+"api/Segment/CoverageHeatMap/"
    static CoverageMap=API.HostAddress+"api/Segment/CoverageMap/"
    static ScriptRun=API.HostAddress+"api/RoadCluster/ConsoleApiScript"
    static GetDocuments=API.HostAddress+"api/FAQ/GetDocuments"
    static UploadDocuments=API.HostAddress+"api/FAQ/UploadDocuments"
    static DeleteDocuments=API.HostAddress+"api/FAQ/DeleteDocuments/"
}