import { UserSecurityQue } from './UserSecurityQue';

export class User {
    ID: number;
    UserName: string;
    DisplayName: string;
    CreatedByID = null;
    CreatedDate :Date;
    ModifiedByID = null;
    ModifiedDate = null;
    IsActive = true;
    UserLogin = false;
    PhoneNumber :string;
    MobileCode:string;
    CountryCode : string;
    OTP : string;
    IsAgree = false;
    Expires : string;
    Issued: string;
    ExpiresIn: DoubleRange;
    TokenType : string;
    RefreshToken: string;
    Token:string;
    ApplicationRoles:Array<ApplicationRole>;
    CurrentRole:string;
    RequererEmail :string;
    QueList :string;
    ViewPage:string;
    StatusID = null;
    SelectedRoles : string;
    Password :string;
    ConfirmPassword:string;
    QuestionAns : Array<string>;
    userSecurities : Array<UserSecurityQue>;
    Code :string;
    UserID :string;
    FullName :string;
    Degree :string;
    UserContactPrimaryID:0;
    PatientSecondayUserCount:number;
    UserContactSecondary : Array<any>;
    CurrentRoleID : string;
    type:string='Mobile';
    Instruction= false;
    Gender:string;
    ExpertFullName:string;
}
// export class ApplicationUser{
//     Id:string='';
//     UserName:string='';
//     Email:string='';
//     RoleName:string='';
//     RoleId : string='';
//     CreatedByID:string='';
//     OrganizationID:number=0;
// }

export class ApplicationRole{
    Id:string;
    Name:string;
    Selected:boolean;
}
 