import { UserSecurityQue } from './UserSecurityQue';

export class ForgotPassword {
    Email:string
    MobileNo:string
    userSecurities=Array<UserSecurityQue>()
    type="Username"
    MobileCode:string
    CountryCode : string
    OTP:string
}