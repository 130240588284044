export class Config {
    static grant_type: string = 'password';
    static ClientId: string = 'RoadTriage';
    static client_secret: string = 'Lrt8TTrvjrOANr1rYm31uMpA';
    static scope: string = 'Main openid offline_access';
    static response_type: string = 'id_token token';
    static AdminRoleId : string ='aec44656-3bc7-408b-a8cc-5a84d48d273d';
    static RegionalAdminRoleId : string ='e9b60403-1721-4551-be1a-f8e098509e2e';
    static RobocistAdminRoleId : string ='018ef66b-1012-49bd-9733-2a505b4c846a';

    static UserRoleId : string ='0d15ba47-e5bc-43b8-bca1-76969eb7ed24';
    static refresh_grant_type : string ='refresh_token';
    
}