import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import 'leaflet-draw';
import { TriageRegion, servicestatus } from '../_models/TriageRegion';
import { GeoData, GeoPoint } from '../_models/GeoData';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as esriGeoCoder from 'esri-leaflet-geocoder'



@Component({
  selector: 'app-draw-fence',
  templateUrl: './draw-fence.component.html',
  styleUrls: ['./draw-fence.component.css']
})
export class DrawFenceComponent implements OnInit {

  private map: any;
  private featureGroup: any;
  public srcTiles: string;
  public height: string;
  public Fences = Array<GeoData>();
  public DrawnFences = Array<GeoData>();
  public ShowMap: boolean = true;
  public triageRegion = new TriageRegion();
  public submitted: boolean = false;
  @ViewChild('content') input;
  public constructor(private http: HttpClient, private _restservice: RestService, private _route: ActivatedRoute, private router: Router, private modalService: NgbModal) {
    this.height = window.innerHeight + "px";
  }

  public ngOnInit() {
    this.srcTiles = 'https://tile.osm.org/{z}/{x}/{y}.png';
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.triageRegion.ClientID = parseInt(params.get('ClientID'));
    });
  }

  public ngAfterViewInit() {

    this.map = L.map("map", {
      center: [39.381266, -97.922211],
      zoom: 4,
      // layers: [L.tileLayer(this.srcTiles, { attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' })],
      zoomControl: true
    });
    var osm = L.tileLayer(this.srcTiles, {
      attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19
    }).addTo(this.map),
      mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        attribution: '&copy; Robocist Inc.',
        maxZoom: 19,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      });

    var baseMaps = {
      "Street": osm,
      "Satellite": mqi
    };

    var overlays = {//add any overlays here
    };

    L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);


    this.featureGroup = L.featureGroup().addTo(this.map);
    new L.Control.Draw({
      edit: {
        featureGroup: this.featureGroup
      }
    }).addTo(this.map);
    var searchControl = esriGeoCoder.geosearch().addTo(this.map);
    var results = L.layerGroup().addTo(this.map);
    searchControl.on('results', function (data) {
      results.clearLayers();
      for (var i = data.results.length - 1; i >= 0; i--) {
        results.addLayer(L.marker(data.results[i].latlng));
      }
    });
    var self = this;
    this.map.on('draw:created', function (e) {

      self.featureGroup.addLayer(e.layer);

    });

  }

  Export() {
    var self = this;
    self.Fences = Array<GeoData>();
    this.featureGroup.eachLayer(function (layer) {
      var fence = new GeoData();
      var data = Array<GeoPoint>();
      if (layer instanceof L.Circle) {
        data.push(layer.getLatLng() as unknown as GeoPoint);
        fence.coordinates = data;
        fence.radius = layer.getRadius();
        fence.type = 'Point';

      }
      else if (layer instanceof L.Rectangle) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Rectangle';
      }
      else if (layer instanceof L.Polygon) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Polygon';
      }

      if (fence.type != '') {
        if (self.DrawnFences.filter(x => x.type == fence.type && x.radius == fence.radius && JSON.stringify(x.coordinates) == JSON.stringify(fence.coordinates)).length == 0) {
          self.Fences.push(fence);
        }
      }
    });


    this.triageRegion.GeoJson = JSON.stringify(this.Fences);
    if (this.Fences.length > 0) {
      this.ShowMap = false;

      this.triageRegion.GeoJson = this.triageRegion.GeoJson.split("[[").join("[");
      this.triageRegion.GeoJson = this.triageRegion.GeoJson.split("]]").join("]");
      this.triageRegion.StartMonth = new Date().getMonth() + 1;
      this.triageRegion.StartYear = new Date().getFullYear();
      this.triageRegion.EndMonth = 0;
      this.triageRegion.EndYear = 0;
      this.triageRegion.ReleaseMonth = 0;
      this.triageRegion.ReleaseYear = 0;
    }
    this.modalService.open(this.input);

  }


  onSubmit() {
    this.modalService.dismissAll();
    this.submitted = true;
    this.triageRegion.CreatedDateTime = new Date() as unknown as string;
    var StartDate = new Date(this.triageRegion.StartYear, this.triageRegion.StartMonth - 1);
    var EndDate = new Date();
    var ReleaseDate = new Date();

    if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
      ReleaseDate = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth - 1);
    }
    if (this.triageRegion.EndMonth != 0 && this.triageRegion.EndYear != 0) {
      EndDate = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth - 1);
      if (EndDate < StartDate) {
        AutoClosingAlert('danger', 'End date should be greater than start date.');
      }
      else if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {

        if (!(ReleaseDate >= StartDate && ReleaseDate <= EndDate)) {
          AutoClosingAlert('danger', 'Release date should be greater than start date and less than end date.');

        }
        else {
          this.CreateDomain();
        }
      }
      else if (this.triageRegion.ReleaseMonth != 0 || this.triageRegion.ReleaseYear != 0) {
        if (this.triageRegion.ReleaseMonth == 0 && this.triageRegion.ReleaseYear != 0) {
          AutoClosingAlert('danger', 'Please select release month.');
        }
        else {
          AutoClosingAlert('danger', 'Please select release year.');
        }
      }
      else if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
        if (ReleaseDate < StartDate) {
          AutoClosingAlert('danger', 'Release date should be greater than start date.');

        }
        else {
          this.CreateDomain();
        }
      }
      else if (this.triageRegion.ReleaseMonth != 0 || this.triageRegion.ReleaseYear != 0) {
        if (this.triageRegion.ReleaseMonth == 0 && this.triageRegion.ReleaseYear != 0) {
          AutoClosingAlert('danger', 'Please select release month.');
        }
        else {
          AutoClosingAlert('danger', 'Please select release year.');
        }
      }
      else {
        this.CreateDomain();
      }
    }
    else if (this.triageRegion.EndMonth != 0 || this.triageRegion.EndYear != 0) {
      if (this.triageRegion.EndMonth == 0 && this.triageRegion.EndYear != 0) {
        AutoClosingAlert('danger', 'Please select end month.');
      }
      else {
        AutoClosingAlert('danger', 'Please select end year.');
      }
    }
    else {
      this.CreateDomain();
    }
  }

  CreateDomain() {
    this.triageRegion.StartDate = new Date(this.triageRegion.StartYear, this.triageRegion.StartMonth - 1).toDateString();
    if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
      var date = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth, 0).getDate();
      this.triageRegion.ReleaseDate = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth - 1, date).toDateString();
    }
    else {
      this.triageRegion.ReleaseDate = null;
    }
    if (this.triageRegion.EndMonth != 0 && this.triageRegion.EndYear != 0) {
      var date = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth, 0).getDate();
      this.triageRegion.EndDate = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth - 1, date).toDateString();
    }
    else {
      this.triageRegion.EndDate = null;
    }
    // this._restservice.Call<servicestatus[]>("PUT",API.CheckStatus,null)
    // .subscribe(data => {
    // if(data.filter(s=>s.RegionID==this.re)){
    //   AutoClosingAlert('danger', 'Data is processing in backend please do not add region.');
    // }
    // else{
    this._restservice.Call<TriageRegion>("POST", API.CreateTriageRegion, this.triageRegion)
      .subscribe(obj => {
          AutoClosingAlert('success', 'Geo Fence Created Successfully.');
          this.router.navigate(['/Home'])
      },
        error => {
          AutoClosingAlert('danger', 'System error occured while creating domain.');
        })
      //}
    // });
      //   AutoClosingAlert('success', 'Geo Fence Created Successfully.');
      //   this.router.navigate(['/Home'])
      //     , error => {
      //       AutoClosingAlert('danger', 'System error occured while creating domain.');
      //     }
      // })
  }


}
