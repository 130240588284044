import { AssignRegion } from './AssignRegion';
import { Client } from './Client';

export class RegisterViewModel {
    ID = null ;
   
    DisplayName: string;
   
    FirstName : string;
   
    LastName : string;
   
    Email : string;

    Password : string;
   
    ConfirmPassword : string;
   
    ApplicationRoles = null;

    PhoneNumber : string;
   
    MobileCode = null;

    CountryCode : string;

    UserID = null;
    RequererEmail = null;
    ReturnPage:string;
   }

   export class RegisterNewUserViewModel{
    Id=0;
    FirstName:string;
    LastName:string;
    Email:string;
    ApplicationRoles:string="";
    UserId:string;
    Password:string;
    ConfirmPassword:string;
    UserInfoID =0;
    UserContactID=0;
    Messege:string;
    SecurityQueID:number;
    Answer:string;
    TriageRegionIds:Array<number>;
    Clients = Array<Client>();
   }







