import { AssignRegion } from './AssignRegion';
import { Client } from './Client';

export class TriageRegion {
    ID: number = 0;
    ClientID:number;
    GeoJson : string ="";
    CreatedDateTime:string="";
    Name:string="";
    IsActive:boolean = false;
    IsDeleted : boolean = false;
    ParentID:number=null;
    subTriageRegions = new Array<TriageRegion>();
    assignRegions = new Array<AssignRegion>();
    ParentRegionName :string ="";
    CheckedValue:string="";
    Level :number = 0;
    StartDate:string ="";
    EndDate:string="";
    ReleaseDate:string="";
    StartMonth : number;
    EndMonth:number;
    StartYear : number;
    EndYear: number;
    ReleaseYear : number;
    ReleaseMonth : number;
    AllowAfterEndDate:boolean =false;
    Client=new Client()
    toggle:string=""
    Col:string="collapsed"
    chkBoundingBox:boolean;
}

export class servicestatus{
    ID:number=0;
    RegionID:number;
    Status:string;
    LastUpdateDate:Date;
    LastCompleted:string;
    RegionName:string;
    SubRegions= new Array<number>();
}