import { ApplicationRole } from './User';

export class LoginViewModel {

    Username: string;
    grant_type: string;
    Password: string;
    Code: string;
    client_id: string;
    client_secret: string;
    scope: string;
    response_type: string;
}

export class UserViewModel{
    Id:string;
    UserName:string;
    Name:string;
    LastName:string;
    Password:string;
    ConfirmPassword:string;
    Email:string;
    Mode:string;
    SelectedRoles:string;
    ApplicationRole:Array<ApplicationRole>;
}