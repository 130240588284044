import { Component, OnInit } from '@angular/core';
import { Globals } from '../_services/Globals';
import { API } from '../_models/API';
import { RestService } from '../_services';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
  styleUrls: ['./simple-layout.component.css']
})
export class SimpleLayoutComponent implements OnInit {

  constructor(public _restService:RestService) { }

  ngOnInit() {
  }
  changeOfRoutes(){
    var data = Globals.BreadCrumbID;

    this._restService.Call('POST', API.PostUserLogActivity + data, null)
        .subscribe(data => {
        })
  }
}
