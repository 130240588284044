import { Component, OnInit } from '@angular/core';
import { LoginViewModel } from '../_models/LoginViewModel';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { TokenModel } from '../_models/TokenModel';
import { ApplicationUser } from '../_models/ApplicationUser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginModel = new LoginViewModel();
  submitted = false;
  error = new TokenModel();
  status = 'fa-eye-slash';
  type = 'password';
  userDetails = new ApplicationUser();
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {

  }

  getAlert() {
    if (this.status === 'fa-eye-slash') {
      this.status = 'fa-eye';
      this.type = 'text';
    } else {
      this.status = 'fa-eye-slash';
      this.type = 'password';
    }
  }

  onSubmit() {

    this.submitted = true;
    this.error = new TokenModel();
    // this.loginModel.Username = this.loginModel.Username.toLowerCase();
    this.authenticationService.login(this.loginModel).pipe(first())
      .subscribe(token => {
        if (token != null) {
          return this.UserData();
        }
      },
        error => {
          this.error = error;
        }
      );

  }

  UserData() {

    return this.authenticationService.UserFromToken()
      .subscribe(userInfo => {
        this.userDetails = userInfo;
        this.router.navigate([`/Home`])

        return 'Success'
      })
  }

}
