import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as L from 'node_modules/leaflet';
import 'leaflet.markercluster';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as XLSX from 'xlsx';
import { ApplicationUser } from '../_models/ApplicationUser.js';
import { TriageRegion } from '../_models/TriageRegion.js';
import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
import { LatLng } from 'leaflet';
import { RoadClusters, Clusters, ClusterType } from '../_models/Clusters.js';
import * as  noUiSlider from 'nouislider/distribute/nouislider';
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import { Config } from '../_models/Config';
import { DomainMapRange } from '../_models/DomainMapRange';

@Component({
  selector: 'app-pavement-condition-map',
  templateUrl: './pavement-condition-map.component.html',
  styleUrls: ['./pavement-condition-map.component.css']
})
export class PavementConditionMapComponent implements OnInit {
  public User: ApplicationUser;
  public RegionID: number;
  public DrawnFences = Array<GeoData>();
  chkBoundingBox = false;
  public YearList = [];
  public MonthList = [];
  public lat: number = 0;
  public long: number = 0;
  public ImgList = Array<string>();
  public datalist = Array<InsideMapPoint>();
  public AssessmentCluster = Array<RoadClusters>();
  public height: string;
  public map: any;
  public slider: any;
  public roadslider: any;
  public sliderMonth: any;
  public MinRange: number;
  public MaxRange: number;
  public RoadMinRange: number;
  public RoadMaxRange: number;
  public RoadCharacteristics = [];
  public RideQualities = [];
  public URL: string;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  DomainAdminRole = Config.AdminRoleId;
  isRobocistAdmin = false;
  isAdmin = false;
  public isLoaded = false;
  public Month = 0;
  public Year = 0;
  @ViewChild('content') input;
  public Region = new TriageRegion();
  public isSet = false;
  public currentZoom = 13;
  public currentCenter = new LatLng(35.779591, -78.638176);
  public MonthValue = 0;
  public BackMonth = 0;
  public MapTypeID: number;
  public DomainRange = new DomainMapRange();

  SizeID = 4;
  public constructor(private http: HttpClient, private _Activatedroute: ActivatedRoute,
    private _restservice: RestService, public cdRef: ChangeDetectorRef, private modalService: NgbModal) {
    this.height = window.innerHeight + "px";
   
  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params: ParamMap,) => {
      this.MapTypeID = parseInt(params.get('MapTypeID'));
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.DomainRange.MaximumCoverage = 1;
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    //this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    if (this.User.RoleId == this.RobocistAdminRole) {
      this.isRobocistAdmin = true;
    }
    if (this.User.RoleId == this.RobocistAdminRole || this.User.RoleId == this.DomainAdminRole) {
      this.isAdmin = true;
    }
    this.ListYear()
    this.ListRideQualities();
    this.ListRoadChar();
    this.GetRegion();
   this.GetYearMonth();

  }

  ListYear(){
    this._restservice.Call<string[]>('GET', API.ListOfYear + this.RegionID+"/M", null)
    .subscribe(data => {
      this.YearList = data;

    });
  }
  ListRoadChar(){
    this._restservice.Call<string[]>('GET', API.ListOfClusterType + "?Type=Pavement Condition", null)
    .subscribe(data => {
      this.RoadCharacteristics = data;
     
    });
  }
  ListRideQualities(){
    this._restservice.Call<string[]>('GET', API.ListOfClusterType + "?Type=Ride Quality", null)
    .subscribe(Qualitydata => {
      this.RideQualities = Qualitydata;
      
      // this.GetRegion();

    });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
        this.isLoaded = true;
        // this.GetYearMonth();
        // this.GetDefaultRange();
      })
  }
  GetDefaultRange() {
    this._restservice.Call<DomainMapRange>('GET', API.GetDefaultRange + this.MapTypeID + "/" + this.RegionID, null)
      .subscribe(data => {
        // this.slider.noUiSlider.updateOptions({
        //   start:[data.FairMin,data.FairMax]
        // });
        if (data == null) {
          this.DomainRange.FairMin = 4;
          this.DomainRange.FairMax = 5;

          this.DomainRange.RoadFairMin = 4;
          this.DomainRange.RoadFairMax = 5;

          $('.RoadCharList').map(function () {
            $(this).attr('checked', 'true');
          })
          $('.RideQualityList').map(function () {
            $(this).attr('checked', 'true');
          })
          $('.MapAssessmentList').map(function () {
            if ($(this).val() != 3) {
              $(this).attr('checked', 'true');
            }
          })
          $('.RoadMapAssessmentList').map(function () {
            if ($(this).val() == 1 || $(this).val() == 2) {
              $(this).attr('checked', 'true');
            }
          })
        }
        else {

          this.DomainRange = data;
          if (data.RoadFairMax == null || data.RoadFairMin == null) {
            this.DomainRange.RoadFairMin = 4;
            this.DomainRange.RoadFairMax = 5;
          }

          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
          // if (this.DomainRange.Characteristics == null) {
          //   $('.RoadCharList').map(function () {
          //     $(this).attr('checked', 'true');
          //   })
          // }
          if (this.DomainRange.Characteristics != null) {
            this.DomainRange.Characteristics.forEach(element => {
              $('#' + element).attr('checked', 'true');
            });
          }
          // if (this.DomainRange.RideQuality == null) {
          //   $('.RideQualityList').map(function () {
          //     $(this).attr('checked', 'true');
          //   })
          // } 
          if (this.DomainRange.RideQuality != null) {
            this.DomainRange.RideQuality.forEach(element => {
              $('#' + element).attr('checked', 'true');
            });
          }
          // if(this.DomainRange.RoadAssessment==null){
          //   $('.RoadMapAssessmentList').map(function () {
          //     if ($(this).val() ==1 || $(this).val() ==2) {
          //       $(this).attr('checked', 'true');
          //     }
          //   })
          // }
          if (this.DomainRange.RoadAssessment != null) {
            this.DomainRange.RoadAssessment.forEach(element => {
              $('#Roadchk_' + element).attr('checked', 'true');
            });
          }
          // if (this.DomainRange.Assessment == null) {
          //   $('.MapAssessmentList').map(function () {
          //     if ($(this).val() != 3) {
          //       $(this).attr('checked', 'true');
          //     }
          //   })
          // }
          if (this.DomainRange.Assessment != null) {
            this.DomainRange.Assessment.forEach(element => {
              $('#chk_' + element).attr('checked', 'true');
            });
          }
          this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
        }
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        var EndDate = new Date(this.Year, this.Month);

        this.monthDiff(StartDate, EndDate,'S');
        this.slider = document.getElementById('slider') as any;

        noUiSlider.create(this.slider, {
          start: [this.DomainRange.FairMin, this.DomainRange.FairMax],
          padding: [1, 1],
          connect: true,
          tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
          range: {
            'min': 0,
            'max': 10
          }
        });
        this.roadslider = document.getElementById('Roadslider') as any;

        noUiSlider.create(this.roadslider, {
          start: [this.DomainRange.RoadFairMin, this.DomainRange.RoadFairMax],
          padding: [1, 1],
          connect: true,
          tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
          range: {
            'min': 0,
            'max': 10
          }
        });
        var RoadPoorMax = document.getElementById('RoadPoorMax');
        var RoadFairMin = document.getElementById('RoadFairMin');
        var RoadFairMax = document.getElementById('RoadFairMax');
        var RoadGoodMin = document.getElementById('RoadGoodMin');

        this.roadslider.noUiSlider.on('update', function (values, handle) {
          if (handle) {
            RoadFairMax.innerHTML = Math.round(values[handle]).toString();
            RoadGoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();

          } else {
            RoadFairMin.innerHTML = Math.round(values[handle]).toString();
            RoadPoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();

          }
        });

        var PoorMax = document.getElementById('PoorMax');
        var FairMin = document.getElementById('FairMin');
        var FairMax = document.getElementById('FairMax');
        var GoodMin = document.getElementById('GoodMin');
        this.slider.noUiSlider.on('update', function (values, handle) {
          if (handle) {
            FairMax.innerHTML = Math.round(values[handle]).toString();
            GoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();
          } else {
            FairMin.innerHTML = Math.round(values[handle]).toString();
            PoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();
          }
        });
        this.Map();
      })
  }


  
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;


    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);

    this.monthDiff(StartDate, EndDate,'S');
  }
  monthDiff(d1, d2,Type) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    var minRange = 1;
  
    if (1 == mon) {
      minRange = 1; // minus 1 minute
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      

      if(Type=="S")//Set
      {
         this.sliderMonth = document.getElementById("sliderMonth") as any;
      noUiSlider.create(this.sliderMonth, {
        start: this.DomainRange.MaximumCoverage,
        tooltips: [wNumb({ decimals: 0 })],
        range: {
          'min': 1,
          'max': mon
        },
      });
      var self = this;
      self.sliderMonth.noUiSlider.on('update', function (values, handle) {
        self.MonthValue = Math.round(values[handle]);

      });
    }
    else{ //reset
      this.sliderMonth.noUiSlider.updateOptions({
        start: [this.DomainRange.MaximumCoverage]
      });
    }
    }
  }
  // ngAfterViewInit() {
  //   this.slider = document.getElementById('slider') as any;

  //   noUiSlider.create(this.slider, {
  //     start: [this.DomainRange.FairMin, this.DomainRange.FairMax],
  //     padding: [1, 1],
  //     connect: true,
  //     tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
  //     range: {
  //       'min': 0,
  //       'max': 10
  //     }
  //   });

  //   var PoorMax = document.getElementById('PoorMax');
  //   var FairMin = document.getElementById('FairMin');
  //   var FairMax = document.getElementById('FairMax');
  //   var GoodMin = document.getElementById('GoodMin');
  //   this.slider.noUiSlider.on('update', function (values, handle) {
  //     if (handle) {
  //       FairMax.innerHTML = Math.round(values[handle]).toString();
  //       GoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();
  //     } else {
  //       FairMin.innerHTML = Math.round(values[handle]).toString();
  //       PoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();
  //     }
  //   });


  // }
  ResetSlider() {
    this.slider.noUiSlider.updateOptions({
      start: [this.DomainRange.FairMin, this.DomainRange.FairMax]
    });
    this.roadslider.noUiSlider.updateOptions({
      start: [this.DomainRange.RoadFairMin, this.DomainRange.RoadFairMax]
    });
    this.cdRef.detectChanges();
     // this.slider.noUiSlider.updateOptions({
        //   start:[data.FairMin,data.FairMax]
        // });
        $('.RoadCharList').removeAttr('checked');
  $('.RideQualityList').removeAttr('checked');
   $('.RoadMapAssessmentList').removeAttr('checked');
   $('.MapAssessmentList').removeAttr('checked');

        if (this.DomainRange == null) {
          this.DomainRange.FairMin = 4;
          this.DomainRange.FairMax = 5;

          this.DomainRange.RoadFairMin = 4;
          this.DomainRange.RoadFairMax = 5;

          $('.RoadCharList').map(function () {
            $(this).prop('checked', true);
          })
          $('.RideQualityList').map(function () {
            $(this).prop('checked', true);
          })
          $('.MapAssessmentList').map(function () {
            if ($(this).val() != 3) {
              $(this).prop('checked', true);
            }
          })
          $('.RoadMapAssessmentList').map(function () {
            if ($(this).val() == 1 || $(this).val() == 2) {
              $(this).prop('checked', true);
            }
          })
        }
        else {
         
          if (this.DomainRange.RoadFairMax == null || this.DomainRange.RoadFairMin == null) {
            this.DomainRange.RoadFairMin = 4;
            this.DomainRange.RoadFairMax = 5;
          }

          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
         
         
          if (this.DomainRange.Characteristics != null) {
            this.DomainRange.Characteristics.forEach(element => {
            
              $('#' + element).prop('checked', true);
            });
          }
          else{
            $('.RoadCharList').removeAttr('checked');
          }
         
          if (this.DomainRange.RideQuality != null) {
            this.DomainRange.RideQuality.forEach(element => {
              $('#' + element).prop('checked', true);
            });
          }
          else{
            $('.RideQualityList').removeAttr('checked');
          }
        
          if (this.DomainRange.RoadAssessment != null) {
            this.DomainRange.RoadAssessment.forEach(element => {
              $('#Roadchk_' + element).prop('checked', true);
            });
          }
          else{
            $('.RoadMapAssessmentList').removeAttr('checked');
          }
      
          if (this.DomainRange.Assessment != null) {
            this.DomainRange.Assessment.forEach(element => {
              $('#chk_' + element).prop('checked', true);
            });
          }
          else{
            $('.MapAssessmentList').removeAttr('checked');
          }
          this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
        }
        // var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        // var EndDate = new Date(this.Year, this.Month);

        // this.monthDiff(StartDate, EndDate,'R');
        var RoadPoorMax = document.getElementById('RoadPoorMax');
        var RoadFairMin = document.getElementById('RoadFairMin');
        var RoadFairMax = document.getElementById('RoadFairMax');
        var RoadGoodMin = document.getElementById('RoadGoodMin');
        var PoorMax = document.getElementById('PoorMax');
        var FairMin = document.getElementById('FairMin');
        var FairMax = document.getElementById('FairMax');
        var GoodMin = document.getElementById('GoodMin');
  }


  SetDefault() {
    var domain = new DomainMapRange();
    domain.MapTypeID = this.MapTypeID;
    domain.RegionID = this.RegionID;
    domain.AssetTrendSize = parseInt($('#SizeID').val() as string);
    domain.MaximumCoverage = this.MonthValue;
    let Arr = [];
    let RoadArr = [];

    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    if (Arr.length == 0)
      domain.Assessment = null;
    else
      domain.Assessment = Arr;

    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    if (RoadArr.length == 0)
      domain.RoadAssessment = null;
    else
      domain.RoadAssessment = RoadArr;

    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).val());
    });
    if (Char.length == 0)
      domain.Characteristics = null;
    else
      domain.Characteristics = Char;

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).val());
    })

    if (Quality.length == 0)
      domain.RideQuality = null;
    else
      domain.RideQuality = Quality;

    domain.FairMin = $('#FairMin').text() as unknown as number;
    domain.FairMax = $('#FairMax').text() as unknown as number;

    domain.RoadFairMin = $('#RoadFairMin').text() as unknown as number;
    domain.RoadFairMax = $('#RoadFairMax').text() as unknown as number;

    this._restservice.Call<DomainMapRange>('POST', API.SetDefaultRange, domain)
      .subscribe(data => {
        this.DomainRange = data;
        AutoClosingAlert('success', 'Default Range set successfully.');
      })
  }
  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (isDefault) {
        this.GetDefaultRange();
      }
    }
    else {
      this._restservice.Call<string[]>('GET', API.ListOfMonth + Year + "/" + this.RegionID+"/M", null)
        .subscribe(data => {
          this.MonthList = data;
          this.cdRef.detectChanges();
          $('#YearID').val(Year);
          $('#MonthID').val(Month);
          this.Month = Month;
          this.Year = Year;
          if (isDefault) {
            this.GetDefaultRange();
          }
        });
    }
  }
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.GetYearMonth + this.RegionID+"/M" + "?Type=Pavement Condition", null)
      .subscribe(data => {
        this.FillYearMonth(data.Year, data.MonthID, true);
      })
  }
  Map() {

    var self = this;
    //this.RetriveGeoFence();
    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    let RoadArr = [];
    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    // if (Arr.length == 0) {
    //   $('.MapAssessmentList:checkbox').each(function () {
    //     Arr.push($(this).val())
    //   });
    // }
    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).attr('name'));
    });

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).attr('name'));
    })
    document.getElementById('weathermap').innerHTML = "<div id='map' style='height:925px'></div>";
    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;

    }
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    this.MinRange = $('#FairMin').text() as unknown as number;
    this.MaxRange = $('#FairMax').text() as unknown as number;

    this.RoadMinRange = $('#RoadFairMin').text() as unknown as number;
    this.RoadMaxRange = $('#RoadFairMax').text() as unknown as number;

    this._restservice.Call<any[]>('GET', API.ListOfClusterPavementConditionMap + "?MonthID=" + $('#MonthID').val() + "&Year=" + $('#YearID').val() + "&RegionID=" + this.RegionID + "&AssessmentIDs=" + JSON.parse(JSON.stringify(Arr)) + "&MinRange=" + this.MinRange + "&MaxRange=" + this.MaxRange + "&RoadChar=" + Char.toString() + "&Quality=" + Quality.toString() + "&IsPrevious=" + isPrevious + "&BackMonth=" + (this.BackMonth - 1 + "&RoadAssessmentIDs=" + JSON.parse(JSON.stringify(RoadArr)) + "&RoadMinRange=" + this.RoadMinRange + "&RoadMaxRange=" + this.RoadMaxRange), null)
      .subscribe(data => {

        var markerList = [];
        if (data == null) {
          data = new Array<any>();
        }

        if (data.length > 0 && this.map == undefined) {
          this.lat = data[0].Lt;
          this.long = data[0].Lg;
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }
        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

        var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });

        var baseMaps = {
          "Street": osm,
          "Satellite": mqi
        };

        var overlays = {//add any overlays here
        };

        L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);

        
        var searchControl = esriGeoCoder.geosearch().addTo(this.map);
        var results = L.layerGroup().addTo(this.map);
        searchControl.on('results', function (data) {
          results.clearLayers();
          for (var i = data.results.length - 1; i >= 0; i--) {
            results.addLayer(L.marker(data.results[i].latlng));
          }
        });
        this.map.on('zoomend', function (e) {
          var currZoom = self.map.getZoom();
          if (markerList.length > 0) {
            if (currZoom >= 16) {
              markers.removeLayers(markerList);
            }

            else {
              markers.addLayers(markerList);
            }
          }
        });

        // if ($('#MonthID').val() == "0" && $('#YearID').val() == "0" && data.length > 0) {
        //   var date = data[0].Dt as string;
        //   this.Year = parseInt(date.split('-')[0]);
        //   this.Month = parseInt(date.split('-')[1]);
        //   //this.FillYearMonth(date.split('-')[0], date.split('-')[1]);

        // }
        // else {
        var m = $('#MonthID').val() as string;
        var y = $('#YearID').val() as string;
        this.Month = parseInt(m);
        this.Year = parseInt(y);
        //}

        this.cdRef.detectChanges();

        var len = data.length;

        var markers = L.markerClusterGroup({ chunkedLoading: true });


        for (var i = 0; i < len; i++) {
          var icon = "";
          var item = data[i];
          var cluster = new RoadClusters();
          cluster.GPSLat = item.Lt;
          cluster.GPSLong = item.Lg;
          cluster.ClusterType = item.Ct;
          cluster.Assessment = item.As;
          cluster.AssessmentDate = item.Dt;
          cluster.RoadClusterID = item.CtID;
          cluster.Category = item.CtType;
          cluster.AssessmentClusterID = item.ID;
          this.AssessmentCluster.push(cluster);


          var marker = L.marker(L.latLng(cluster.GPSLat, cluster.GPSLong), {
            title: cluster.ClusterType, draggable: false
          }).on('click', this.MarkerClick.bind(null, cluster, this)
          );
          marker.setOpacity(0);
          markerList.push(marker);
          markers.addLayers(markerList);

          this.map.addLayer(markers);
          // if (Arr.length > 0) {
          var iconColor = "#000000";

          if (cluster.Category == "Ride Quality") {
            if (cluster.Assessment > this.MaxRange && cluster.Assessment <= 10) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#0102F3";//"#008000"
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment >= this.MinRange && cluster.Assessment <= this.MaxRange) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#FE9903";//"#0000ff"
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment >= 0 && cluster.Assessment < this.MinRange) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#FE0106";//"#f31515"
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
          } else {
            if (cluster.Assessment == -1) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#000000";
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment == -2) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#276A27";
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment > this.RoadMaxRange && cluster.Assessment <= 10) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#0102F3";
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment >= this.RoadMinRange && cluster.Assessment <= this.RoadMaxRange) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#FE9903";
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }
            else if (cluster.Assessment >= 0 && cluster.Assessment < this.RoadMinRange) {
              var featureGroup = L.featureGroup().addTo(this.map);
              iconColor = "#FE0106";
              L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
                { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
                .addTo(featureGroup).on('click', this.MarkerClick.bind(null, cluster, this)
                );
              featureGroup.bringToBack();
            }


          }
          // L.circleMarker(L.latLng(cluster.GPSLat, cluster.GPSLong),
          //   { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
          //   .addTo(this.map).on('click', this.MarkerClick.bind(null, cluster, this)
          //   );
          // }
        }

        //  if (this.AssessmentCluster.length > 0) {
        // markers.addLayers(markerList);

        //this.map.addLayer(markers);
        //   }
        this.RetriveGeoFence();
       
      })
  }

  // RetriveGeoFence() {

   
  //   this.DrawnFences = Array<GeoData>();

  //   this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
  //     .subscribe(data => {

  //       var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

  //       var subRegions = data.subTriageRegions;

  //       if (Data.length > 0 && this.map == undefined) {
  //         this.lat = Data[0].coordinates[0].lat;
  //         this.long = Data[0].coordinates[0].lng;
  //       } else if (this.map == undefined) {
  //         this.lat = 35.779591;
  //         this.long = -78.638176;
  //       }
  //       else {
  //         this.lat = this.currentCenter.lat;
  //         this.long = this.currentCenter.lng;
  //       }
  //       this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

  //       var osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
  //         attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //         maxZoom: 19
  //       }).addTo(this.map),
  //         mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
  //           attribution: '&copy; Robocist Inc.',
  //           maxZoom: 19,
  //           subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
  //         });

  //       var baseMaps = {
  //         "Street": osm,
  //         "Satellite": mqi
  //       };

  //       var overlays = {//add any overlays here
  //       };

  //       L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);

  //       var featureGroup = L.featureGroup().addTo(this.map);

  //       Data.map(value => this.DrawnFences.push(value));
  //       subRegions.map(subdata => {
  //         var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
  //         geoFence.map(value => this.DrawnFences.push(value));
  //       })

  //       this.DrawnFences.forEach((element, index) => {
  //         if (element.type == 'Point') {
  //           var latlongcircle = element.coordinates as unknown as LatLng;
  //           var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(c.getBounds());
  //           }
  //         }
  //         else if (element.type == 'Rectangle') {

  //           var latlongrect = element.coordinates as unknown as L.LatLngBounds;
  //           L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(latlongrect);
  //           }
  //         }
  //         else if (element.type == 'Polygon') {
  //           var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
  //           L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(latlongpoly);
  //           }
  //         }
  //       });
  //       featureGroup.bringToBack();

  //     })

  // }

  RetriveGeoFence() {

    var featureGroup = L.featureGroup().addTo(this.map);
    this.DrawnFences = Array<GeoData>();

    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {

        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();

      })

  }

  MarkerClick(cluster, self, e) {

    var lat = cluster.GPSLat;
    var lng = cluster.GPSLong;
    var obj = self as this;
    var ID = cluster.AssessmentClusterID;
    var isPrevious = false;
    if (obj.BackMonth > 0) {
      isPrevious = true;
    }
    var boundingBox = $('#chkBoundingBox').is(':checked');
    obj.http.get<any>(API.PavementConditionMapImgList + ID + "/" + lat + "/" + lng + "?Month=" + obj.Month
      + "&Year=" + obj.Year + "&IsPrevious=" + isPrevious + "&BackMonth=" + obj.BackMonth + "&BoundingBox=" + boundingBox)
      .subscribe(data => {
        obj.datalist = data.Item1;
        obj.ImgList = data.Item2;

        obj.cdRef.detectChanges();

        L.popup({ maxHeight: 350 })
          .setLatLng(e.latlng)
          .setContent(document.getElementById('dvImgList').innerHTML)
          .openOn(obj.map);
        obj.cdRef.detectChanges();

        $('.img').click(function () {
          obj.URL = $(this.firstChild).attr('src');
          obj.cdRef.detectChanges();
          obj.modalService.open(obj.input, { size: 'lg' });
        });
      });
  }

  //********** Export Start ***************/
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  dynamicDownloadTxt() {
    this.dyanmicDownloadByHtmlTag({
      fileName: 'PavementMap',
      text: JSON.stringify(this.AssessmentCluster)
    });
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.AssessmentCluster);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'PavementMap.xlsx');

  }
  //********** Export End ***************/

}
