import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as L from 'node_modules/leaflet';
import 'leaflet.markercluster';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as XLSX from 'xlsx';
import { ApplicationUser } from '../_models/ApplicationUser.js';
import { TriageRegion } from '../_models/TriageRegion.js';
import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
import { LatLng } from 'leaflet';
import { RoadClusters, ClusterType, RoadClustersRaw } from '../_models/Clusters.js';
import * as  noUiSlider from 'nouislider/distribute/nouislider';
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainMapRange, DefaultValues } from '../_models/DomainMapRange';
import { Config } from '../_models/Config';
import 'leaflet-control-geocoder';
import * as esriGeoCoder from 'esri-leaflet-geocoder'

@Component({
  selector: 'app-pavement-map',
  templateUrl: './pavement-map.component.html',
  styleUrls: ['./pavement-map.component.css']
})
export class PavementMapComponent implements OnInit {
  isAdmin = false;
  isRobocistAdmin = false;
  public CurrentYear = (new Date()).getFullYear();
  public User: ApplicationUser;
  public RegionID: number;
  public DrawnFences = Array<GeoData>();
  public YearList = [];
  public MonthList = [];
  public lat: number = 0;
  public long: number = 0;
  public ImgList = Array<string>();
  public datalist = Array<InsideMapPoint>();
  public AssessmentCluster = Array<RoadClusters>();
  public AssessmentClusterRaw = Array<RoadClustersRaw>();
  public height: string;
  public map: any;
  public slider: any;
  public roadslider: any;
  public sliderMonth: any;
  public MinRange: number;
  public MaxRange: number;
  public RoadMinRange: number;
  public RoadMaxRange: number;
  public RoadCharacteristics = [];
  public RideQualities = [];
  public URL: string;
  public Month = 0;
  public Year = 0;
  public isLoaded = false;
  public isSet = false;
  public currentZoom = 13;
  public currentCenter = new LatLng(35.779591, -78.638176);
  public MonthValue = 0;
  public BackMonth = 0;
  public MapTypeID: number;
  public DomainRange = new DomainMapRange();
  RobocistAdminRole = Config.RobocistAdminRoleId;
  DomainAdminRole = Config.AdminRoleId;
  @ViewChild('content') input;
  public Region = new TriageRegion();
  SizeID = 4;
  public Type = ""
  chkBoundingBox = false;

  public defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsMonth")) as DefaultValues;

  public constructor(private http: HttpClient, private _Activatedroute: ActivatedRoute,
    private _restservice: RestService, public cdRef: ChangeDetectorRef, private modalService: NgbModal) {
    this.height = window.innerHeight + "px";
  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params: ParamMap, ) => {
      this.MapTypeID = parseInt(params.get('MapTypeID'));
      this.Type = params.get('Type');
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.DomainRange.MaximumCoverage = 1;
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    // this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    if (this.User.RoleId == this.RobocistAdminRole || this.User.RoleId == this.DomainAdminRole) {
      this.isAdmin = true;
    }
    if (this.User.RoleId == this.RobocistAdminRole) {
      this.isRobocistAdmin = true;
    }
    if (this.Type == "Q") {
      this.defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsQue")) as DefaultValues
    }
    if(this.defaultsSetting!=null ){
      this.chkBoundingBox=this.defaultsSetting.chkBoundingBox;
    }
    this.ListYear()
    this.ListRideQualities();
    this.ListRoadChar();
    this.GetRegion()
    this.GetYearMonth();
  }

  ListYear() {
    this._restservice.Call<string[]>('GET', API.ListOfYear + this.RegionID + '/' + this.Type, null)
      .subscribe(data => {
        this.YearList = data;

      });
  }
  ListRoadChar() {
    this._restservice.Call<string[]>('GET', API.ListOfClusterType + "?Type=Pavement", null)
      .subscribe(data => {
        this.RoadCharacteristics = data;

      });
  }
  ListRideQualities() {
    this._restservice.Call<string[]>('GET', API.ListOfClusterType + "?Type=Ride Quality", null)
      .subscribe(Qualitydata => {
        this.RideQualities = Qualitydata;

        // this.GetRegion();

      });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
        this.isLoaded = true;


        // this.GetYearMonth();
      })
  }
  GetDefaultRange() {
    this._restservice.Call<DomainMapRange>('GET', API.GetDefaultRange + this.MapTypeID + "/" + this.RegionID, null)
      .subscribe(data => {
        // this.slider.noUiSlider.updateOptions({
        //   start:[data.FairMin,data.FairMax]
        // });
        if (data == null) {
          this.DomainRange.FairMin = 4;
          this.DomainRange.FairMax = 5;


          this.DomainRange.RoadFairMin = 4;
          this.DomainRange.RoadFairMax = 5;
          if (this.DomainRange.RoadAssessment != null) {
            this.DomainRange.RoadAssessment.forEach(element => {
              $('#Roadchk_' + element).attr('checked', 'true');
            });
          }
          $('.RoadCharList').map(function () {
            $(this).attr('checked', 'true');
          })
          $('.RideQualityList').map(function () {
            $(this).attr('checked', 'true');
          })
          $('.MapAssessmentList').map(function () {
            if ($(this).val() != 3) {
              $(this).attr('checked', 'true');
            }
          })
        }
        else {

          this.DomainRange = data;

          if (data.RoadFairMax == null || data.RoadFairMin == null) {
            this.DomainRange.RoadFairMin = 4;
            this.DomainRange.RoadFairMax = 5;
          }
          if (this.DomainRange.RoadAssessment != null) {
            this.DomainRange.RoadAssessment.forEach(element => {
              $('#Roadchk_' + element).attr('checked', 'true');
            });
          }

          if (this.DomainRange.MaximumCoverage == null) {
            this.DomainRange.MaximumCoverage = 1;
          }
          // if (this.DomainRange.Characteristics == null) {
          //   $('.RoadCharList').map(function () {
          //     $(this).attr('checked', 'true');
          //   })
          // }
          if (this.DomainRange.Characteristics != null) {
            this.DomainRange.Characteristics.forEach(element => {
              $('#' + element).attr('checked', 'true');
            });
          }
          // if (this.DomainRange.RideQuality == null) {
          //   $('.RideQualityList').map(function () {
          //     $(this).attr('checked', 'true');
          //   })
          // } 
          if (this.DomainRange.RideQuality != null) {
            this.DomainRange.RideQuality.forEach(element => {
              $('#' + element).attr('checked', 'true');
            });
          }
          // if (this.DomainRange.Assessment == null) {
          //   $('.MapAssessmentList').map(function () {
          //     if ($(this).val() != 3) {
          //       $(this).attr('checked', 'true');
          //     }
          //   })
          // }
          if (this.DomainRange.Assessment != null) {
            this.DomainRange.Assessment.forEach(element => {
              $('#chk_' + element).attr('checked', 'true');
            });
          }
          this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
        }
        var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
        var EndDate = new Date(this.Year, this.Month);

        this.monthDiff(StartDate, EndDate, 'S');
        this.slider = document.getElementById('slider') as any;

        noUiSlider.create(this.slider, {
          start: [this.DomainRange.FairMin, this.DomainRange.FairMax],
          padding: [1, 1],
          connect: true,
          tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
          range: {
            'min': 0,
            'max': 10
          }
        });

        this.roadslider = document.getElementById('Roadslider') as any;

        noUiSlider.create(this.roadslider, {
          start: [this.DomainRange.RoadFairMin, this.DomainRange.RoadFairMax],
          padding: [1, 1],
          connect: true,
          tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
          range: {
            'min': 0,
            'max': 10
          }
        });
        var RoadPoorMax = document.getElementById('RoadPoorMax');
        var RoadFairMin = document.getElementById('RoadFairMin');
        var RoadFairMax = document.getElementById('RoadFairMax');
        var RoadGoodMin = document.getElementById('RoadGoodMin');

        this.roadslider.noUiSlider.on('update', function (values, handle) {
          if (handle) {
            RoadFairMax.innerHTML = Math.round(values[handle]).toString();
            RoadGoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();

          } else {
            RoadFairMin.innerHTML = Math.round(values[handle]).toString();
            RoadPoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();

          }
        });


        var PoorMax = document.getElementById('PoorMax');
        var FairMin = document.getElementById('FairMin');
        var FairMax = document.getElementById('FairMax');
        var GoodMin = document.getElementById('GoodMin');
        this.slider.noUiSlider.on('update', function (values, handle) {
          if (handle) {
            FairMax.innerHTML = Math.round(values[handle]).toString();
            GoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();
          } else {
            FairMin.innerHTML = Math.round(values[handle]).toString();
            PoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();
          }
        });
        if (this.defaultsSetting != null && this.defaultsSetting.Coverage != null) {
          var coverage = 4;
          if (this.Type == "M") {
            coverage = 12;
          }

          if (this.sliderMonth != undefined) {
            this.sliderMonth.noUiSlider.updateOptions({
              start: [this.defaultsSetting.Coverage]
            });
          }
        }
        this.IsLoad = true
        this.Map();
      });
  }
  SetDefault() {
    var domain = new DomainMapRange();
    domain.MapTypeID = this.MapTypeID;
    domain.RegionID = this.RegionID;
    domain.AssetTrendSize = parseInt($('#SizeID').val() as string);
    domain.MaximumCoverage = this.MonthValue;
    let Arr = [];
    let RoadArr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });
    if (Arr.length == 0)
      domain.Assessment = null;
    else
      domain.Assessment = Arr;

    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    if (RoadArr.length == 0)
      domain.RoadAssessment = null;
    else
      domain.RoadAssessment = RoadArr;

    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).val());
    });
    if (Char.length == 0)
      domain.Characteristics = null;
    else
      domain.Characteristics = Char;

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).val());
    })

    if (Quality.length == 0)
      domain.RideQuality = null;
    else
      domain.RideQuality = Quality;

    domain.FairMin = $('#FairMin').text() as unknown as number;
    domain.FairMax = $('#FairMax').text() as unknown as number;

    domain.RoadFairMin = $('#RoadFairMin').text() as unknown as number;
    domain.RoadFairMax = $('#RoadFairMax').text() as unknown as number;
    this._restservice.Call<DomainMapRange>('POST', API.SetDefaultRange, domain)
      .subscribe(data => {
        this.DomainRange = data;
        AutoClosingAlert('success', 'Default Range set successfully.');
      })
  }
  monthDiff(d1, d2, Type) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;

    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    var coverage = 4;
    if (this.Type == "M") {
      coverage = 12;
    }


    if (1 == mon) {
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      if (Type == "S")//Set
      {
        this.sliderMonth = document.getElementById("sliderMonth") as any;
        // document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : "Month");
        noUiSlider.create(this.sliderMonth, {
          start: this.DomainRange.MaximumCoverage,
          tooltips: [wNumb({ decimals: 0 })],
          range: {
            'min': 1,
            'max': coverage
          },
        });
        var self = this;
        self.sliderMonth.noUiSlider.on('update', function (values, handle) {
          self.MonthValue = Math.round(values[handle]);
          if (self.MonthValue != self.DomainRange.MaximumCoverage) {
            self.SetSessionDefaults()
          }
        });
      } else { //reset
        this.sliderMonth.noUiSlider.updateOptions({
          start: [this.DomainRange.MaximumCoverage]
        });
      }

    }
  }
  // ngAfterViewInit() {
  //   this.slider = document.getElementById('slider') as any;

  //   noUiSlider.create(this.slider, {
  //     start: [4, 5],
  //     padding: [1, 1],
  //     connect: true,
  //     tooltips: [wNumb({ decimals: 0 }), wNumb({ decimals: 0 })],
  //     range: {
  //       'min': 0,
  //       'max': 10
  //     }
  //   });

  //   var PoorMax = document.getElementById('PoorMax');
  //   var FairMin = document.getElementById('FairMin');
  //   var FairMax = document.getElementById('FairMax');
  //   var GoodMin = document.getElementById('GoodMin');
  //   this.slider.noUiSlider.on('update', function (values, handle) {
  //     if (handle) {
  //       FairMax.innerHTML = Math.round(values[handle]).toString();
  //       GoodMin.innerHTML = (Math.round(values[handle]) + 1).toString();
  //     } else {
  //       FairMin.innerHTML = Math.round(values[handle]).toString();
  //       PoorMax.innerHTML = (Math.round(values[handle]) - 1).toString();
  //     }
  //   });


  // }
  ResetSlider() {
    this.slider.noUiSlider.updateOptions({
      start: [this.DomainRange.FairMin, this.DomainRange.FairMax]
    });
    this.roadslider.noUiSlider.updateOptions({
      start: [this.DomainRange.RoadFairMin, this.DomainRange.RoadFairMax]
    });
    $('.RoadCharList').removeAttr('checked');
    $('.RideQualityList').removeAttr('checked');
    $('.RoadMapAssessmentList').removeAttr('checked');
    $('.MapAssessmentList').removeAttr('checked');
    if (this.DomainRange == null) {
      this.DomainRange.FairMin = 4;
      this.DomainRange.FairMax = 5;


      this.DomainRange.RoadFairMin = 4;
      this.DomainRange.RoadFairMax = 5;
      if (this.DomainRange.RoadAssessment != null) {
        this.DomainRange.RoadAssessment.forEach(element => {
          $('#Roadchk_' + element).prop('checked', true);
        });
      }
      $('.RoadCharList').map(function () {
        $(this).prop('checked', true);
      })
      $('.RideQualityList').map(function () {
        $(this).prop('checked', true);
      })
      $('.MapAssessmentList').map(function () {
        if ($(this).val() != 3) {
          $(this).prop('checked', true);
        }
      })
    }
    else {


      if (this.DomainRange.RoadFairMax == null || this.DomainRange.RoadFairMin == null) {
        this.DomainRange.RoadFairMin = 4;
        this.DomainRange.RoadFairMax = 5;
      }
      if (this.DomainRange.RoadAssessment != null) {
        this.DomainRange.RoadAssessment.forEach(element => {
          $('#Roadchk_' + element).prop('checked', true);
        });
      }
      else {
        $('.RoadMapAssessmentList').removeAttr('checked');
      }

      if (this.DomainRange.MaximumCoverage == null) {
        this.DomainRange.MaximumCoverage = 1;
      }

      if (this.DomainRange.Characteristics != null) {
        this.DomainRange.Characteristics.forEach(element => {
          $('#' + element).prop('checked', true);
        });
      } else {
        $('.RoadCharList').removeAttr('checked');
      }

      if (this.DomainRange.RideQuality != null) {
        this.DomainRange.RideQuality.forEach(element => {
          $('#' + element).prop('checked', true);
        });
      }
      else {
        $('.RideQualityList').removeAttr('checked');
      }


      if (this.DomainRange.Assessment != null) {
        this.DomainRange.Assessment.forEach(element => {
          $('#chk_' + element).prop('checked', true);
        });
      }
      else {
        $('.MapAssessmentList').removeAttr('checked');
      }
      this.SizeID = this.DomainRange.AssetTrendSize == null ? 4 : this.DomainRange.AssetTrendSize;
    }
  }

  IsLoad: boolean = false;

  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (isDefault) {
        this.GetDefaultRange();
      } else {
        this.SetSessionDefaults()
      }
    }
    else {

      var SelectedYear = Year;
      if (this.YearList.filter(s => s == Year).length == 0) {
        SelectedYear = this.LastYear
      }
      this._restservice.Call<string[]>('GET', API.ListOfMonth + SelectedYear + "/" + this.RegionID + "/" + this.Type, null)
        .subscribe(data => {
          if (data != null) {
            this.MonthList = data;
            this.cdRef.detectChanges();
            if (isDefault) {

              if (this.YearList.filter(s => s == Year).length > 0 && this.MonthList.filter(s => s.ID == Month).length > 0) {
                $('#YearID').val(Year);
                $('#MonthID').val(Month);
                this.Month = Month;
                this.Year = Year;
              }
              else {
                $('#YearID').val(this.LastYear);
                $('#MonthID').val(this.LastMonth);
                this.Month = this.LastMonth;
                this.Year = this.LastYear;
              }
            }
            if (isDefault) {
              this.GetDefaultRange();
            } else {
              this.SetSessionDefaults()
            }
          }
        });
    }
  }


  SetSessionDefaults() {

    if (this.IsLoad == true) {
      var defaults = new DefaultValues();
      defaults.Year = $('#YearID').val() as number
      if (this.Type == "Q") {
        defaults.Quarter = $('#MonthID').val() as number;
      } else {
        defaults.Month = $('#MonthID').val() as number;
      }
      defaults.chkBoundingBox = $('input[name="chkBoundingBox"]:checked')[0] == undefined ? false : true
      defaults.Coverage = this.MonthValue;
      if (this.Type == "Q") {
        localStorage.removeItem("SetSessionMapsDefaultsQue")
        localStorage.setItem("SetSessionMapsDefaultsQue", JSON.stringify(defaults))
      } else {
        localStorage.removeItem("SetSessionMapsDefaultsMonth")
        localStorage.setItem("SetSessionMapsDefaultsMonth", JSON.stringify(defaults))
      }
    }
  }

  LastYear: any;
  LastMonth: any;
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.GetYearMonth + this.RegionID + "/" + this.Type + "?Type=Pavement Condition", null)
      .subscribe(data => {

        this.LastYear = data.Year;
        this.LastMonth = data.MonthID;
        if (this.defaultsSetting != null) {
          if (this.Type == "Q") {
            this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Quarter, true);
          } else {
            this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Month, true);
          }
        } else {
          this.FillYearMonth(data.Year, data.MonthID, true);
        }
      })
  }
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;

    if (this.Type == "Q") {
      if (Month == 1) {
        Month = 1
      } else if (Month == 2) {
        Month = 4
      }
      else if (Month == 3) {
        Month = 7
      } else {
        Month = 10
      }
    }

    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);
    this.SetSessionDefaults()
    this.monthDiff(StartDate, EndDate, 'R');
  }
  Map() {

    //this.RetriveGeoFence();

    var self = this;


    let Arr = [];
    $('.MapAssessmentList:checkbox:checked').each(function () {
      Arr.push($(this).val())
    });

    let RoadArr = [];
    $('.RoadMapAssessmentList:checkbox:checked').each(function () {
      RoadArr.push($(this).val())
    });
    // if (Arr.length == 0) {
    //   $('.MapAssessmentList:checkbox').each(function () {
    //     Arr.push($(this).val())
    //   });
    // }
    var Char = [];
    $('.RoadCharList:checkbox:checked').each(function () {
      Char.push($(this).attr('name'));
    });

    var Quality = [];
    $('.RideQualityList:checkbox:checked').each(function () {
      Quality.push($(this).attr('name'));
    })
    document.getElementById('weathermap').innerHTML = "<div id='map' style='height:925px'></div>";
    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;
    }

    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }
    var arrymonth = $('#MonthID').val() as string;
    var sendMonths = parseInt(arrymonth);
    var converageMonth = this.BackMonth;

    if (this.Type == "Q") {
      isPrevious = true
      if (parseInt(arrymonth) == 1) {
        sendMonths = 3
      } else if (parseInt(arrymonth) == 2) {
        sendMonths = 6
      }
      else if (parseInt(arrymonth) == 3) {
        sendMonths = 9
      } else {
        sendMonths = 12
      }
      if (this.BackMonth <= 0) {
        converageMonth = 3
      } else {
        converageMonth = this.BackMonth * 3
      }
    }
    // var isPrevious = $('#chkData').is(":checked");

    this.MinRange = $('#FairMin').text() as unknown as number;
    this.MaxRange = $('#FairMax').text() as unknown as number;

    this.RoadMinRange = $('#RoadFairMin').text() as unknown as number;
    this.RoadMaxRange = $('#RoadFairMax').text() as unknown as number;

    this._restservice.Call<RoadClustersRaw[]>('GET', API.ListOfClusterPavementMap + "?Type=" + this.Type + "&MonthID=" + sendMonths + "&Year=" + $('#YearID').val() + "&RegionID=" + this.RegionID + "&AssessmentIDs=" + JSON.parse(JSON.stringify(Arr)) + "&MinRange=" + this.MinRange + "&MaxRange=" + this.MaxRange + "&RoadChar=" + Char.toString() + "&Quality=" + Quality.toString() + "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1) + "&RoadAssessmentIDs=" + JSON.parse(JSON.stringify(RoadArr)) + "&RoadMinRange=" + this.RoadMinRange + "&RoadMaxRange=" + this.RoadMaxRange, null)
      .subscribe(data => {

        this.AssessmentClusterRaw = data


        var markerList = [];

        if (data.length > 0 && this.map == undefined) {
          this.lat = data[0].Lt;
          this.long = data[0].Lg;
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }
        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

        var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; ' + this.CurrentYear + ' Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });

        var baseMaps = {
          "Street": osm,
          "Satellite": mqi
        };

        var overlays = {//add any overlays here
        };

        // var geocoder = L.Control.geocoder({
        //   defaultMarkGeocode: false,
        //   position:"topleft"
        // })
        //   .on('markgeocode', function(e) {
        //     console.log(e)
        //     // L.marker([e.geocode.center.lat,e.geocode.center.lng]).addTo(self.map);
        //     console.log(parseFloat(e.target._lastGeocode.split(',')[0])+','+parseFloat(e.target._lastGeocode.split(',')[1]))
        //     L.marker([e.geocode.center.lat,e.geocode.center.lng]).addTo(self.map);
        //     var latlong= [
        //       [e.geocode.center.lat,e.geocode.center.lng]
        //       //     //     //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
        //       ];
        //     self.map.fitBounds([latlong]);
        //   })
        //   .addTo(self.map);


        L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);
        var searchControl = esriGeoCoder.geosearch({
          collapseAfterResult: true,
          useMapBounds: false,
          zoomToResult: false,
          providers: [esriGeoCoder.arcgisOnlineProvider()]
        }).addTo(this.map);
        var results = new L.LayerGroup().addTo(this.map);
        searchControl.on('results', function (data) {
          results.clearLayers();
          for (var i = data.results.length - 1; i >= 0; i--) {
            results.addLayer(L.marker(data.results[i].latlng));
          }
        });




        var m = $('#MonthID').val() as string;
        var y = $('#YearID').val() as string;
        this.Month = parseInt(m);
        this.Year = parseInt(y);
        this.cdRef.detectChanges();

        var len = data.length;

        var markers = L.markerClusterGroup({ chunkedLoading: true });


        for (var i = 0; i < len; i++) {



          var featureGroup = L.featureGroup().addTo(this.map);

          var Category = data[i].CtType;
          var Assessment = data[i].As;
          var iconColor = "#000000";
          if (Category == "Ride Quality") {
            if (Assessment > this.MaxRange && Assessment <= 10) {
              iconColor = "#FFFF00";
            }
            else if (Assessment >= this.MinRange && Assessment <= this.MaxRange) {
              iconColor = "#FE9903";
            }
            else if (Assessment >= 0 && Assessment < this.MinRange) {
              iconColor = "#cc0000";
            }
            //  }
          } else {
            if (Assessment == -1) {
              iconColor = "#000000";
            }
            else if (Assessment == -2) {
              iconColor = "#276A27";
            }
            else if (Assessment > this.RoadMaxRange && Assessment <= 10) {
              iconColor = "#FFFF00";
            }
            else if (Assessment >= this.RoadMinRange && Assessment <= this.RoadMaxRange) {
              iconColor = "#FE9903";
            }
            else if (Assessment >= 0 && Assessment < this.RoadMinRange) {
              iconColor = "#cc0000";
            }
          }
          var markercircle = L.circleMarker(L.latLng(data[i].Lt, data[i].Lg), { radius: $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
            .addTo(featureGroup)
            .on('click', this.MarkerClick.bind(null, data[i], this));

          markerList.push(markercircle);
          featureGroup.bringToBack();
          //  var markercircle= L.circleMarker(L.latLng(data[i].Lt, data[i].Lg), { radius:  $('#SizeID').val(), fillColor: iconColor, color: "#000", weight: 1, opacity: 0, fillOpacity: 0.8 })
          //   .addTo(this.map).on('click', this.MarkerClick.bind(null, data[i], this)
          //   );

        }

        // markers.addLayers(markerList);
        this.map.addLayer(markers);

        this.RetriveGeoFence();
        this.SetAssessmentClusterForExport()
      })

  }

  SetAssessmentClusterForExport() {

    this.AssessmentCluster = new Array<RoadClusters>()
      ; for (var i = 0; i < this.AssessmentClusterRaw.length; i++) {
        var icon = "";
        var item = this.AssessmentClusterRaw[i];
        var cluster = new RoadClusters();
        cluster.GPSLat = item.Lt;
        cluster.GPSLong = item.Lg;
        cluster.ClusterType = item.Ct;
        cluster.Assessment = item.As;
        cluster.AssessmentDate = item.Dt;
        cluster.RoadClusterID = item.CtID;
        cluster.AssessmentClusterID = item.ID;
        cluster.Category = item.CtType;
        this.AssessmentCluster.push(cluster);
      }
  }


  // RetriveGeoFence() {


  //   this.DrawnFences = Array<GeoData>();
  //   this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
  //     .subscribe(data => {

  //       var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

  //       var subRegions = data.subTriageRegions;


  //       if (Data.length > 0 && this.map == undefined) {
  //         this.lat = Data[0].coordinates[0].lat;
  //         this.long = Data[0].coordinates[0].lng;
  //       } else if (this.map == undefined) {
  //         this.lat = 35.779591;
  //         this.long = -78.638176;
  //       }
  //       else {
  //         this.lat = this.currentCenter.lat;
  //         this.long = this.currentCenter.lng;
  //       }
  //       this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

  //       var osm = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
  //         attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //         maxZoom: 19
  //       }).addTo(this.map),
  //         mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
  //           attribution: '&copy; Robocist Inc.',
  //           maxZoom: 19,
  //           subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
  //         });

  //       var baseMaps = {
  //         "Street": osm,
  //         "Satellite": mqi
  //       };

  //       var overlays = {//add any overlays here
  //       };

  //       L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);
  //       var featureGroup = L.featureGroup().addTo(this.map);
  //       Data.map(value => this.DrawnFences.push(value));
  //       subRegions.map(subdata => {
  //         var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
  //         geoFence.map(value => this.DrawnFences.push(value));
  //       })

  //       this.DrawnFences.forEach((element, index) => {
  //         if (element.type == 'Point') {
  //           var latlongcircle = element.coordinates as unknown as LatLng;
  //           var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(c.getBounds());
  //           }
  //         }
  //         else if (element.type == 'Rectangle') {

  //           var latlongrect = element.coordinates as unknown as L.LatLngBounds;
  //           L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(latlongrect);
  //           }
  //         }
  //         else if (element.type == 'Polygon') {
  //           var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
  //           L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
  //           if (index == 0 && this.isSet == false) {
  //             this.map.fitBounds(latlongpoly);
  //           }
  //         }
  //       });
  //       featureGroup.bringToBack();

  //     })

  // }

  RetriveGeoFence() {

    var featureGroup = L.featureGroup().addTo(this.map);
    this.DrawnFences = Array<GeoData>();
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {

        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();

      })

  }

  MarkerClick(cluster, self, e) {

    var lat = cluster.Lt;
    var lng = cluster.Lg;
    var obj = self as this;
    var ID = cluster.ID;
    var isPrevious = false;
    if (obj.BackMonth > 0) {
      isPrevious = true;
    }
    var boundingBox = $('#chkBoundingBox').is(':checked');

    var arrymonth = $('#MonthID').val() as string;
    var sendMonths = parseInt(arrymonth);
    var converageMonth = obj.BackMonth;

    if (obj.Type == "Q") {
      isPrevious = true
      if (parseInt(arrymonth) == 1) {
        sendMonths = 3
      } else if (parseInt(arrymonth) == 2) {
        sendMonths = 6
      }
      else if (parseInt(arrymonth) == 3) {
        sendMonths = 9
      } else {
        sendMonths = 12
      }
      if (obj.BackMonth <= 0) {
        converageMonth = 3
      } else {
        converageMonth = obj.BackMonth * 3
      }

    }

    obj.http.get<any>(API.PavementMapImgList + ID + "/" + lat + "/" + lng + "?Type=" + obj.Type + "&Month=" + sendMonths + "&Year=" + obj.Year + "&IsPrevious=" + isPrevious + "&BackMonth=" + (converageMonth - 1) + "&BoundingBox=" + boundingBox)
      .subscribe(data => {
        obj.datalist = data.Item1;
        obj.ImgList = data.Item2;

        obj.cdRef.detectChanges();

        L.popup({ maxHeight: 350 })
          .setLatLng(e.latlng)
          .setContent(document.getElementById('dvImgList').innerHTML)
          .openOn(obj.map);
        obj.cdRef.detectChanges();

        $('.img').click(function () {
          obj.URL = $(this.firstChild).attr('src');
          obj.cdRef.detectChanges();
          obj.modalService.open(obj.input, { size: 'lg' });
        });
      });
  }

  //********** Export Start ***************/
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  dynamicDownloadTxt() {
    this.dyanmicDownloadByHtmlTag({
      fileName: 'PavementMap',
      text: JSON.stringify(this.AssessmentCluster)
    });
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.AssessmentCluster);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'PavementMap.xlsx');

  }
  //********** Export End ***************/

}
