import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { fakeBackendProvider } from './_helpers';
import { AppComponent } from './app.component';
import { AppRoutingModule, routing } from './app.routing';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {searchFilterPipe} from '../Validators/filter-pipe'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import {MustMatchDirective} from '../Validators/MustMatchDirective'
import { MatSidenavModule,
 MatToolbarModule,MatTableModule,MatSortModule,MatMenuModule,MatButtonModule, MatDatepickerModule,MatFormFieldModule, DateAdapter,MatInputModule
} from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import {  } from  '@angular/material/table'
import { MatSort} from '@angular/material/sort'
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { TrafficSignMapComponent } from './traffic-sign-map/traffic-sign-map.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { RoadMarkingsandLinesComponent } from './road-markingsand-lines/road-markingsand-lines.component';
import { PavementConditionMapComponent } from './pavement-condition-map/pavement-condition-map.component';
import { DrawSubRegionComponent } from './draw-sub-region/draw-sub-region.component'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { UserListComponent } from './user-list/user-list.component';
import { AssignRegionComponent } from './assign-region/assign-region.component';
import { HomeComponent } from './home/home.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { MenuService } from './_services/menu.service';
import { RegionViewComponent } from './region-view/region-view.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './_services/ConfirmationDialog.service';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UserLogComponent } from './user-log/user-log.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ClientListComponent } from './client-list/client-list.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { NavbarModule, WavesModule, ButtonsModule, IconsModule, MDBBootstrapModule } from 'angular-bootstrap-md';
import { PavementMapComponent } from './pavement-map/pavement-map.component';
import { RoadTriageFAQComponent } from './road-triage-faq/road-triage-faq.component';
import { LineSegmentMapComponent } from './line-segment-map/line-segment-map.component';
import { SegmentPDFComponent } from './segment-pdf/segment-pdf.component';
import { LaneLineAssessmentComponent } from './lane-line-assessment/lane-line-assessment.component';
import {MatSelectModule} from '@angular/material/select';
import { PDFLaneLineComponent } from './pdflane-line/pdflane-line.component';
import { DocumentComponent } from './document/document.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    routing,
    searchFilterPipe,
    MustMatchDirective,
    HeatmapComponent,
    TrafficSignMapComponent,
    UserDashboardComponent,
    RoadMarkingsandLinesComponent,
    PavementConditionMapComponent,
    DrawSubRegionComponent,
    InviteUserComponent,
    UserListComponent,
    AssignRegionComponent,
    HomeComponent,
    RegionViewComponent,
    ConfirmationDialogComponent,
    EditProfileComponent,
    UserLogComponent,
    AddUserComponent,
    ClientListComponent,
    CreateClientComponent,
    EditClientComponent,
    PavementMapComponent,
    RoadTriageFAQComponent,
    LineSegmentMapComponent,
    SegmentPDFComponent,
    LaneLineAssessmentComponent,
    PDFLaneLineComponent,
    DocumentComponent
    
    
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    DataTablesModule,
    NgbModule,
    NgHttpLoaderModule.forRoot(),
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NavbarModule,
    IconsModule,
    ConfirmationPopoverModule.forRoot({confirmButtonType:'danger'}),
    BreadcrumbModule,
    DateRangePickerModule,
    MDBBootstrapModule.forRoot(),
    MatSelectModule
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,MenuService,ConfirmationDialogService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  exports: [BsDropdownModule, TooltipModule, ModalModule],
  bootstrap: [AppComponent]
})
export class AppModule {
}
