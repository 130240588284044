import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as XLSX from 'xlsx';
import * as L from 'node_modules/leaflet';
import '../../assets/dist/leaflet-heat'
import { ApplicationUser } from '../_models/ApplicationUser';
import { TriageRegion } from '../_models/TriageRegion.js';
import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
import { LatLng } from 'leaflet';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import * as noUiSlider from 'nouislider'
import * as wNumb from '../../assets/scripts/js/Custom/wNumb';
import { ClusterType } from '../_models/Clusters';
import { DefaultValues } from '../_models/DomainMapRange';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.css']
})

export class HeatmapComponent implements OnInit {
  public User: ApplicationUser;
  public RegionID: number;
  public DrawnFences = Array<GeoData>();
  public YearList = [];
  public MonthList = [];
  public height: string;
  public lat: number = 0;
  public long: number = 0;
  public ImgList: InsideMapPoint[];
  public map: any;
  public points = Array<InsideMapPoint>();
  public isLoaded = false;
  public Region = new TriageRegion();
  public Month = 0;
  public Year = 0;
  public isSet = false;
  public currentZoom = 13;
  public currentCenter = new LatLng(35.779591, -78.638176);
  public sliderMonth: any;
  public MonthValue = 0;
  public BackMonth = 0;
  public ImageCDN = API.ImageCDN;

  public defaultsSetting = JSON.parse(localStorage.getItem("SetSessionMapsDefaultsMonth")) as DefaultValues;


  public constructor(private http: HttpClient, private _restservice: RestService, private router: Router, private _Activatedroute: ActivatedRoute, private cdRef: ChangeDetectorRef) {
    this.height = window.innerHeight + "px";
  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params: ParamMap) => {
   
      this.RegionID = parseInt(params.get('RegionID'));
    });
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    //this.RegionID = parseInt(sessionStorage.getItem('RegionID'));
    this.GetYearMonth()
    this.ListYear();

    this.GetRegion();

  }

  IsLoad: boolean = false;
  SetSessionDefaults() {
    if (this.IsLoad == true) {
      var defaults = new DefaultValues();
      defaults.Year = $('#YearID').val() as number

      defaults.Month = $('#MonthID').val() as number;
      defaults.Coverage = this.MonthValue;

      localStorage.removeItem("SetSessionMapsDefaultsMonth")
      localStorage.setItem("SetSessionMapsDefaultsMonth", JSON.stringify(defaults))
    }
  }


  ListYear() {
    this._restservice.Call<string[]>('GET', API.ListOfYear + this.RegionID + "/M", null)
      .subscribe(data => {
        this.YearList = data;

      });
  }
  GetRegion() {
    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
        this.isLoaded = true;
      })
  }
  GetYearMonth() {
    this._restservice.Call<ClusterType>("GET", API.GetYearMonth + this.RegionID + "/M", null)
      .subscribe(data => {
        if (this.defaultsSetting != null) {
          this.FillYearMonth(this.defaultsSetting.Year, this.defaultsSetting.Month, true);
        } else {
          this.FillYearMonth(data.Year, data.MonthID, true);
        }
      })
  }

  IsChangeSlide = false;
  monthDiff(d1, d2, Type) {

    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;

    months -= d1.getMonth() - 1;

    months += d2.getMonth();
    var mon = months <= 0 ? 1 : months as number;
    var coverage = 12;

    this.sliderMonth = document.getElementById("sliderMonth") as any;
    if (1 == mon) {
      mon = 1; // plus 1 minute
      document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
      $("#SameRange").attr("style", "display:block")
      $("#sliderMonth").attr("style", "display:none")
    } else {
      $("#sliderMonth").attr("style", "display:block")
      $("#SameRange").attr("style", "display:none")

      if (this.IsChangeSlide == false)//Set
      {
        this.IsChangeSlide = true
        // document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : "Month");
        noUiSlider.create(this.sliderMonth, {
          start: 1,
          tooltips: [wNumb({ decimals: 0 })],
          range: {
            'min': 1,
            'max': coverage
          },
        });

        var self = this;
        self.sliderMonth.noUiSlider.on('update', function (values, handle) {
          self.MonthValue = Math.round(values[handle]);
          if (self.MonthValue != 1) {
            self.SetSessionDefaults()
          }
        });
      }
      else {
        this.sliderMonth.noUiSlider.updateOptions({
          start: [1]
        });
      }
      if (this.defaultsSetting != null && this.defaultsSetting.Coverage != null) {
        if (this.sliderMonth != undefined) {
          this.sliderMonth.noUiSlider.updateOptions({
            start: [this.defaultsSetting.Coverage]
          });
        }
      }
    }
    // var months;
    // months = (d2.getFullYear() - d1.getFullYear()) * 12;
    // months -= d1.getMonth() - 1;

    // months += d2.getMonth();
    // var mon = months <= 0 ? 1 : months as number;
    // var minRange = 1;



    // if (1 == mon) {

    //   mon = 1; // plus 1 minute
    //   document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : " Month");
    //   $("#SameRange").attr("style", "display:block")
    //   $("#sliderMonth").attr("style", "display:none")
    // } else {
    //   $("#sliderMonth").attr("style", "display:block")
    //   $("#SameRange").attr("style", "display:none")

    //   if (this.IsChangeSlide == false)//Set
    //   {
    //     this.IsChangeSlide=true

    //     this.sliderMonth = document.getElementById("sliderMonth") as any;
    //     // document.getElementById("MaxMonth").innerHTML = mon.toString() + (mon > 1 ? " Months" : "Month");

    //     noUiSlider.create(this.sliderMonth, {
    //       start: 1,
    //       tooltips: [wNumb({ decimals: 0 })],
    //       range: {
    //         'min': 1,
    //         'max': mon
    //       },
    //     });
    //     var self = this;
    //     self.sliderMonth.noUiSlider.on('update', function (values, handle) {
    //       self.MonthValue = Math.round(values[handle]);

    //     });
    //   }else{
    //     this.sliderMonth.noUiSlider.updateOptions({
    //       start: [mon]
    //     });
    //   }
    // }
  }
  FillYearMonth(Year, Month, isDefault: boolean) {
    if (Year == 0) {
      this.MonthList = [];
      $('#MonthID').val(0);
      if (!isDefault) {
        this.SetSessionDefaults()
      }
      this.Map();
    }
    else {
      this._restservice.Call<string[]>('GET', API.ListOfMonth + Year + "/" + this.RegionID + "/M" + "?IsHeatmap=true", null)
        .subscribe(data => {
          this.MonthList = data;
          this.cdRef.detectChanges();
          $('#YearID').val(Year);
          $('#MonthID').val(Month);

          var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
          var EndDate = new Date(Year, Month);
          if (!isDefault) {

            this.SetSessionDefaults()
          }
          this.Map();
          this.monthDiff(StartDate, EndDate, "S");
        });
    }
  }
  MonthChange(Month) {
    var Year = $('#YearID').val() as number;

    var StartDate = new Date(this.Region.StartYear, this.Region.StartMonth);
    var EndDate = new Date(Year, Month);

    this.monthDiff(StartDate, EndDate, "S");
  }
  Map() {
    this.RetriveGeoFence();
    var self = this;


    if (this.map != undefined) {
      this.map.eachLayer(function (layer) {
        self.map.removeLayer(layer);
      });
      this.currentZoom = self.map.getZoom();
      this.currentCenter = self.map.getCenter();
      this.isSet = true;
    }
    this.BackMonth = this.MonthValue;// this.sliderMonth.noUiSlider.get();
    var isPrevious = false;
    if (this.BackMonth > 1) {
      isPrevious = true;
    }

    document.getElementById('weathermap').innerHTML = "<div id='map' style='width:1300px'></div>";


    this._restservice.Call<any[]>('GET', API.ListOfHeatmap + "?MonthID=" + $('#MonthID').val() + "&Year=" + $('#YearID').val() + "&RegionID=" + this.RegionID + "&IsPrevious=" + isPrevious + "&BackMonth=" + (this.BackMonth - 1), null)
      .subscribe(data => {

        // var searchControl = esriGeoCoder.geosearch().addTo(this.map);
        // var results = L.layerGroup().addTo(this.map);
        // searchControl.on('results', function (data) {
        //   results.clearLayers();
        //   for (var i = data.results.length - 1; i >= 0; i--) {
        //     results.addLayer(L.marker(data.results[i].latlng));
        //   }
        // });
        if ($('#MonthID').val() == "0" && $('#YearID').val() == "0" && data.length > 0) {
          var date = data[0].Dt as string;
          this.Year = parseInt(date.split('-')[0]);
          this.Month = parseInt(date.split('-')[1]);
          this.FillYearMonth(date.split('-')[0], date.split('-')[1],false);
        }
        else {
          var m = $('#MonthID').val() as string;
          var y = $('#YearID').val() as string;
          this.Month = parseInt(m);
          this.Year = parseInt(y);
        }
        var len = data.length;
        this.points = Array<InsideMapPoint>();
        for (let i = 0; i < len; i++) {
          var point = new InsideMapPoint();
          var item = data[i];

          point.lat = item.Lt;
          point.lng = item.Lg;
          point.value = item.Cd;
          this.points.push(point);
        }
        L.heatLayer(this.points).addTo(self.map);
        // SearchResult
        //var layerresults = new L.LayerGroup().addTo(this.map);
        var geocoder = L.Control.geocoder({
          defaultMarkGeocode: false,
          position: "topleft",
        })
          .on('markgeocode', function (e) {
            var latlng = e.geocode.center;
            if (e.target._lastGeocode.split(',').length < 2) {
              var marker = L.marker(latlng).addTo(self.map);
            } else {
              var marker = L.marker([parseFloat(e.target._lastGeocode.split(',')[0]), parseFloat(e.target._lastGeocode.split(',')[1])]).addTo(self.map);
            }
            self.map.fitBounds(e.geocode.bbox);

          })
          .addTo(self.map);
      })

  }
  onDragEnd(event) {
    var latlng = event.target.getLatLng();
  }

  MarkerClick(cluster, self) {

    var lat = cluster.lat;
    var lng = cluster.lng;
    var obj = self as this;
    var isPrevious = false;
    if (obj.BackMonth > 0) {
      isPrevious = true;
    }
    obj.http.get<InsideMapPoint[]>(API.HeatmapImageList + lat + "/" + lng + "?Month=" + obj.Month + "&Year=" + obj.Year + "&IsPrevious=" + isPrevious + "&BackMonth=" + obj.BackMonth)
      .subscribe(data => {
        obj.ImgList = data;

        obj.cdRef.detectChanges();

        L.popup()
          .setLatLng([lat, lng])
          .setContent(document.getElementById('dvImgList').innerHTML)
          .openOn(obj.map);

      });
  }

  RetriveGeoFence() {

    this.DrawnFences = Array<GeoData>();

    this._restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        if (Data.length > 0 == this.map == undefined) {
          this.lat = Data[0].coordinates[0].lat;
          this.long = Data[0].coordinates[0].lng;
        } else if (this.map == undefined) {
          this.lat = 35.779591;
          this.long = -78.638176;
        }
        else {
          this.lat = this.currentCenter.lat;
          this.long = this.currentCenter.lng;
        }
        this.map = L.map('map', { center: new L.LatLng(this.lat, this.long), zoom: this.currentZoom, canvas: true });

        var osm = L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 19
        }).addTo(this.map),
          mqi = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
            attribution: '&copy; Robocist Inc.',
            maxZoom: 19,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          });

        var baseMaps = {
          "Street": osm,
          "Satellite": mqi
        };

        var overlays = {//add any overlays here
        };

        L.control.layers(baseMaps, overlays, { position: 'topleft' }).addTo(this.map);

        var featureGroup = L.featureGroup().addTo(this.map);


        Data.map(value => this.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => this.DrawnFences.push(value));
        })

        this.DrawnFences.forEach((element, index) => {
          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;
            var c = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(c.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly, { fillOpacity: 0.1 }).addTo(featureGroup);
            if (index == 0 && this.isSet == false) {
              this.map.fitBounds(latlongpoly);
            }
          }
        });
        featureGroup.bringToBack();

      })

  }

  //********** Export Start ***************/


  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  dynamicDownloadTxt() {
    this.dyanmicDownloadByHtmlTag({
      fileName: 'DataCoverageJson',
      text: JSON.stringify(this.points)
    });
  }
  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.points);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'DataCoverageMap.xlsx');

  }
  //********** Export End ***************/

}
