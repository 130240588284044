import { Directive,Input} from '@angular/core';
import { FormGroup, NG_VALIDATORS, Validator, ValidationErrors } from '@angular/forms';
import {MustMatch} from '../Validators/MatchValidators'
import * as $ from 'jquery';
@Directive({
  selector: '[mustMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true }]
})


export class MustMatchDirective implements Validator{
  constructor( ) { }

  @Input('mustMatch') mustMatch: string[] = [];

  validate(formGroup: FormGroup): ValidationErrors {
      return MustMatch(this.mustMatch[0], this.mustMatch[1])(formGroup);
  }
}
