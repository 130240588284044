import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, Input, OnDestroy } from '@angular/core';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { AssignRegion } from '../_models/AssignRegion';
import { Router } from '@angular/router';
import { Config } from '../_models/Config';
import { ApplicationUser } from '../_models/ApplicationUser';
import { TriageRegion, servicestatus } from '../_models/TriageRegion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../_models/Client';
import { HttpClient } from '@angular/common/http';
import { Subscription, interval } from 'rxjs';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
  User: ApplicationUser;
  constructor(private _restService: RestService, private _http: HttpClient, private _router: Router, private modalService: NgbModal, private cdRef: ChangeDetectorRef) { }
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    // console.debug("Scroll Event", document.body.scrollTop);
    // see András Szepesházi's comment below
    if (this.scrolled == true) {
      this.ScrollStore = window.pageYOffset
      localStorage.removeItem("ScrollStore");
      localStorage.setItem("ScrollStore", this.ScrollStore)
    }

  }

  IsPageLoad = false;
  ScrollStore: any = localStorage.getItem("ScrollStore");
  RegionList = Array<AssignRegion>();
  AdminRole = Config.AdminRoleId;
  RegionalAdminRole = Config.RegionalAdminRoleId;
  UserRole = Config.UserRoleId;
  RobocistAdminRole = Config.RobocistAdminRoleId;
  isLoaded = false;
  isRobocistAdmin = false;
  triageRegion = new TriageRegion();
  clients = Array<Client>();
  Status = 1;
  @ViewChild('content') input;
  EndMonthinvalid = false;
  ValidateReleasedate = false;
  private updateSubscription: Subscription;

  @ViewChild('processalert') processalert;

  ngOnInit() {
    this.GetAllAssignedRegion();
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    this.checkstatus()
    this.updateSubscription = interval(10000)
      .subscribe(
        (val) => {

          if (this.CheckprocessStatus == true) {
            this.checkstatus()

          }
        }
      );

  }

  @Input() someKindOfGridId: string;
  rowsCount: number = 0;
  scrolled: boolean = false;

  ngAfterViewChecked() {
    this.ScrollStore = localStorage.getItem("ScrollStore")
    let newRowsCount = this.clients.length;
    if (newRowsCount <= 0 || !this.rowsCountChanged(newRowsCount) || this.scrolled)
      return;

    window.scroll(0, this.ScrollStore)
    this.rowsCount = newRowsCount;
    this.scrolled = true;
  }

  private rowsCountChanged(newRowsCount: number): boolean {

    return newRowsCount !== this.rowsCount;
  }

  ngOnDestroy() {
    this.IsPageLoad = false
    // this.ScrollStore=window.pageYOffset
    //localStorage.removeItem("ScrollStore")
    this.scrolled = false;
  }
  public ngAfterViewInit() {
    this.IsPageLoad = true

  }
  // ngAfterViewChecked() {
  //   if(this.scrolled==false){
  //   this.ScrollStore=localStorage.getItem("ScrollStore")
  //     console.log(this.ScrollStore)
  //       window.scroll(0,this.ScrollStore)
  //       this.cdRef.detectChanges();
  //       this.scrolled=true
  //     }
  // }


  GetAllAssignedRegion() {
    this.isLoaded = false;
    this.cdRef.detectChanges();
    this._restService.Call<AssignRegion[]>('GET', API.GetAllAssignedRegion + "?Active=" + this.Status, null)
      .subscribe(data => {
        this.RegionList = data;
        this.isLoaded = true;
        this.clients = Array<Client>();
        var sorClient = JSON.parse(sessionStorage.getItem("ClientList")) as Client[];

        if (this.User.RoleId == this.RobocistAdminRole) {
          this.isRobocistAdmin = true;
        }
        var clientIDs = Array.from(new Set(this.RegionList.map(x => x.ClientID)));
        clientIDs.map(x => {
          var client = new Client();
          client.ID = x;
          client.OrganizationName = this.RegionList.find(r => r.ClientID == x).ClientName;
          client.AssignRegions = this.RegionList.filter(r => r.ClientID == x);
          if (sorClient != null && sorClient != undefined) {
            if(sorClient.filter(s => s.ID == client.ID)[0]==undefined){
              sorClient.push(client)
            }
            client.toggle = sorClient.filter(s => s.ID == client.ID)[0].toggle
            client.Col = sorClient.filter(s => s.ID == client.ID)[0].Col

            if (client.toggle == undefined || client.toggle == "") {
              client.toggle = "show"
              client.Col = "";
            }
          }
          this.clients.push(client);
        })
        this.clients = this.clients.sort((a, b) => (a.OrganizationName > b.OrganizationName) ? 1 : -1)


        if (sorClient != null && sorClient != undefined) {
          this.clients.forEach(c => {
            c.toggle = sorClient.filter(s => s.ID == c.ID)[0].toggle
            c.Col = sorClient.filter(s => s.ID == c.ID)[0].Col

            if ((c.toggle == undefined || c.toggle == "") && (c.Col == undefined || c.Col == "")) {
              c.toggle = "show"
              c.Col = "";
            }

            c.AssignRegions.forEach(ass => {
              if (sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID) != null && sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0] != undefined) {
                  ass.toggle = sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0].toggle
                  ass.Col = sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0].Col
                if ((ass.toggle == undefined || ass.toggle == "") && (ass.Col == undefined || ass.Col == "")) {
                  ass.toggle = ""
                  ass.Col = "collapsed";
                }
              }
              ass.SubAssignRegions.forEach(subass => {
                if (sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID) != null && sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0] != undefined) {
                if (sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0].SubAssignRegions.filter(a => a.ID == subass.ID) != null) {
                  subass.toggle = sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0].SubAssignRegions.filter(a => a.ID == subass.ID)[0].toggle
                  subass.Col = sorClient.filter(s => s.ID == c.ID)[0].AssignRegions.filter(a => a.ID == ass.ID)[0].SubAssignRegions.filter(a => a.ID == subass.ID)[0].Col
                  if ((subass.toggle == undefined || subass.toggle == "") && (subass.Col == undefined || subass.Col == "")) {
                    subass.toggle = ""
                    subass.Col = "collapsed";
                  }
                }
              }
              });
              this.cdRef.detectChanges();
            });
          });
        } else {
          this.clients.forEach(c => {

            if (c.toggle == undefined || c.toggle == "") {
              c.toggle = "show"
              c.Col = "";
            }
            c.AssignRegions.forEach(ass => {
              if (ass.toggle == undefined || ass.toggle == "") {
                ass.toggle = ""
                ass.Col = "collapsed";
              }
              ass.SubAssignRegions.forEach(subass => {
                if (subass.toggle == undefined || subass.toggle == "") {
                  subass.toggle = ""
                  subass.Col = "collapsed";
                }
              });
              this.cdRef.detectChanges();
            });
          });
        }
        this.cdRef.detectChanges();

        if ($('.View').length == 0) {
          $('#ViewHeading').hide();
        }
        if ($('.Add').length == 0) {
          $('#AddHeading').hide();
        }
        if ($('.Edit').length == 0) {
          $('#EditHeading').hide();
        }
        if ($('.EditName').length == 0) {
          $('#EditNameHeading').hide();
        }
        if ($('.Delete').length == 0) {
          $('#DeleteHeading').hide();
        }
        if ($('.Reactive').length == 0) {
          $('#ReactiveHeading').hide();
        }
      })
  }

  DeleteRegion(regionID: string) {
    if (this.checkstatusdata(regionID)) {
      if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length == 0) {
        if (regionID != "0") {
          this._restService.Call('DELETE', API.DeleteTriageRegion + "?ID=" + regionID, null)
            .subscribe(data => {
              this.GetAllAssignedRegion();
              AutoClosingAlert('success', 'Region Deleted Successfully.');
            }
              , error => {
                AutoClosingAlert('danger', 'A problem has been occurred while deleting region.')
              })
        }
      } else {
        this.SelectedProcessRegion = this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0)[0]
        //this.modalService.open(this.processalert);
      }
    }
  }

  EditName(ID) {
    if (this.checkstatusdata(ID)) {
      if (this.Progressstatus.filter(s => s.RegionID == ID || s.SubRegions.filter(a => a == ID).length > 0).length == 0) {
        this._restService.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + ID, null)
          .subscribe(data => {
            this.triageRegion = data;

            this.cdRef.detectChanges();
            this.modalService.open(this.input);
          })
      } else {
        this.SelectedProcessRegion = this.Progressstatus.filter(s => s.RegionID == parseInt(ID) || s.SubRegions.filter(a => a == parseInt(ID)).length > 0)[0]
        //this.modalService.open(this.processalert);
      }
    }
  }

  Submit() {
    // var StartDate = new Date(this.triageRegion.StartYear, this.triageRegion.StartMonth - 1);
    // var EndDate = new Date();
    // var ReleaseDate = new Date();
    // if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
    //   ReleaseDate = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth - 1);
    // }

    // if (this.triageRegion.EndMonth != 0 && this.triageRegion.EndYear != 0) {
    //   EndDate = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth - 1);
    //   if (EndDate < StartDate) {
    //     AutoClosingAlert('danger', 'End date should be greater than start date.');
    //   }
    //   else if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {

    //     if (!(ReleaseDate >= StartDate && ReleaseDate <= EndDate)) {
    //       AutoClosingAlert('danger', 'Release date should be greater than start date and less than end date.');

    //     }
    //     else {
    //       this.UpdateDomain();
    //     }
    //   }
    //   else if (this.triageRegion.ReleaseMonth != 0 || this.triageRegion.ReleaseYear != 0) {
    //     if (this.triageRegion.ReleaseMonth == 0 && this.triageRegion.ReleaseYear != 0) {
    //       AutoClosingAlert('danger', 'Please select release month.');
    //     }
    //     else {
    //       AutoClosingAlert('danger', 'Please select release year.');
    //     }
    //   }
    //   else {
    //     this.UpdateDomain();
    //   }
    // }
    // else if (this.triageRegion.EndMonth != 0 || this.triageRegion.EndYear != 0) {
    //   if (this.triageRegion.EndMonth == 0 && this.triageRegion.EndYear != 0) {
    //     AutoClosingAlert('danger', 'Please select end month.');
    //   }
    //   else {
    //     AutoClosingAlert('danger', 'Please select end year.');
    //   }
    // }

    // else if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
    //   if (ReleaseDate < StartDate) {
    //     AutoClosingAlert('danger', 'Release date should be greater than start date.');

    //   }
    //   else {
    //     this.UpdateDomain();
    //   }
    // }
    // else if (this.triageRegion.ReleaseMonth != 0 || this.triageRegion.ReleaseYear != 0) {
    //   if (this.triageRegion.ReleaseMonth == 0 && this.triageRegion.ReleaseYear != 0) {
    //     AutoClosingAlert('danger', 'Please select release month.');
    //   }
    //   else {
    //     AutoClosingAlert('danger', 'Please select release year.');
    //   }
    // }
    // else {
    //   this.UpdateDomain();
    // }
    if (this.triageRegion.EndMonth == 0 || this.triageRegion.EndYear == 0) {
      this.EndMonthinvalid = true;
    }
    else {
      this.EndMonthinvalid = false
    }
    if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
      if (this.triageRegion.ReleaseYear < this.triageRegion.StartYear || this.triageRegion.ReleaseYear > this.triageRegion.EndYear) {
        this.ValidateReleasedate = true;

      } else if (this.triageRegion.ReleaseYear == this.triageRegion.StartYear || this.triageRegion.ReleaseYear == this.triageRegion.EndYear) {
        if (this.triageRegion.ReleaseYear == this.triageRegion.StartYear && this.triageRegion.ReleaseMonth < this.triageRegion.StartMonth) {
          this.ValidateReleasedate = true;
        } else if (this.triageRegion.ReleaseYear == this.triageRegion.EndYear && this.triageRegion.ReleaseMonth > this.triageRegion.EndMonth) {
          this.ValidateReleasedate = true;
        } else {
          this.ValidateReleasedate = false;
        }
      }
      else {
        this.ValidateReleasedate = false;
      }
    } else { this.ValidateReleasedate = false; }

    if (this.EndMonthinvalid == false && this.triageRegion.EndMonth >= this.triageRegion.StartMonth
      && this.triageRegion.EndYear >= this.triageRegion.StartYear && this.ValidateReleasedate == false) {
      this.UpdateDomain()
    }
  }

  UpdateDomain() {
    this.triageRegion.StartDate = new Date(this.triageRegion.StartYear, this.triageRegion.StartMonth - 1).toDateString();

    if (this.triageRegion.ReleaseMonth != 0 && this.triageRegion.ReleaseYear != 0) {
      this.triageRegion.ReleaseDate = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth - 1).toDateString();
      // this.triageRegion.ReleaseDate = new Date(this.triageRegion.ReleaseYear, this.triageRegion.ReleaseMonth - 1, rdate).toDateString();
    }
    else {
      this.triageRegion.ReleaseDate = null;
    }
    if (this.triageRegion.EndMonth != 0 && this.triageRegion.EndYear != 0) {
      this.triageRegion.EndDate = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth - 1).toDateString();
      //var date = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth, 0).getDate();
      // this.triageRegion.EndDate = new Date(this.triageRegion.EndYear, this.triageRegion.EndMonth - 1, date).toDateString();
    }
    else {
      this.triageRegion.EndDate = null;
    }
    this._restService.Call<Client>('PUT', API.UpdateClientInDomain, this.triageRegion)
      .subscribe(data => {
        this.modalService.dismissAll();
        AutoClosingAlert('success', 'Domain updated successfully.');
        this.GetAllAssignedRegion();
      })
  }

  UpdateName() {



    if (this.triageRegion.Level == 1) {
      this.Submit();
    }
    else {

      this._restService.Call<TriageRegion>('PUT', API.EditTriageRegionName + "?ID=" + this.triageRegion.ID, this.triageRegion)
        .subscribe(data => {
          AutoClosingAlert('success', 'Data updated successfully.');
          this.modalService.dismissAll();
          this.GetAllAssignedRegion();
        })
    }

  }

  Collapse() {
    $('.show').removeClass('show');
    $('.cart-heading').addClass('collapsed');
    this.clients.forEach(C => {
      C.toggle = "";
      C.Col = "collapsed";
    });
    sessionStorage.removeItem("ClientList")
    sessionStorage.setItem("ClientList", JSON.stringify(this.clients))

  }

  CollapseAction(id, C: Client) {

    // if ($(id).hasClass("show")==false) {
    //   $(id).addClass('show');
    //   $(id).removeClass('collapsed');
    //   $(id).addClass('collapse');
    // } else {
    //   $(id).removeClass('show');
    //   $(id).removeClass('collapse');
    //   $(id).addClass('collapsed');
    // }
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }
    this.clients.forEach(cli => {
      if (cli.ID == C.ID) {
        cli = C;
      }
    });
    sessionStorage.removeItem("ClientList")
    sessionStorage.setItem("ClientList", JSON.stringify(this.clients))
  }

  CollapseAssig(id, C: AssignRegion, level: number) {
    // if ($(id).hasClass("show")) {
    //   C.toggle = "";
    //   $(id).removeClass('show');
    //   $(id).addClass('collapsed');
    //   $(id).removeClass('collapse');
    // } else {
    //   C.toggle = "show";
    //   $(id).addClass('show');
    //   $(id).addClass('collapse');
    //   $(id).removeClass('collapsed');
    // }
    if (C.toggle == "show") {
      C.toggle = "";
      C.Col = "collapsed";
    } else {
      C.toggle = "show";
      C.Col = "";
    }

    if (level == 1) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ID).forEach(a => a = C)
      sessionStorage.removeItem("ClientList")
      sessionStorage.setItem("ClientList", JSON.stringify(this.clients))

    } else if (level == 2) {
      this.clients.filter(s => s.ID == C.ClientID)[0].AssignRegions.filter(a => a.ID == C.ParentID)[0].SubAssignRegions.filter(s => s.ID == C.ID).forEach(a => a = C)
      sessionStorage.removeItem("ClientList")
      sessionStorage.setItem("ClientList", JSON.stringify(this.clients))

    }

    return C.toggle;
  }


  ActivateRegion(regionID: string) {
    if (this.checkstatusdata(regionID)) {
      if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length == 0) {
        if (regionID != "0") {
          this._restService.Call('POST', API.ReactivateRegion + regionID, null)
            .subscribe(data => {
              this.GetAllAssignedRegion();
              AutoClosingAlert('success', 'Region reactivated Successfully.');
            }
              , error => {
                AutoClosingAlert('danger', 'A problem has been occurred while deleting region.')
              })
        }
      } else {
        //this.modalService.open(this.processalert);
      }
    }
  }

  EditSubRegion(regionID) {
    if (this.checkstatusdata(regionID)) {
      if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length == 0) {
        this._router.navigate(['/DrawSubRegion', regionID])
      } else {
        this.SelectedProcessRegion = this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0)[0]
        //this.modalService.open(this.processalert);

      }
    }
  }

  EditFence(regionID) {
    if (this.checkstatusdata(regionID)) {
      if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length == 0) {
        this._router.navigate(['/EditFence', regionID])
      } else {
        this.SelectedProcessRegion = this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0)[0]
        //this.modalService.open(this.processalert);
      }
    }
  }
  ViewMap(regionID) {
    if (this.checkstatusdata(regionID)) {
      if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length == 0) {
        this._router.navigate(['/UserDashboard', regionID])
      } else {
        this.SelectedProcessRegion = this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0)[0]
        //this.modalService.open(this.processalert);
      }
    }
  }

  public CheckprocessStatus: boolean = true;
  public SelectedProcessRegion = new servicestatus();
  public Progressstatus = new Array<servicestatus>();
  public IsConnect = true;
  public ServiceregionCount = 0;
  public ProcessMessage = "";
  checkstatus() {
    var connectis = this.IsConnect
    this.IsConnect = false
    this._http.put<servicestatus[]>(API.CheckStatus + "?IsConnect=" + connectis, null)
      .subscribe(data => {

        if (data.length != this.ServiceregionCount) {
          this.ServiceregionCount = data.length
          this.IsConnect = true
        }
        this.Progressstatus = data
        if (data.length > 0) {
          if (this.SelectedProcessRegion != null && this.SelectedProcessRegion.RegionName != null) {
            this.ProcessMessage = this.SelectedProcessRegion.RegionName + " is being processed. This may take a few minutes. Do not create new subregions within this region until the process completes. You will be notified when the process is completed."
            this.CheckprocessStatus = true;

            // if(this.ShowPopupAlert==true){
            //   this.ShowPopupAlert=false;
            //   this.modalService.open(this.processalert);
            // }
          }
        }
        else {
          this.ProcessMessage = "The Process has been completed. Now you can perform any action."
          this.CheckprocessStatus = false;
          if (this.SelectedProcessRegion != null && this.SelectedProcessRegion.RegionID > 0) {
            this.modalService.open(this.processalert);
          }
        }
      });
  }
  async checkstatusdata(regionID) {
    var connectis = this.IsConnect
    this.IsConnect = false
    this._restService.Call<servicestatus[]>('POST', API.CheckStatusPost + "?IsConnect=" + connectis, null)
      .subscribe(data => {
        // this._http.put<servicestatus[]>(API.CheckStatus + "?IsConnect=" + connectis, null)
        //   .subscribe(data => {

        if (data.length != this.ServiceregionCount) {
          this.ServiceregionCount = data.length
          this.IsConnect = true
        }
        this.Progressstatus = data

        if (data.length > 0) {
          if (this.SelectedProcessRegion != null && this.SelectedProcessRegion.RegionName != null) {
            this.ProcessMessage = this.SelectedProcessRegion.RegionName + " is being processed. This may take a few minutes. Do not create new subregions within this region until the process completes. You will be notified when the process is completed."
            this.CheckprocessStatus = true;
            if (this.Progressstatus.filter(s => s.RegionID == parseInt(regionID) || s.SubRegions.filter(a => a == parseInt(regionID)).length > 0).length > 0) {
              this.modalService.open(this.processalert);
            }
          }
        }
        else {
          this.ProcessMessage = "The Process has been completed. Now you can perform any action."
          this.CheckprocessStatus = false;
          if (this.SelectedProcessRegion != null && this.SelectedProcessRegion.RegionID > 0) {
            this.modalService.open(this.processalert);
          }
        }

      });
  }

}
