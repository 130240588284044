export class FAQData
{
    Question : string = '';
    Answer : string = '';
    SubFAQ = new Array<FAQData>();
}

export class Document
{
    ID:number;
    Name:string;
    PdfDocument:string;
    htmlDocument:string;
    IsActive:boolean;
    PdfDocumentFile:string;
    htmlDocumentFile:string;
    PdfDocumentFiles:File;
    htmlDocumentFiles:File;
}