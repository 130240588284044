export class Clusters {
    GPSLat: number = 0;
    GPSLong: number = 0;
    ClusterType: string = "";
    Assessment: number = 0;
    AssessmentDate: string = "";
    SignClusterID: string = "";

}
export class RoadClusters {
    GPSLat: number = 0;
    GPSLong: number = 0;
    ClusterType: string = "";
    Assessment: number = 0;
    AssessmentDate: string = "";
    RoadClusterID: string = "";
    AssessmentClusterID: number = 0;
    Category:string="";
}

export class RoadClustersRaw {
    Lt: number = 0;
    Lg: number = 0;
    Ct: string = "";
    As: number = 0;
    Dt: string = "";
    CtID: string = "";
    ID: number = 0;
    CtType:string="";
}

export class ClusterType {
    Name: string;
    Value: string;
    MonthID: number;
    Year: number;
}