import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenModel } from '../_models/TokenModel';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public GetTokenModel=new TokenModel();
  constructor(private _http: HttpClient) { }

  Call<T>(method: string, URL: string, data: object): Observable<T> {
    let headersData = new HttpHeaders();

    if (method === 'TOKEN') {
       headersData = headersData
       .set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
     
    }  else {
      headersData = headersData.set('Content-Type', 'application/json')
      ;
    }

    try {
      switch (method) {
        case 'TOKEN':
          return this._http.post<T>(URL, data.toString(), { headers: headersData });

        case 'POST':
          return this._http.post<T>(URL, data, { headers: headersData });

        case 'GET':
          return this._http.get<T>(URL, { headers: headersData });

        case 'PUT':
          return this._http.put<T>(URL, data, { headers: headersData });

        case 'DELETE':
          return this._http.delete<T>(URL, { headers: headersData });


      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
