import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RegisterNewUserViewModel } from '../_models';
import { RestService } from '../_services';
import { ApplicationUser } from '../_models/ApplicationUser';
import { API } from '../_models/API';
import { Router } from '@angular/router';
import { SecurityQue } from '../_models/SecurityQue';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  securityQues = Array<SecurityQue>();

  constructor(public _restService: RestService,public router:Router,public cdRef : ChangeDetectorRef) { }
  User = new RegisterNewUserViewModel();
  public status = 'fa-eye-slash';
  public type = 'password';
  IsDuplicate = false;
  DuplicateEmail = "";
  
  ngOnInit() {
    this._restService.Call<SecurityQue[]>("GET",API.SecurityQuestions,null)
    .subscribe(res=>{
        this.securityQues = res;
        this.cdRef.detectChanges();
        $("#SecurityQueID").val("")
        return res;
    })
    PasswordStrengthInit('#Password');
  }

  CheckDuplicate(type) {
    if (type == 'Email') {
      this._restService.Call<any>('GET', API.ValidateDuplicate + "?Email=" + this.User.Email, null)
        .subscribe(result => {
          if (result.StatusCode != 200) {
            this.DuplicateEmail = "Email is already exist in the system.";
            this.IsDuplicate = true;

          }
          else {
            this.DuplicateEmail = "";
            this.IsDuplicate = false;
            this.PostUser();
          }

        })
    }

  }
  PostUser() {
    this._restService.Call<ApplicationUser>('POST', API.CreateRobocistAdmin, this.User)
      .subscribe(data => {
        AutoClosingAlert('success', 'Robocist Admin created successfully.');
        this.router.navigate(['/UserList'])

      },
        error => {
          AutoClosingAlert('danger', error);
        })
  }
  getAlert() {
    if (this.status === 'fa-eye-slash') {
      this.status = 'fa-eye';
      this.type = 'text';
    } else {
      this.status = 'fa-eye-slash';
      this.type = 'password';
    }
  }

  CheckPassCreate() {
    if ($('#Password').hasClass('Validpass')) {
      $("#validPass").hide()
      var pass = $('#Password').val();
      var confPas = $("#ConfirmPasswordAdd").val();
      if (pass != '') {
        if (pass == confPas) {
          $("#Checkshow").show();
          $("#Crossshow").hide();
          this.IsDuplicate = false;
        } else {
          $("#Checkshow").hide();
          $("#Crossshow").show();
          this.IsDuplicate = true;
        }
      } else {
        $("#Checkshow").hide();
        $("#Crossshow").hide();
        this.IsDuplicate = true;
      }
    }
    else {
      // $("#Checkshow").hide();
      // $("#Crossshow").show();
      this.IsDuplicate = true;
      $("#validPass").show();
    }
  }
  CheckForPass() {
    if ($('#Password').hasClass('Validpass')) {
      this.IsDuplicate = false;
      $("#validPass").hide()
    } else {
      this.IsDuplicate = true;
      $("#validPass").show();
    }

  }
}
