import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { ApplicationUser } from '../_models/ApplicationUser';
import { Config } from '../_models/Config';
import { RestService } from '../_services';
import { tblMap } from '../_models/tblMap';
import { API } from '../_models/API';
import { TriageRegion } from '../_models/TriageRegion';
import { Client } from '../_models/Client';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})

export class UserDashboardComponent implements OnInit {
  public RegionID = 0;
  public user: ApplicationUser;
  public IsRobocistAdmin = false;
  public maps = Array<tblMap>();
  isLoaded = false;
  public Region = new TriageRegion();
  @ViewChild('content') input;
  clients = Array<Client>();
  public EditRegion = new TriageRegion();

  constructor(private _Activatedroute: ActivatedRoute, private _restService: RestService, private modalService: NgbModal, private cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this._Activatedroute.paramMap.subscribe((params: ParamMap, ) => {
      this.RegionID = parseInt(params.get("RegionID"));
      this.GetRegion();
      sessionStorage.setItem('RegionID', params.get('RegionID'))
      this.user = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;

      if (this.user.RoleId == Config.RobocistAdminRoleId) {
        this.IsRobocistAdmin = true;
      }
    });
    this.GetClientList();
    this.GetMaps();
  }

  GetRegion() {
    this._restService.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Region = data;
      })
  }
  GetClientList() {
    this._restService.Call<Client[]>('GET', API.ClientList, null)
      .subscribe(data => {
        this.clients = data;
      })
  }
  GetMaps() {
    this._restService.Call<tblMap[]>('GET', API.GetMapsClientWise + this.RegionID, null)
      .subscribe(data => {
        this.maps = data;
        this.isLoaded = true;
      })
  }

  EditName() {
    this._restService.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.EditRegion = data;
        this.cdRef.detectChanges();
        this.modalService.open(this.input);
      })
  }

  Submit() {
    var StartDate = new Date(this.EditRegion.StartYear, this.EditRegion.StartMonth - 1);
    var EndDate = new Date();
    var ReleaseDate = new Date();
    if (this.EditRegion.ReleaseMonth != 0 && this.EditRegion.ReleaseYear != 0) {
      ReleaseDate = new Date(this.EditRegion.ReleaseYear, this.EditRegion.ReleaseMonth - 1);
    }

    if (this.EditRegion.EndMonth != 0 && this.EditRegion.EndYear != 0) {
      EndDate = new Date(this.EditRegion.EndYear, this.EditRegion.EndMonth - 1);
      if (EndDate < StartDate) {
        AutoClosingAlert('danger', 'End date should be greater than start date.');
      }
      else if (this.EditRegion.ReleaseMonth != 0 && this.EditRegion.ReleaseYear != 0) {

        if (!(ReleaseDate >= StartDate && ReleaseDate <= EndDate)) {
          AutoClosingAlert('danger', 'Release date should be greater than start date and less than end date.');

        }
        else {
          this.UpdateDomain();
        }
      }
      else if (this.EditRegion.ReleaseMonth != 0 || this.EditRegion.ReleaseYear != 0) {
        if (this.EditRegion.ReleaseMonth == 0 && this.EditRegion.ReleaseYear != 0) {
          AutoClosingAlert('danger', 'Please select release month.');
        }
        else {
          AutoClosingAlert('danger', 'Please select release year.');
        }
      }
      else {
        this.UpdateDomain();
      }
    }
    else if (this.EditRegion.EndMonth != 0 || this.EditRegion.EndYear != 0) {
      if (this.EditRegion.EndMonth == 0 && this.EditRegion.EndYear != 0) {
        AutoClosingAlert('danger', 'Please select end month.');
      }
      else {
        AutoClosingAlert('danger', 'Please select end year.');
      }
    }

    else if (this.EditRegion.ReleaseMonth != 0 && this.EditRegion.ReleaseYear != 0) {
      if (ReleaseDate < StartDate) {
        AutoClosingAlert('danger', 'Release date should be greater than start date.');

      }
      else {
        this.UpdateDomain();
      }
    }
    else if (this.EditRegion.ReleaseMonth != 0 || this.EditRegion.ReleaseYear != 0) {
      if (this.EditRegion.ReleaseMonth == 0 && this.EditRegion.ReleaseYear != 0) {
        AutoClosingAlert('danger', 'Please select release month.');
      }
      else {
        AutoClosingAlert('danger', 'Please select release year.');
      }
    }
    else {
      this.UpdateDomain();
    }

  }

  UpdateDomain() {
    this.EditRegion.StartDate = new Date(this.EditRegion.StartYear, this.EditRegion.StartMonth - 1).toDateString();
    if (this.EditRegion.ReleaseMonth != 0 && this.EditRegion.ReleaseYear != 0) {
      var rdate = new Date(this.EditRegion.ReleaseYear, this.EditRegion.ReleaseMonth, 0).getDate();
      this.EditRegion.ReleaseDate = new Date(this.EditRegion.ReleaseYear, this.EditRegion.ReleaseMonth - 1, rdate).toDateString();
    }
    else {
      this.EditRegion.ReleaseDate = null;
    }
    if (this.EditRegion.EndMonth != 0 && this.EditRegion.EndYear != 0) {
      var date = new Date(this.EditRegion.EndYear, this.EditRegion.EndMonth, 0).getDate();
      this.EditRegion.EndDate = new Date(this.EditRegion.EndYear, this.EditRegion.EndMonth - 1, date).toDateString();
    }
    else {
      this.EditRegion.EndDate = null;
    }
    this._restService.Call<Client>('PUT', API.UpdateClientInDomain, this.EditRegion)
      .subscribe(data => {
        this.modalService.dismissAll();
        AutoClosingAlert('success', 'Domain updated successfully.');
        this.GetRegion();
      })
  }
}
