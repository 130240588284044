import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { ApplicationUser } from '../_models/ApplicationUser';
import { SecurityQue } from '../_models/SecurityQue';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  UserID: string;
  User = new ApplicationUser();
  securityQues = Array<SecurityQue>();
  IsDuplicate: boolean;

  constructor(private _route: ActivatedRoute, public _restService: RestService, private cdRef: ChangeDetectorRef, public router: Router) { }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.UserID = params.get('UserID');
    });
    this._restService.Call<SecurityQue[]>("GET", API.SecurityQuestions, null)
      .subscribe(res => {
        this.securityQues = res;
        this.cdRef.detectChanges();
        this.GetProfile();

      })

  }
  GetProfile() {
    this._restService.Call<ApplicationUser>("GET", API.GetProfileForEdit + this.UserID, null)
      .subscribe(data => {
        this.User = data;
        this.User.ConfirmEmail = this.User.Email;
        if (data.userSecurities.length > 0) {

          this.User.SecurityQueID = data.userSecurities[0].SecurityQuestionID.toString();
          this.User.Answer = data.userSecurities[0].Answer;
        }
        else {
          this.User.SecurityQueID = "";
        }
      })
  }
  onSubmit() {

    this._restService.Call<ApplicationUser>('PUT', API.UpdateProfile, this.User)
      .subscribe(data => {
        if (data != null) {
          AutoClosingAlert('success', 'Profile updated successfully.');
          this.router.navigate(['/Home'])
        }
      },
        error => {
          AutoClosingAlert('danger', error);
        })

  }


  CheckPassCreate() {
    var email = $('#Email').val();
    var confEmail = $("#ConfirmEmail").val();
    if (email != '') {
      if (email == confEmail) {
        $("#Checkshow").show();
        $("#Crossshow").hide();
        this.IsDuplicate = false;
      } else {
        $("#Checkshow").hide();
        $("#Crossshow").show();
        this.IsDuplicate = true;
      }
    } else {
      $("#Checkshow").hide();
      $("#Crossshow").hide();
      this.IsDuplicate = true;
    }

  }


}
