import { Component, OnInit } from '@angular/core';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { Client } from '../_models/Client';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  ID: string;
  Client = new Client();
  constructor(public _restService: RestService, public router: Router, public _route: ActivatedRoute) { }

  ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.ID = params.get('ID');

    });
    this.GetClient();
  }

  onSubmit() {
    if (this.Client.ClientMaps.filter(x => x.IsActive).length > 0) {
      this._restService.Call<Client>('PUT', API.EditClient, this.Client)
        .subscribe(data => {
          if (data != null) {
            AutoClosingAlert('success', 'Client updated successfully.');
            this.router.navigate(['/ClientList'])
          }
        },
          error => {
            AutoClosingAlert('danger', error);
          })
    } else {
      AutoClosingAlert('danger', 'Please select atleast one map.');
    }
  }

  GetClient() {
    this._restService.Call<Client>('GET', API.GetClientEdit + this.ID, null)
      .subscribe(data => {
        this.Client = data;
      },
        error => {
          AutoClosingAlert('danger', error);
        })
  }
}
