
export class tbl_SegmentAssessment {
    assessmentsegmentid:number;
    segmentid: number;
    points: string[];
    middlepoint:string;
    streetname: string;
    wayid: number;
    segmentlength: number;
    segmentpasses: number;
    overallscore: number;
    overallrating: string;
    pavementdistressscore: number;
    pavementdistressrating: string;
    ridequalityscore: number;
    ridequalityrating: string;
    longitudinalcracks: string;
    lateralcracks: string;
    fatiguecracks: string;
    potholes: string;
    repairs: string;
    edgedropoff: string;
    sealedcracks: string;
    bumps: string;
    dipslifts: string;
    roughness: string;
    troublespots: string;
    concentration: string;
    lanelines: string;
    assessmentdatestampstart: string;
    assessmentdatestampend: string;
    StartDate: Date;
    EndDate: Date;
    LongitudinalCracks_cd = new SegmentJson()
    LateralCracks_cd = new SegmentJson()
    FatigueCracks_cd = new SegmentJson()
    Potholes_cd = new SegmentJson()
    Repairs_cd = new SegmentJson()
    Bumps_cd = new SegmentJson()
    DipsLifts_cd = new SegmentJson()
    Roughness_cd = new SegmentJson()
    TroubleSpots_cd = new SegmentJson()
    Concentration_cd:IDictionary<string,TroubleSportRating>;
    ConcentrationList_cd= new Array<TroubleSportRating>();
    Laneline_cd= new LanelineJson();
    lat:string;
    long:string;
    popupimages:string;
    LaneOverallRating:string;
    ImagePopup_cd: IDictionaryImage<string,ImagePopup>;
    BackMonth:number;
    sliderMonth:number;
    sendMonths:number;
    Year:number;
}

export class SegmentJson {
    Avg: string;
    AvgPerMile: number;
    SummaryRating: string;
    OverallScore: number;
    OverallRating: string;
    Severe: number;
    Moderate: number;
    Light: number;
    None: number;
    SevereValue: number;
    ModerateValue: number;
    LightValue: number;
    NoneValue: number;
    ImageLink: string;
    ImageURL: string;
    BaseUrl: string;
    EmptyImage:number;
}
interface IDictionary<T,TroubleSportRating>{
    [Key: string]: T;
}
export class TroubleSportRating {
    SampleCount: number;
    Rating: string;
    ObjectsPerSample: number;

}

interface IDictionaryImage<T,ImagePopup>{
    [Key: string]: T;
}
export class ImagePopup {
    imagelink:string;
    gpslat: string;
    gpslong: string;
    gpslatround:number;
    gpslonground:number;
    ImageURL:string;

}

export class LanelineJson
{
    OverallScore: number;
    OverallRating: string;
    Good:number;
    Fair:number;
    Poor:number;
    Bad:number;
    None:number;
    ImageLink: string;
    ImageURL: string;
}

export class SegmentPopupToolTip{
    Name:string;
    SegmentMap:string;
    RelativeLocationMap:string;
    HeatMap:string;
    SegmentSummary:string;
    ObjectSummary:string;
    ConcentrationofObjects:string;
    PavementDistress:string;
    RideQuality:string;
  }

  export class tbl_segmentdatadensity{
    assessmentsegmentid:number;
    segmentid:number;
    points: string[];
    StartDate:Date;
    segmentdatadensity:number
  }