import { Component, OnInit} from '@angular/core';
// import * as L from 'node_modules/leaflet';
// import 'leaflet.markercluster';
// import { RestService } from '../_services/rest.service';
// import { API } from '../_models/API';
// import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { GeoData, InsideMapPoint } from '../_models/GeoData.js';
// import { LatLng } from 'leaflet';

// // import * as Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import { Chart, ChartOptions } from 'chart.js';
// import { tbl_SegmentAssessment } from '../_models/tbl_SegmentAssessment';
// import "../../assets/dist/leaflet.groupedlayercontrol.css";
// import "../../assets/dist/leaflet.groupedlayercontrol.js";
// import "../../assets/dist/exampledata.js";
// import { tbl_AssessmentCluster } from '../_models/tbl_AssessmentCluster';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';

@Component({
  selector: 'app-segment-pdf',
  templateUrl: './segment-pdf.component.html',
  styleUrls: ['./segment-pdf.component.css']
})
export class SegmentPDFComponent implements OnInit {


  // popupsegment = new tbl_SegmentAssessment();
  // public Type="" 
  // segment = new tbl_SegmentAssessment();
  
  constructor(  ) {

   }


//   chkBoundingBox = true;
//   Pavementroi = "pavement-with-roi"
//   segmentid:number;
//   public ImageCDN = API.ImageCDN+"/";
//   wayid:number;
//   StartDate:string;
   ngOnInit() {
//     this._Activatedroute.paramMap.subscribe((params: ParamMap,) => {
//       this.segmentid = parseInt(params.get('segmentid'));
//       this.wayid = parseInt(params.get('WayID'));
//       this.Type = params.get('Type');
//     });
//     this.MarkerClick()
  }
//   MarkerClick() {
   
//    this.segment = JSON.parse(sessionStorage.getItem('segment')) as tbl_SegmentAssessment;
//    var segment=this.segment


//     this._restservice.Call<tbl_SegmentAssessment>('GET', API.SegmentPopup + this.segmentid + "?Type="+this.Type+ "&WayID=" + this.wayid + "&StartDate=" +  this.segment.StartDate, null)
//       .subscribe(data => {
//         this.popupsegment = data;
//         var length = (this.popupsegment.points.length / 2).toFixed(0)
//         this.popupsegment.middlepoint = this.popupsegment.points[length]
//         this.popupsegment.middlepoint = this.popupsegment.middlepoint.replace('(', '')
//         this.popupsegment.middlepoint = this.popupsegment.middlepoint.replace(')', '')
//         this.popupsegment.lat = parseFloat(this.popupsegment.middlepoint.split(',')[0]).toFixed(6)
//         this.popupsegment.long = parseFloat(this.popupsegment.middlepoint.split(',')[1]).toFixed(6)

//         this.popupsegment = data
    
//         this.OtherMap()
//         var Light = 0;
//         var Severe = 0;
//         var Moderate = 0;
//         var None = 0;
//         if ( this.segment.ConcentrationList_cd != null &&  this.segment.ConcentrationList_cd != undefined) {
//           let result =  this.segment.ConcentrationList_cd.map(function (a) { return a.ObjectsPerSample; });
//           if ( this.segment.ConcentrationList_cd.length > 0) {
//             var maxcount = result.reduce((a, b) => Math.max(a, b));
//             for (let i = maxcount - 1; i >= 0; i--) {
//               if ( this.segment.ConcentrationList_cd[i] != undefined) {
            
               
//                 if ( this.segment.ConcentrationList_cd[i].Rating == "Light") {
//                   Light +=  this.segment.ConcentrationList_cd[i].ObjectsPerSample;
//                 } else if ( this.segment.ConcentrationList_cd[i].Rating == "Severe") {
//                   Severe +=   this.segment.ConcentrationList_cd[i].ObjectsPerSample;
//                 } else if ( this.segment.ConcentrationList_cd[i].Rating == "Moderate") {
//                   Moderate += this.segment.ConcentrationList_cd[i].ObjectsPerSample;
//                 } else//Good
//                 {
//                   None +=  this.segment.ConcentrationList_cd[i].ObjectsPerSample;
//                 }
              

//               }
//             }
//           }
//         }
//         let optionsTroble: ChartOptions = {
//           responsive: true,
//           maintainAspectRatio: true,
//           legend: {
//             display: false,
//           },
//           tooltips: {
//             enabled: false
//           },
//           scales: {
//             yAxes: [{
//               ticks: {
//                 fontColor: "#040404",
//                 beginAtZero: true
//               },

//             }],
//             xAxes: [{
//               ticks: {
//                 fontColor: "#040404",
//                 beginAtZero: true
//               }

//             }]
//           },
//           title: {
//             display: true,
//             text: 'Sample Count',
//             position: 'bottom'
//           },
//           plugins: {
//             datalabels: {
//               formatter: (value, ctx) => {
//                 var dataArr = ctx.chart.data.datasets[0].data as number[];
//                 let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
//                 if (total == 0) {
//                   return "0%";
//                 } else {
//                   let percentage = (value * 100 / total).toFixed(2) + "%";
//                   return percentage;
//                 }
//               },
//               color: 'black',
//               align: 'right',
//               anchor: 'start',
//               textAlign: 'center'
//             }
//           }
//         };
//         let optionsPeavment: ChartOptions = {
//           responsive: true,
//           maintainAspectRatio: true,
//           legend: {
//             display: false,
//           },
//           tooltips: {
//             enabled: false
//           },
//           scales: {
//             yAxes: [{
//               ticks: {
//                 display: false
//               }
//               // ticks: {
//               //   fontColor:"#040404",
//               //   beginAtZero: true
//               // }
//             }],
//             xAxes: [{
//               ticks: {
//                 fontColor: "#040404",
//                 beginAtZero: true
//               }
//             }]
//           },
//           title: {
//             display: true,
//             text: 'Percentage of Images',
//             position: 'bottom'
//           },
//           plugins: {
//             datalabels: {
//               formatter: (value, ctx) => {
//                 //   var dataArr = ctx.chart.data.datasets[0].data as number[];
//                 //   let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
//                 //   if(total==0){
//                 //     return "0%";
//                 //   } else{   
//                 //   let percentage=((value/total)*100).toFixed(0) +"%" 
//                 //  // let percentage = (value * 100 / total).toFixed(2) + "%";
//                 //   return percentage;
//                 // }
//                 return value + "%";
//               },
//               color: 'black',
//               align: 'right',
//               anchor: 'start',
//               textAlign: 'center'
//             }
//           }
//         };
//         let optionsRide: ChartOptions = {
//           responsive: true,
//           maintainAspectRatio: true,
//           legend: {
//             display: false,
//           },
//           tooltips: {
//             enabled: false
//           },
//           scales: {
//             yAxes: [{
//               ticks: {
//                 display: false
//               }
//               // ticks: {
//               //   fontColor:"#040404",
//               //   beginAtZero: true
//               // }
//             }],
//             xAxes: [{
//               ticks: {
//                 fontColor: "#040404",
//                 beginAtZero: true,
//               }
//             }]
//           },
//           title: {
//             display: true,
//             text: 'Percentage of Samples',
//             position: 'bottom'
//           },
//           plugins: {
//             datalabels: {
//               formatter: (value, ctx) => {
//                 //   var dataArr = ctx.chart.data.datasets[0].data as number[];
//                 //   let total = dataArr.reduce((acc, cur) => acc + Number(cur), 0);   // sum from lodash   
//                 //   if(total==0){
//                 //     return "0%";
//                 //   } else{   
//                 //   let percentage=((value/total)*100).toFixed(0) +"%" 
//                 //  // let percentage = (value * 100 / total).toFixed(2) + "%";
//                 //   return percentage;

//                 // }

//                 return value + "%";
//               },
//               color: 'black',
//               align: 'right',
//               anchor: 'start',
//               textAlign: 'center'
//             }
//           }
//         };
     
        
//         var crt = document.getElementById('myChartTrouble') as HTMLCanvasElement;
//         crt.height = 200;
//         new Chart(crt, {
//           plugins: [ChartDataLabels],
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [Severe, Moderate, Light, None],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],
//               borderWidth: 1,
//               barPercentage: 0.4,
//               barThickness: 18,  // number (pixels) or 'flex'
//               maxBarThickness: 18 // number (pixels)
//             }]
//           },
//           options:
//             optionsTroble,

//         });

//         //Longitudinal Cracks
//         var canvasRoadchart1 = document.getElementById('canvasRoadchart1') as HTMLCanvasElement;
//         canvasRoadchart1.height = 170;
//         new Chart(canvasRoadchart1, {
//           plugins: [ChartDataLabels],
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [ this.segment.LongitudinalCracks_cd.SevereValue,  this.segment.LongitudinalCracks_cd.ModerateValue,  this.segment.LongitudinalCracks_cd.LightValue,  this.segment.LongitudinalCracks_cd.NoneValue],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],

//               borderWidth: 1
//             }]
//           },
//           options: optionsPeavment
//         });

//         //Lateral Cracks
//         var canvasRoadchart2 = document.getElementById('canvasRoadchart2') as HTMLCanvasElement;
//         canvasRoadchart2.height = 170;
//         new Chart(canvasRoadchart2, {
//           plugins: [ChartDataLabels],
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [ this.segment.LateralCracks_cd.SevereValue,  this.segment.LateralCracks_cd.ModerateValue,  this.segment.LateralCracks_cd.LightValue,  this.segment.LateralCracks_cd.NoneValue],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],
//               borderWidth: 1
//             }]
//           },
//           options: optionsPeavment
//         });

//         //Fatigue Cracks
//         var canvasRoadchart3 = document.getElementById('canvasRoadchart3') as HTMLCanvasElement;
//         canvasRoadchart3.height = 170;
//         new Chart(canvasRoadchart3, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [ this.segment.FatigueCracks_cd.SevereValue,  this.segment.FatigueCracks_cd.ModerateValue,  this.segment.FatigueCracks_cd.LightValue,  this.segment.FatigueCracks_cd.NoneValue],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],
//               borderWidth: 1
//             }]
//           },
//           options: optionsPeavment,
//           plugins: [ChartDataLabels],
//         });

//         //Potholes
//         var canvasRoadchart4 = document.getElementById('canvasRoadchart4') as HTMLCanvasElement;
//         canvasRoadchart4.height = 170;
//         new Chart(canvasRoadchart4, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [ this.segment.Potholes_cd.SevereValue,  this.segment.Potholes_cd.ModerateValue,  this.segment.Potholes_cd.LightValue,  this.segment.Potholes_cd.NoneValue],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],
//               borderWidth: 1
//             }]
//           },
//           options: optionsPeavment,
//           plugins: [ChartDataLabels],
//         });

//         //Patches
//         var canvasRoadchart5 = document.getElementById('canvasRoadchart5') as HTMLCanvasElement;
//         canvasRoadchart5.height = 170;
//         new Chart(canvasRoadchart5, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [segment.Repairs_cd.SevereValue, segment.Repairs_cd.ModerateValue, segment.Repairs_cd.LightValue, segment.Repairs_cd.NoneValue],
//               backgroundColor: [
//                 '#ff6747', '#FE9903', '#FFFF00', '#70AD47'
//               ],
//               borderWidth: 1
//             }]
//           },
//           options: optionsPeavment,
//           plugins: [ChartDataLabels],
//         });

//         //Bumps
//         var canvaschart1 = document.getElementById('canvaschart1') as HTMLCanvasElement;
//         new Chart(canvaschart1, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [segment.Bumps_cd.SevereValue, segment.Bumps_cd.ModerateValue, segment.Bumps_cd.LightValue, segment.Bumps_cd.NoneValue],
//               backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
//               borderWidth: 1
//             }]
//           },
//           options: optionsRide,
//           plugins: [ChartDataLabels],
//         });

//         //Dips/Lifts
//         var canvaschart2 = document.getElementById('canvaschart2') as HTMLCanvasElement;
//         new Chart(canvaschart2, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [segment.DipsLifts_cd.SevereValue, segment.DipsLifts_cd.ModerateValue, segment.DipsLifts_cd.LightValue, segment.DipsLifts_cd.NoneValue],
//               backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
//               borderWidth: 1
//             }]
//           },
//           options: optionsRide,
//           plugins: [ChartDataLabels],
//         });

//         //Rough Spots
//         var canvaschart3 = document.getElementById('canvaschart3') as HTMLCanvasElement;
//         new Chart(canvaschart3, {
//           type: 'horizontalBar',
//           data: {
//             labels: ['SEVERE', 'MODERATE', 'LIGHT', 'NONE'],
//             datasets: [{
//               data: [segment.Roughness_cd.SevereValue, segment.Roughness_cd.ModerateValue, segment.Roughness_cd.LightValue, segment.Roughness_cd.NoneValue],
//               backgroundColor: ['#ff6747', '#FE9903', '#FFFF00', '#70AD47'],
//               borderWidth: 1
//             }]
//           },
//           options: optionsRide,
//           plugins: [ChartDataLabels],
//         });
//       });
//   }

//   OtherMap(){
//       document.getElementById('map1').innerHTML = "<div id='Pavementmap1' style='width:335px;height:335px'></div>";
//       var Pavementmap1=L.map('Pavementmap1', {   dragging: false,
//         scrollWheelZoom: 'center',
//         center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13});
//       L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
//         attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
//         maxZoom: 19,
//         minZoom:13
//       }).addTo(Pavementmap1)
  
//       document.getElementById('map2').innerHTML = "<div id='Pavementmap2' style='width:335px;height:335px'></div>";
//       var Pavementmap2=L.map('Pavementmap2', {   dragging: false,
//         scrollWheelZoom: 'center',
//         center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 13});
//       L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
//         attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
//         maxZoom: 19,
//         minZoom:13
//       }).addTo(Pavementmap2)
//    // })
  
//     var point = new Array<LatLng>();
//         this.popupsegment.points.forEach(p => {
//           p = p.replace('(', '')
//           p = p.replace(')', '')
//           var lat = parseFloat(p.split(",")[0]);
//           var long = parseFloat(p.split(",")[1]);
  
//           point.push(new L.LatLng(lat, long));
//         });
//         var polylinePoints = [
//           point
//           //[35.9018825,-78.6576104],[35.9017232,-78.6568122],[35.9016387,-78.6563699]
//         ];
//         var firstpolyline = new L.polyline(polylinePoints, {
//           color: "#3333ff",
//           weight: 4,
//           opacity: 1,
//           smoothFactor: 1
//         })
//         var firstpolyline1 = new L.polyline(polylinePoints, {
//           color: "#3333ff",
//           weight: 4,
//           opacity: 1,
//           smoothFactor: 1
//         })
//       firstpolyline.addTo(Pavementmap2);
//       firstpolyline1.addTo(Pavementmap1);
//       L.marker([this.popupsegment.lat, this.popupsegment.long]).addTo(Pavementmap2);
//     this.HeatMap()
//   }

// public points = Array<InsideMapPoint>();
//   HeatMap(){
   
//     var isPrevious = false;
//     if ( this.segment.BackMonth > 1) {
//       isPrevious = true;
//     }
 
    
//     this._restservice.Call<tbl_AssessmentCluster[]>('GET', API.SegmentPopupHeatmap + this.popupsegment.segmentid +"/"+this.Type+"?MonthIDs="+this.segment.sendMonths+"&Year=" + $('#YearID').val() +
//     "&IsPrevious=" + isPrevious + "&BackMonth=" + (this.segment.BackMonth - 1), null)
//     .subscribe(data => {
//       document.getElementById('map3').innerHTML = "<div id='Pavementmap3' style='width:335px;height:335px'></div>";
//       var Pavementmap3=L.map('Pavementmap3', { center: new L.LatLng(this.popupsegment.lat, this.popupsegment.long), zoom: 12});
//       L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
//         attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> ',
//         maxZoom: 19,
//         minZoom:10
//       }).addTo(Pavementmap3)
//       var len = data.length;
//       this.points = Array<InsideMapPoint>();
//       for (let i = 0; i < len; i++) {
//         var point = new InsideMapPoint();
//         var item = data[i];

//         point.lat = item.gpslat;
//         point.lng = item.gpslong;
//         point.value = item.clusterdatadensity;
//         this.points.push(point);
//       }
//       L.heatLayer(this.points,{
//         radius: 20,
//         blur: 15, 
//         maxZoom: 10,
//         max: 4.0,
//           gradient: {
//             0.2: '#FFFF00',
//       0.4: '#FE9903',
//       0.6: '#fd8d3c',
//       0.8: '#f03b20',
//       1: '#ff6747'
// }
//     }).addTo(Pavementmap3);
   
//     })
//   }

//   exportToPdf(){
//     // let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
//     // // var pdf = new jsPDF('p', 'pt',  [imgWidth, pageHeight]);
//     // var imgWidth = 208;
//     // var pageHeight = 295;
//     // // var imgWidth = 1345;
//     // // var pageHeight = 2041.5;
//     // var position = 0;

//     // var data = document.getElementById('pdfPrakrutiId');
//     // var data2 = document.getElementById('RideCharacteristics');
//     // html2canvas(data).then(canvas => {
//     //   var imgHeight = canvas.height * imgWidth / canvas.width;
       
//     //   const contentDataURL = canvas.toDataURL('image/png')
//     // // Few necessary setting options
//     // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//     // pdf.save('MYPdf.pdf'); // Generated PDF
//     // });
//     //     var data = document.getElementById('maindiv');
//     //     html2canvas(data).then(canvas => {
//     //     // Few necessary setting options
//     //     var imgWidth = 208;
//     //     var pageHeight = 295;
//     //     var imgHeight = canvas.height * imgWidth / canvas.width;
//     //     var heightLeft = imgHeight;
        
//     //     const contentDataURL = canvas.toDataURL('image/png')
//     //     let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
//     //     var position = 0;
//     //     pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
//     //     pdf.save('MYPdf.pdf'); // Generated PDF
//     // });
//     this.getPDF()
//     }
 
//    getPDF(){
    
//       var HTML_Width =$("#maindiv").width();
//       var HTML_Height = $("#maindiv").height();
//       var top_left_margin = 15;
//       // var PDF_Width =HTML_Width+(top_left_margin*2) ;
//       // var PDF_Height =(PDF_Width*1.5)+(top_left_margin*2);
//       var PDF_Width =HTML_Width+(top_left_margin*2) ;
//       var PDF_Height =(PDF_Width*1.5)+(top_left_margin*2);
//       var canvas_image_width = HTML_Width;
//       var canvas_image_height = HTML_Height;
//       var data = document.getElementById('maindiv');
//       var data2 = document.getElementById('RideCharacteristics')
//      // var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
//       var pdf = new jsPDF('portrait', 'pt',  [PDF_Width, PDF_Height],true);
//       var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
 
//       html2canvas(data,{
//          logging:true,
//          allowTaint:true,
//         useCORS: true }).then(canvas => {
//           var img = new Image();
//           img.onload = function() {
//             document.body.appendChild(img);
//           };

//         var imgData = canvas.toDataURL("image/png");
//           pdf.addImage(imgData, 'JPEG', 0, top_left_margin,canvas_image_width,canvas_image_height+10);
//           pdf.addPage( [PDF_Width, PDF_Height], 'p');
//          // alert(((PDF_Height*1)+(top_left_margin*2)))//2086.5
//           pdf.addImage(imgData, 'JPEG', 0, -((PDF_Height*1)+(top_left_margin*2)),canvas_image_width,canvas_image_height+10);
//           pdf.save("PavementAssessment.pdf");
//           });

//     };



}
