import { Component, OnInit, ViewChild } from '@angular/core';
import { GeoData, GeoPoint, GeoPointInside, InsideMapPoint } from '../_models/GeoData';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { RestService } from '../_services';
import { TriageRegion, servicestatus } from '../_models/TriageRegion';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { API } from '../_models/API';
import { LatLng } from 'leaflet';
import * as L from 'node_modules/leaflet';
import circleToPolygon from 'circle-to-polygon';
import * as esriGeoCoder from 'esri-leaflet-geocoder'
import '../../assets/dist/leaflet-heat'

@Component({
  selector: 'app-draw-sub-region',
  templateUrl: './draw-sub-region.component.html',
  styleUrls: ['./draw-sub-region.component.css']
})
export class DrawSubRegionComponent implements OnInit {
  public srcTiles: string;
  public height: string;
  public Fences = Array<GeoData>();
  public DrawnFences = Array<GeoData>();
  public UpdatedDrawnFences = Array<GeoData>();
  public submitted: boolean = false;

  public RegionID: number = 0;
  public map: any;
  private featureGroup: any;
  Level: number = 0;
  @ViewChild('outsidecontent') input;
  @ViewChild('content') saveinput;
  public points = Array<InsideMapPoint>();

  private triageRegion = new TriageRegion();
  public constructor(private restservice: RestService, private _route: ActivatedRoute, private modalService: NgbModal, private router: Router) {
    this.height = window.innerHeight + "px";
  }
  ngOnInit() {
    this.srcTiles = 'https://tile.osm.org/{z}/{x}/{y}.png'
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.RegionID = parseInt(params.get('ID'));

    });

  }
  ngAfterViewInit() {
   
    this.map = L.map("map", {
      center: [35.779591, -78.638176],
      zoom: 13,
     // layers: [L.tileLayer(this.srcTiles, { attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' })],
      zoomControl: true
    });
    var osm = L.tileLayer(this.srcTiles,{
      attribution: '&copy; Robocist Inc., &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
     maxZoom: 19
   }).addTo(this.map),
    mqi =L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
      attribution: '&copy; Robocist Inc.',
      maxZoom: 19,
      subdomains:['mt0','mt1','mt2','mt3']
  });
  
  var baseMaps = {
    "Street": osm,
    "Satellite": mqi
  };
  
  var overlays =  {//add any overlays here
    };
  
  L.control.layers(baseMaps,overlays, {position: 'topleft'}).addTo(this.map);
  
  
    this.featureGroup = L.featureGroup().addTo(this.map);
    new L.Control.Draw({
      edit: {
        featureGroup: this.featureGroup
      }
    }).addTo(this.map);
    var searchControl = esriGeoCoder.geosearch().addTo(this.map);
    var results = L.layerGroup().addTo(this.map);
    searchControl.on('results', function (data) {
      results.clearLayers();
      for (var i = data.results.length - 1; i >= 0; i--) {
        results.addLayer(L.marker(data.results[i].latlng));
      }
    });
    var self = this;
    this.map.on('draw:created', function (e) {
      var latlngs: any;
      if (e.layer instanceof L.Circle) {
        var latlng = e.layer.getLatLng();
        var radiusrecent = e.layer.getRadius();
        var Circlelatlng = L.latLng(latlng.lat, latlng.lng);
        var bounds = Circlelatlng.toBounds(radiusrecent * 2);
        var North = new LatLng(bounds.getNorth(), latlng.lng);
        var South = new LatLng(bounds.getSouth(), latlng.lng);
        var East = new LatLng(latlng.lat, bounds.getEast());
        var West = new LatLng(latlng.lat, bounds.getWest());


        const coordinates = [latlng.lng, latlng.lat];
        const radius = radiusrecent;
        const numberOfEdges = 64;

        let polygon = circleToPolygon(coordinates, radius, numberOfEdges);
        let a = (polygon.coordinates as unknown as L.LatLngExpression[])[0] as unknown as GeoPoint[];
        var geodata = Array<GeoPoint>();
        a.map(value => {
          var points = new GeoPoint();
          points.lat = value[1];
          points.lng = value[0];
          geodata.push(points);

        });
        latlngs = geodata;

      }
      else {
        latlngs = (e.layer.getLatLngs())[0];
      }
      var inside = false;
      var geopoints = Array<GeoPointInside>();
      latlngs.map(value => {
        var data = new GeoPointInside;
        data.lat = value.lat;
        data.lng = value.lng;

        if (data.inside == false) {
          data.inside = self.isMarkerInsideRegion(data.lat, data.lng);
          geopoints.push(data);
        }
      })
      if (geopoints.filter(x => x.inside == false).length > 0) {
        if (self.Level == 1) {
          AutoClosingAlert('danger', 'Region must be inside the domain.');
        } else if (self.Level == 2) {
          AutoClosingAlert('danger', 'Sub-region must be inside the region.')
        }
      }
      else {
        self.featureGroup.addLayer(e.layer);
      }
    });
    this.restservice.Call<any[]>('GET', API.ListOfHeatmap + "?MonthID=0&Year=0&RegionID=" + this.RegionID+"&BackMonth=0", null)
    .subscribe(data => {
      var len = data.length;
        for (let i = 0; i < len; i++) {
          var point = new InsideMapPoint();
          var item = data[i];

          point.lat = item.Lt;
          point.lng = item.Lg;
          point.value = item.Cd;
          this.points.push(point);
        }
        L.heatLayer(this.points).addTo(self.map);
    })
    this.RetriveGeoFence();

  }

  RetriveGeoFence() {
    var self = this;
    this.restservice.Call<TriageRegion>('GET', API.GetTriageRegion + "?ID=" + this.RegionID, null)
      .subscribe(data => {
        this.Level = data.Level;
        var Data = JSON.parse(data.GeoJson) as unknown as GeoData[];

        var subRegions = data.subTriageRegions;

        Data.map(value => self.DrawnFences.push(value));
        subRegions.map(subdata => {
          var geoFence = JSON.parse(subdata.GeoJson) as unknown as GeoData[];
          geoFence.map(value => self.DrawnFences.push(value));
        })


        self.DrawnFences.forEach((element, index) => {

          if (element.type == 'Point') {
            var latlongcircle = element.coordinates as unknown as LatLng;

            var circle = L.circle([latlongcircle[0].lat, latlongcircle[0].lng], element.radius);
            circle.addTo(this.map);
            if (index == 0) {
              self.map.fitBounds(circle.getBounds());
            }
          }
          else if (element.type == 'Rectangle') {

            var latlongrect = element.coordinates as unknown as L.LatLngBounds;
            L.rectangle(latlongrect).addTo(this.map);

            if (index == 0) {
              self.map.fitBounds(latlongrect);
            }
          }
          else if (element.type == 'Polygon') {
            var latlongpoly = element.coordinates as unknown as L.LatLngExpression[] | L.LatLngExpression[][] | L.LatLngExpression[][][];
            L.polygon(latlongpoly).addTo(this.map);

            if (index == 0) {
              self.map.fitBounds(latlongpoly);;
            }
          }
        });

      })
  }


  isMarkerInsideRegion(x, y) {
    var polyPoints: GeoPoint[];
    var inside = false;

    this.DrawnFences.map(value => {
      polyPoints = value.coordinates;
      if (inside == false) {
        if (value.type == 'Point') {
          inside = this.IsMarkerInsideCircle(x, y, polyPoints[0].lat, polyPoints[0].lng, value.radius);
        }
        else {

          for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
            var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
            var xj = polyPoints[j].lat, yj = polyPoints[j].lng;

            var intersect = ((yi > y) != (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
          }
        }
      }
    });
    return inside;
  };

  IsMarkerInsideCircle(x, y, Lat, Lng, Radius) {
    var inside = false;
    var i = x - Lat;
    var e = y - Lng;
    var Fromlatlng = new LatLng(x, y);
    var ToLatLng = new LatLng(Lat, Lng);

    var distance = Fromlatlng.distanceTo(ToLatLng);
    if (distance <= Radius)
      inside = true;
    return inside;
  }

  Export() {

    var features = [];
    var self = this;
    self.Fences = Array<GeoData>();

    this.featureGroup.eachLayer(function (layer) {
      var fence = new GeoData();
      var data = Array<GeoPoint>();
      if (layer instanceof L.Circle) {
        data.push(layer.getLatLng() as unknown as GeoPoint);
        fence.coordinates = data;
        fence.radius = layer.getRadius();
        fence.type = 'Point';
      }
      else if (layer instanceof L.Rectangle) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Rectangle';
      }
      else if (layer instanceof L.Polygon) {
        fence.coordinates = layer.getLatLngs() as unknown as GeoPoint[];
        fence.radius = 0;
        fence.type = 'Polygon';
      }

      if (fence.type != '') {
        if (self.DrawnFences.filter(x => x.type == fence.type && x.radius == fence.radius && JSON.stringify(x.coordinates) == JSON.stringify(fence.coordinates)).length == 0) {
          self.Fences.push(fence);
        }
      }
    });

    if (this.Fences.length > 0) {
      this.triageRegion.GeoJson = JSON.stringify(this.Fences);
      this.triageRegion.GeoJson = this.triageRegion.GeoJson.split("[[").join("[");
      this.triageRegion.GeoJson = this.triageRegion.GeoJson.split("]]").join("]");
      this.triageRegion.CreatedDateTime = new Date() as unknown as string;
      this.triageRegion.ParentID = this.RegionID;
      this.modalService.open(this.saveinput);
    }
    else {
      this.modalService.open(this.saveinput);
    }

  }

  onSubmit() {
    this.modalService.dismissAll();
    this.submitted = true;
    this.triageRegion.CreatedDateTime = new Date() as unknown as string;
    this.restservice.Call<servicestatus[]>("PUT",API.CheckStatus,null)
    .subscribe(data => {
    if(data.filter(s=>s.RegionID==this.RegionID && s.SubRegions.filter(a=>a==this.RegionID).length>0).length>0){
      AutoClosingAlert('danger', 'Data is processing in backend please do not add region.');
    }
    else{
        this.restservice.Call("POST", API.CreateSubTriageRegion, this.triageRegion)
          .subscribe(obj => { 
          this.router.navigate(['/Home'])
        },
          error => {
            AutoClosingAlert('danger', error);
          })
    }
  });
  }
}