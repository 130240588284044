import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthenticationService } from '../_services/authentication.service';
import { Config } from '../_models/Config';
import { API } from '../_models/API';
import { TokenModel } from '../_models/TokenModel';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private http: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentToken = this.authenticationService.currentTokenValue;
        if (currentToken && currentToken.access_token) {
           
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentToken.access_token}`
                    }
                });
            }
        

        return next.handle(request);
    }
}