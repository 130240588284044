import { Component, OnInit, Renderer2, Inject, TemplateRef, ViewChild } from '@angular/core';
import { RegisterNewUserViewModel } from '../_models/RegisterViewModel';
import { Router, ParamMap, ActivatedRoute } from '@angular/router'
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/platform-browser';
import { RestService } from '../_services/rest.service';
import { API } from '../_models/API';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../_services/authentication.service';
import { LoginViewModel } from '../_models';
import { first } from 'rxjs/operators';
import { SecurityQue } from '../_models/SecurityQue';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {
  public registerModel = new  RegisterNewUserViewModel();
  modalRef: BsModalRef;
  public userid;
  public code;
  public DisplayEmail;
  IsDuplicate = false;
  public securityQues = Array<SecurityQue>();
  DuplicateEmail = "";
  DuplicatePhonenumber = "";
  public status = 'fa-eye-slash';
  public type = 'password';
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document,
    private _route: ActivatedRoute,
    private _restservice: RestService,
    private modalService: BsModalService) { }

  isSignError: boolean = false;
  submitted = false;
  error = '';
  ngOnInit() {
    $("form").attr('autocomplete', 'off');
    this._restservice.Call<SecurityQue[]>("GET",API.SecurityQuestions,null)
    .subscribe(res=>{
        this.securityQues = res;
        return res;
    })
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.userid = params.get('userId');
      this.code = params.get('code');
      this._restservice.Call<RegisterNewUserViewModel>('GET', API.GetSignupDetailfromcode + "?userId=" + this.userid + "&code=" + this.code, null)
      .subscribe(data => {
        if (data != null) {
          this.registerModel.Id = data.Id;
          this.registerModel.Email = data.Email;
          $("#SecurityQueID").val("")
        }
      });
    });
   
    PasswordStrengthInit('#Password');
    
  }
  CheckPassCreate() {
    if($('#Password').hasClass('Validpass')){
      $("#validPass").hide()
    var pass = $('#Password').val();
    var confPas = $("#ConfirmPasswordAdd").val();
    if (pass != '') {
      if (pass == confPas) {
        $("#Checkshow").show();
        $("#Crossshow").hide();
        this.IsDuplicate=false;
      } else {
        $("#Checkshow").hide();
        $("#Crossshow").show();
        this.IsDuplicate=true;
      }
    } else {
      $("#Checkshow").hide();
      $("#Crossshow").hide();
      this.IsDuplicate=true;
    }
  }
  else{
    // $("#Checkshow").hide();
    // $("#Crossshow").show();
    this.IsDuplicate=true;
    $("#validPass").show();
  }
  }
  CheckForPass(){
    if($('#Password').hasClass('Validpass')){
      this.IsDuplicate=false;
      $("#validPass").hide()
    }else{
      this.IsDuplicate=true;
     $("#validPass").show();
    }
    
  }
  onSubmit() {

    this._restservice.Call<any>('POST', API.SignUp, this.registerModel)
      .subscribe(data => {
            if (data != null) 
            {
                var loginModel = new LoginViewModel();
                loginModel.Username =  this.registerModel.Email;
                loginModel.Password = this.registerModel.Password;
                this.authenticationService.login(loginModel).pipe(first())
                            .subscribe(token => {
                                    if (token != null) {
                                        return this.UserData();
                                    }
                                    });
              
            }
      })

  }

  closePopup() {
    // $.AutoClosingAlert({Type:'success'});
    this.modalRef.hide();
    this.router.navigate(['/SignIn']);
  }

  UserData() {
    return this.authenticationService.UserFromToken()
        .subscribe(userInfo => {

          this.router.navigate([`/Home`])

            return 'Success'
        })
 
}

CheckDuplicate(type){
  if(type == 'Email'){
    this._restservice.Call<any>('GET',API.ValidateDuplicate +"?Email="+this.registerModel.Email,null)
    .subscribe(result=>{
      if(result != true){
           this.DuplicateEmail = result;
           this.IsDuplicate = true;
      }else{
        this.DuplicateEmail = "";
        if(this.DuplicatePhonenumber == "")
        this.IsDuplicate = false;
      }
           return false;
    })
  }

}

FocusIn(type){
  if(type=='Email'){
    this.DuplicateEmail = "";
  }
}
  getAlert() {
    if (this.status === 'fa-eye-slash') {
      this.status = 'fa-eye';
      this.type = 'text';
    } else {
      this.status = 'fa-eye-slash';
      this.type = 'password';
    }
  }
}
