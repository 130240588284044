import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../_services';
import { API } from '../_models/API';
import { UserLogin } from '../_models/UserLogin';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { UserLogActivity } from '../_models/UserLogActivity';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReassignOwner } from '../_models/ReassignOwner';
import { ApplicationUser } from '../_models/ApplicationUser';
import { Config } from '../_models/Config';
import { Client } from '../_models/Client';
import { TriageRegion } from '../_models/TriageRegion';

@Component({
  selector: 'app-user-log',
  templateUrl: './user-log.component.html',
  styleUrls: ['./user-log.component.css']
})
export class UserLogComponent implements OnInit {
  userLogs = Array<UserLogin>();
  @ViewChild('content') input;
  reassignOwner = new ReassignOwner();
  domainAdmins = new Array<ApplicationUser>();
  domainList = new Array<TriageRegion>();
  dtTrigger: Subject<any> = new Subject();
  public Date: Date = new Date();
  public IsTiggered = false;
  User: ApplicationUser;
  constructor(public _restService: RestService, private modalService: NgbModal) { }
  dtOptions: any = [];
  clientList = new Array<Client>();
  today: Date = new Date();
  start: Date = new Date(this.today.setMonth(new Date().getMonth() - 1));
  end: Date = new Date();
  RobocistAdmin = false;
  ClientID = 0;
  DomainID = 0;
  date: string = "";
  ngOnInit() {
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 2,
      title: 'User Logs',
      responsive: true,
      searching: true,
      info: true,
      lengthChange: true,
      ordering: true,
      destroy: true
    };
    this.User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    if (this.User.RoleId == Config.RobocistAdminRoleId) {
      this.RobocistAdmin = true;
    }
  }

  ngAfterViewInit() {
    this.GetUserLog();

  }

  GetUserLog() {
    this.date = $('#daterangepicker_input').val().toString();
    this._restService.Call<UserLogin[]>('GET', API.GetUserLogs + "?date=" + this.date, null)
      .subscribe(data => {
        this.userLogs = data;
        $('#tblUsers').DataTable().destroy();
        this.dtTrigger.next();
        this.IsTiggered = true;
      })
  }
  ExportTOExcel() {

    this._restService.Call<any[]>("GET", API.ExportUserLog + "?date=" + this.date, null)
      .subscribe(data => {
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'UserLog.xlsx');

      })

  }

  ReassignOwnership() {
    this.ClientID = 0;
    if (this.RobocistAdmin == true) {
      this._restService.Call<Client[]>('GET', API.ClientList, null)
        .subscribe(data => {
          this.clientList = data;
        });
    }
    else {
      this._restService.Call<TriageRegion[]>('GET', API.GetDomainUserwise, null)
        .subscribe(data => {
          this.domainList = data;
        })
    }
    this.modalService.open(this.input);
  }
  GetDomains() {
    this._restService.Call<TriageRegion[]>('GET', API.GetDomains + this.ClientID, null)
      .subscribe(data => {
        this.domainList = data;
      })
  }
  GetClientAdmins() {
    this._restService.Call<ApplicationUser[]>('GET', API.GetClientAdmins + this.ClientID, null)
      .subscribe(data => {
        this.domainAdmins = data;
      })
  }
  Submit() {
    if (this.reassignOwner.FromUserID == this.reassignOwner.ToUserID) {
      AutoClosingAlert('danger', 'From and To admin can not be same.');
    }
    else {
      this._restService.Call('POST', API.Reassign+this.ClientID, this.reassignOwner)
        .subscribe(data => {
          AutoClosingAlert('succss','Data saved successfully.');
          this.modalService.dismissAll();
        })
    }
  }
}
