import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { API } from '../_models/API';
import { RestService } from '../_services';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationUser } from '../_models/ApplicationUser';
import { Config } from '../_models/Config';
import { Document } from '../_models/FAQData';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  HTML_URL = API.HTML_URL;
  public Documents = new Array<Document>();
  public CreateDocuments = new Document();
  @ViewChild('content') input;

  constructor(private _restservice: RestService, private _router: Router, private modalService: NgbModal, private cdRef: ChangeDetectorRef) { }
  public IsRobocistAdmin = false;
  ngOnInit() {
    var User = JSON.parse(sessionStorage.getItem('currentUser')) as ApplicationUser;
    if (User.RoleId == Config.RobocistAdminRoleId) {
      this.IsRobocistAdmin = true;
    }
    this.GetDouments()
  }

  downloadPdf(url: string) {
    //const pdfUrl = './assets/sample.pdf';
    //const pdfName = 'your_pdf_file';
    FileSaver.saveAs(this.HTML_URL + url, "Softwarerelease");
  }

  openDoc() {
    window.open(API.PdfURL, '_blank', '', true);
  }

  HtmlDoclength: number = 0;
  PDFDoclength: number = 0;

  GetDouments() {
    this._restservice.Call<Document[]>('GET', API.GetDocuments, null)
      .subscribe(data => {
        this.Documents = data;
        this.HtmlDoclength = data.filter(s => s.htmlDocument != 'NoFile').length
        this.PDFDoclength = data.filter(s => s.PdfDocument != 'NoFile').length
      })
  }
  DeleteDouments(id) {
    this._restservice.Call<Document[]>('GET', API.DeleteDocuments + id, null)
      .subscribe(data => {
        this.GetDouments()
      })
  }
  readUrl(event: any, type: string) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(event.target.files[0]);
      console.log(reader.result);
      if (type == "PDF") {
        this.CreateDocuments.PdfDocumentFile = reader.result.toString()
      } else {

        this.CreateDocuments.htmlDocumentFile = reader.result.toString()
      }
    };
  }


  Submit() {
    console.log(this.CreateDocuments)
    this.CreateDocuments.PdfDocumentFile = this.CreateDocuments.PdfDocumentFile.split("base64,")[1]
    this.CreateDocuments.htmlDocumentFile = this.CreateDocuments.htmlDocumentFile.split("base64,")[1]
    this._restservice.Call<Document>('POST', API.UploadDocuments, this.CreateDocuments)
      .subscribe(data => {
        this.GetDouments()
        this.modalService.dismissAll();
      })
  }

  openPopup() {
    this.modalService.open(this.input);
  }

}
