import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthGuard } from './_guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { SimpleLayoutComponent } from './simple-layout/simple-layout.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { DrawFenceComponent } from './draw-fence/draw-fence.component';
import { EditFenceComponent } from './edit-fence/edit-fence.component';

import { HeatmapComponent } from './heatmap/heatmap.component';
import { TrafficSignMapComponent } from './traffic-sign-map/traffic-sign-map.component';
import { RoadMarkingsandLinesComponent } from './road-markingsand-lines/road-markingsand-lines.component';
import { PavementConditionMapComponent } from './pavement-condition-map/pavement-condition-map.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { DrawSubRegionComponent } from './draw-sub-region/draw-sub-region.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { UserListComponent } from './user-list/user-list.component';
import { AssignRegionComponent } from './assign-region/assign-region.component';
import { HomeComponent } from './home/home.component';
import { RegionViewComponent } from './region-view/region-view.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UserLogComponent } from './user-log/user-log.component';
import { AddUserComponent } from './add-user/add-user.component';
import { RouteGuard } from './_guards/route.guard';
import { ClientListComponent } from './client-list/client-list.component';
import { CreateClientComponent } from './create-client/create-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { PavementMapComponent } from './pavement-map/pavement-map.component';
import { RoadTriageFAQComponent } from './road-triage-faq/road-triage-faq.component';
import { LineSegmentMapComponent } from './line-segment-map/line-segment-map.component';
import { SegmentPDFComponent } from './segment-pdf/segment-pdf.component';
import { LaneLineAssessmentComponent } from './lane-line-assessment/lane-line-assessment.component';
import { PDFLaneLineComponent } from './pdflane-line/pdflane-line.component';

import { CoverageHeatMapComponent } from './coverage-heat-map/coverage-heat-map.component'; 
import { DocumentComponent } from './document/document.component';

export const appRoutes: Routes =

  [
    {
      path: '',
      component: SimpleLayoutComponent,
      children: [
        { path: '', redirectTo: '/SignIn', pathMatch: 'full', canActivate: [RouteGuard] },
        { path: 'SignIn', component: LoginComponent, canActivate: [RouteGuard], data: { ID: '20' } },
        { path: 'SignUp/:userId/:code', component: SignUpComponent, canActivate: [RouteGuard], data: { ID: '21' } },
        { path: 'ForgotPassword', component: ForgotPasswordComponent, canActivate: [RouteGuard], data: { ID: '22' } },
        { path: 'ResetPassword/:userId/:code', component: ResetPasswordComponent, canActivate: [RouteGuard], data: { ID: '23' } },
        { path: 'PDFLineSegment/:WayID/:segmentid/:Type', component: SegmentPDFComponent  },
        { path: 'PDFLaneLine/:WayID/:segmentid/:Type', component: PDFLaneLineComponent  },
      ]
    },
    {
      path: '',
      component: FullLayoutComponent,
      children: [
        {
          path: 'Home', component: HomeComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Home', ID: '1'
          }
        },
        {
          path: 'UserDashboard/:RegionID', component: UserDashboardComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Maps', ID: "2", args: ['RegionID']
          }
        },
        {
          path: 'Heatmap/:RegionID/:MapTypeID', component: HeatmapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Data Coverage', ID: "3", args: ['RegionID','MapTypeID']
          }
        },
        {
          path: 'TrafficSignMap/:RegionID/:MapTypeID', component: TrafficSignMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Traffic Sign', ID: "4", args: ['RegionID','MapTypeID']
          }
        },
        {
          path: 'RoadMarkingsandLines/:RegionID/:MapTypeID/:Type', component: RoadMarkingsandLinesComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Road Lines', ID: "5", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'RoadMarkingsandLinesQuarterly/:RegionID/:MapTypeID/:Type', component: RoadMarkingsandLinesComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Road Lines', ID: "34", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'PavementAssessmentMonthly/:RegionID/:MapTypeID/:Type', component: LineSegmentMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Pavement Assessment Monthly', ID: "30", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'PavementAssessmentQuarterly/:RegionID/:MapTypeID/:Type', component: LineSegmentMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Pavement Assessment Quarterly', ID: "32", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'PavementConditionMap/:RegionID/:MapTypeID', component: PavementConditionMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Pavement Condition', ID: "6", args: ['RegionID','MapTypeID']
          }
        },
        {
          path: 'PavementMap/:RegionID/:MapTypeID/:Type', component: PavementMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Pavement Map Monthly', ID: "27", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'PavementMapQuarterly/:RegionID/:MapTypeID/:Type', component: PavementMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Pavement Map Quarterly', ID: "33", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'DrawFence/:ClientID', component: DrawFenceComponent, canActivate: [AuthGuard], data: {
            ID: '7'
          }
        },
        {
          path: 'DrawSubRegion/:ID', component: DrawSubRegionComponent, canActivate: [AuthGuard], data: {
            ID: '8'
          }
        },
        {
          path: 'EditFence/:ID', component: EditFenceComponent, canActivate: [AuthGuard], data: {
            ID: '14'
          }
        },
        {
          path: 'ClientList', component: ClientListComponent, canActivate: [AuthGuard], data: {
            ID: '24'
          }
        },
        {
          path: 'CreateClient', component: CreateClientComponent, canActivate: [AuthGuard], data: {
            ID: '25'
          }
        },
        { path: 'EditClient/:ID', component: EditClientComponent, canActivate: [AuthGuard], data: { ID: '26' } },

        {
          path: 'InviteUser', component: InviteUserComponent, canActivate: [AuthGuard], data: {
            ID: '9'
          }
        },
        {
          path: 'UserList', component: UserListComponent, canActivate: [AuthGuard], data: {
            ID: '10'
          }
        },
        {
          path: 'Assign/:UserId', component: AssignRegionComponent, canActivate: [AuthGuard], data: {
            ID: '13'
          }
        },
        {
          path: 'AssignRegion/:UserId', component: AssignRegionComponent, canActivate: [AuthGuard], data: {
            ID: '11'
          }
        },
        {
          path: 'RegionView', component: RegionViewComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Region View', ID: "12"
          }
        },
        { path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { ID: '16' } },
        { path: 'EditProfile/:UserID', component: EditProfileComponent, canActivate: [AuthGuard], data: { ID: '17' } },
        { path: 'UserLogs', component: UserLogComponent, canActivate: [AuthGuard], data: { ID: '18' } },
        { path: 'AddUser', component: AddUserComponent, canActivate: [AuthGuard], data: { ID: '19' } },
        {
          path: 'RoadTriageFAQ', component: RoadTriageFAQComponent, canActivate: [AuthGuard], data:{
           ID: '28'
          }
        },
        {
          path: 'LaneLineAssessmentMonthly/:RegionID/:MapTypeID/:Type', component: LaneLineAssessmentComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'LaneLine Assessment Monthly', ID: "36", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'LaneLineAssessmentQuarterly/:RegionID/:MapTypeID/:Type', component: LaneLineAssessmentComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'LaneLine Assessment Quarterly', ID: "35", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'CoverageHeatMapMonthly/:RegionID/:MapTypeID/:Type', component: CoverageHeatMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Heat Map Monthly', ID: "37", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'CoverageHeatMapQuarterly/:RegionID/:MapTypeID/:Type', component: CoverageHeatMapComponent, canActivate: [AuthGuard], data: {
            breadcrumb: 'Heat Map Quarterly', ID: "38", args: ['RegionID','MapTypeID','Type']
          }
        },
        {
          path: 'Documentation', component: DocumentComponent, canActivate: [AuthGuard], data:{
           ID: '39'
          }
        }
        // {
        //   path: 'MapDemo', component: MapDemoComponent, canActivate: [AuthGuard], data: {
        //     breadcrumb: 'Home', ID: '1'
        //   }
        // },
      ]
    },

  ]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
export const routing = [
  LoginComponent,
  SignUpComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  ChangePasswordComponent,
  TopNavComponent,
  FullLayoutComponent,
  SimpleLayoutComponent,
  CreateClientComponent,
  RoadTriageFAQComponent,
  DrawFenceComponent
  , EditFenceComponent
  , InviteUserComponent
  , UserListComponent,
  LaneLineAssessmentComponent,
  CoverageHeatMapComponent,
  DocumentComponent
]
