import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams, HttpClient } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
declare var $: any;
import { AuthenticationService } from '../_services';
import { Config } from '../_models/Config';
import '../../assets/scripts/js/Custom/widgets'
 // import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from '../_models/TokenModel';
import { API } from '../_models/API';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    alerts:any[];
    constructor(private authenticationService: AuthenticationService,private http : HttpClient) {

     }
     
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
        return next.handle(request).pipe(catchError(
        err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }
            if(err.error != null)
            {
            const error = err.error || err.statusText;
            return throwError(error);
            }

            $.AutoClosingAlert({ Type: "danger" });
        
    return Observable.throw(err);
        }))
    }
}