import { Component, OnInit,   Renderer2, Inject } from '@angular/core';
import { ChangePasswordViewModel } from '../_models/ChangePasswordViewModel';
import { Router } from '@angular/router';
import {  RestService } from '../_services';
import { API } from '../_models/API';
import { User } from '../_models';
import { DOCUMENT } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public ChangePassword = new ChangePasswordViewModel();
  public status = 'fa-eye-slash';
  public type = 'password';
  public oldstatus = 'fa-eye-slash';
  public oldtype = 'password';
  public localuser;
  IsDuplicate: boolean = false;
  constructor(
    private router: Router,
    private _restservice: RestService) {

  }

  ngOnInit() {
    this.localuser = JSON.parse(sessionStorage.getItem('currentUser')) as User;
    PasswordStrengthInit('#Password');

  }
  getAlert() {
    if (this.status === 'fa-eye-slash') {
      this.status = 'fa-eye';
      this.type = 'text';
    } else {
      this.status = 'fa-eye-slash';
      this.type = 'password';
    }

  }

  getAlertOld() {
    if (this.oldstatus === 'fa-eye-slash') {
      this.oldstatus = 'fa-eye';
      this.oldtype = 'text';
    } else {
      this.oldstatus = 'fa-eye-slash';
      this.oldtype = 'password';
    }
  }

  CheckPassCreate() {
    if ($('#Password').hasClass('Validpass')) {
      var pass = $('#Password').val();
      var confPas = $("#ConfirmPasswordAdd").val();
      if (pass != '') {
        if (pass == confPas) {
          $("#Checkshow").show();
          $("#Crossshow").hide();
          this.IsDuplicate = false;
        } else {
          $("#Checkshow").hide();
          $("#Crossshow").show();
          this.IsDuplicate = true;
        }
      } else {
        $("#Checkshow").hide();
        $("#Crossshow").hide();
        this.IsDuplicate = true;
      }
    }
    else {
      this.IsDuplicate = true;
      $("#validPass").show();
    }
  }

  CheckForPass() {
    if ($('#Password').hasClass('Validpass')) {
      this.IsDuplicate = false;
      $("#validPass").hide()
    } else {
      this.IsDuplicate = true;
      $("#validPass").show();
    }

  }
  onSubmit() {
    this._restservice.Call<any>('POST', API.ChangePassword, this.ChangePassword)
      .subscribe(data => {
        AutoClosingAlert('success', 'Password changed successfully.');
        this.router.navigate(['/Home'])
      }, error => {
        AutoClosingAlert('danger', error)
      });
  }
}
